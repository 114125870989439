export default {
  about: 'About Game',
  looking_for_partners: 'Looking For Partners',
  date: 'Date',
  name: 'Game title',
  developer: 'Developer',
  links_title: 'Additional links',
  links_description: 'Add links to the resources you want',
  by_company: 'by :company',
  request: 'Participant Info',
  request_type: 'Request Type',
  request_date: 'Request Date',
  on_site_requests: 'On-site Participants',
  contest_participants: 'Contest Participants',
  on_site_participant: 'On-Site Participant',
  contest_participant: 'Contest Participant',
  on_site: 'On-Site',
  on_line: 'Online Only',
  profile: 'Participant Profile',
  showcase_info: 'If you’d like your game to be added to {developer_showcase}, please {contact} Otherwise, other attendees we’ll see it only when viewing your company profile.',
  showcase_info_let_us_know: 'let us know!',
  developer_showcase: 'Developer Showcase',
  min_votes_message: ':current votes received. Get another :diff to take part in the finals!',
  developers: 'Developers',
  budget: 'Production budget',
  pitch_deck_link: 'Pitch deck link',
  game_build_link: 'Game build link',
  planned_date: 'Planned date, if game is still in production',
  video_link: 'Gameplay video',
  youtube_link: 'YouTube Video Link',
  icon: 'Game logo',
  screenshot: 'Game screenshots',
  cover: 'Header image for the game page',
  answer_visible_to_all: 'The answer is visible to all users',
  answer_visible_to_jury: 'The answer is visible only to the jury, employees of your company and platform moderators',
  answer_visible_to_none: 'The answer is visible only to employees of your company and platform moderators',
}
