export default {
  about: 'Об игре',
  looking_for_partners: 'В поисках партнеров',
  date: 'Дата',
  name: 'Название',
  developer: 'Разработчик',
  links_title: 'Дополнительные ссылки',
  links_description: 'Добавьте ссылки на желаемые ресурсы',
  by_company: 'от :company',
  request: 'Информация об участнике',
  request_type: 'Тип заявки',
  request_date: 'Дата заявки',
  on_site_requests: 'On-Site участники',
  contest_participants: 'Участники конкурса',
  on_site_participant: 'On-Site участник',
  contest_participant: 'Участник конкурса',
  on_site: 'On-Site',
  on_line: 'Online Only',
  profile: 'Анкета участника',
  showcase_info: 'Если вы хотите, чтобы ваша игра была добавлена в {developer_showcase}, пожалуйста, {contact} В противном случае другие участники увидят ее только при просмотре профиля вашей компании.',
  showcase_info_let_us_know: 'дайте нам знать!',
  developer_showcase: 'Шоукейс разработчика',
  min_votes_message: 'Получено голосов: :current. Получите еще :diff, чтобы принять участие в финале!',
  developers: 'Разработчики',
  budget: 'Производственный бюджет',
  pitch_deck_link: 'Ссылка на презентацию',
  game_build_link: 'Ссылка на билд игры',
  planned_date: 'Планируемая дата, если игра всё ещё в производстве',
  video_link: 'Видео геймплея',
  youtube_link: 'Ссылка на YouTube видео',
  icon: 'Логотип игры',
  screenshot: 'Скриншоты игры',
  cover: 'Хедер страницы об игре',
  answer_visible_to_all: 'Ответ виден всем пользователям',
  answer_visible_to_jury: 'Ответ виден только членам жюри, сотрудникам вашей компании и модераторам платформы',
  answer_visible_to_none: 'Ответ виден только сотрудникам вашей компании и модераторам платформы',
}
