import { App } from 'vue'
import { useAuth } from '/-/plugins/auth'
import { useAuthToken } from '/~/plugins/auth-token'
import { useLogin } from '/~/plugins/login'
import router from '/~/router'

async function setAuth() {
  const { setToken } = useAuth()
  const { getToken } = useAuthToken()
  const { watchTabsLogout } = useLogin()

  const token = await getToken()

  if (token) {
    setToken(token)
  }
  watchTabsLogout()
}

export async function bootstrap(app: App<Element>) {
  await setAuth()

  return {
    app,
    router
  }
}
