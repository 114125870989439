
import { BaseModel } from './baseModel'

export class Autocomplete extends BaseModel {
  label!: string
  value!: string | number
}

// TODO: refactor autocomplete naming
export class AutocompleteResponse {
  id!: string | number
  uid?: string
  name!: string
}

export class Option {
  label!: string
  value!: string | number
}

export function autocompleteToOption(data: AutocompleteResponse): Option {
  return {
    value: data?.uid || data?.id,
    label: data?.name,
  }
}
