<template>
  <div :id="id"
       ref="wrapper">
    <div
      class="gap-6"
      :class="[
        gridColsClass,
        formData.isUseSliderOnMobile ? 'hidden md:grid' : 'grid',
      ]"
      :style="{
        'scroll-margin-top': 'env(safe-area-inset-top)',
      }">
      <template
        v-for="(image, index) in formData.images"
        :key="'images-grid' + image.url + index">
        <div>
          <div class="w-full mb-2">
            <img :src="image.url"
                 class="w-full rounded-xl cursor-pointer"
                 @click="onSlideClick(index)">
          </div>
          <div class="text-gray-500 italic">
            {{ image.description }}
          </div>
        </div>
      </template>
    </div>
    <div :class="swiperClassList">
      <Swiper
        :slides-per-view="isFullscreen ? 1 : 'auto'"
        :space-between="isFullscreen ? 0 : SLIDES_GAP"
        :pagination="{
          clickable: true,
        }"
        class="h-full"
        @swiper="mainSwiper = $event">
        <!-- :modules="swiperModules" -->
        <template
          v-for="(image, index) in formData.images"
          :key="'images-slider' + image.url + index">
          <SwiperSlide
            :style="!isFullscreen && {
              marginRight: SLIDES_GAP + 'px',
              width: getSlideWidth(formData.colsNumberOnMobile, SLIDES_GAP),
            }">
            <div class="flex flex-col gap-6 items-center justify-center w-full h-full"
                 :class="isFullscreen && 'px-6 lg:px-14 pb-16'"
                 :style="[
                   isFullscreen
                     ? 'paddingTop: calc(4rem + env(safe-area-inset-top))'
                     : '',
                 ]">
              <div class="flex-1">
                <img :src="image.url"
                     :class="isFullscreen ? 'h-full w-full object-contain' : 'rounded-xl cursor-pointer'"
                     @click="onSlideClick(index)">
              </div>
              <div class="italic"
                   :class="isFullscreen ? 'text-gray-100' : 'text-gray-500'">
                {{ image.description }}
              </div>
            </div>
          </SwiperSlide>
        </template>
      </Swiper>
      <template v-if="isFullscreen">
        <div
          class="absolute right-3 z-30 transition opacity-50"
          style="top: calc(0.75rem + env(safe-area-inset-top))">
          <BaseButton theme="dark"
                      size="sm"
                      class="w-8 !p-0"
                      @click="exitFromfullscreen">
            <BaseIcon name="outline_x" />
          </BaseButton>
        </div>
        <div class="absolute left-3 z-30 transition opacity-0 group-hover:opacity-100"
             style="top: calc(50% - 2.5rem + env(safe-area-inset-top))"
             :class="mainSwiper?.isBeginning && 'hidden'">
          <BaseButton size="sm"
                      theme="white"
                      class="w-8 !p-0 shadow-lg"
                      rounded="full"
                      @click="mainSwiper?.slidePrev()">
            <BaseIcon name="outline_arrow_left"
                      size="sm" />
          </BaseButton>
        </div>

        <div
          class="absolute right-3 z-30 transition opacity-0 group-hover:opacity-100"
          style="top: calc(50% - 2.5rem + env(safe-area-inset-top))"
          :class="mainSwiper?.isEnd && 'hidden'">
          <BaseButton size="sm"
                      theme="white"
                      class="w-8 !p-0 shadow-lg"
                      rounded="full"
                      @click="mainSwiper?.slideNext()">
            <BaseIcon name="outline_arrow_right"
                      size="sm" />
          </BaseButton>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Swiper as SwiperClass } from 'swiper'
// import { Grid, Pagination } from 'swiper/modules' // we maybe have to update swiper because of this
import { getGridColsClass, getSlideWidth } from '../utils'
import 'swiper/css'
import 'swiper/css/grid'
import 'swiper/css/pagination'

interface FormDataInterface {
  colsNumberOnDesktop: number
  colsNumberOnMobile: number
  isUseSliderOnMobile: boolean
  rowsNumberInSliderOnMobile: number
  images: { url: string, description: string }[]
}

interface PropsInterface {
  formData: FormDataInterface
  id: string
}

const SLIDES_GAP = 24
const props = withDefaults(defineProps<PropsInterface>(), {
  formData: () => ({
    colsNumberOnDesktop: 2,
    colsNumberOnMobile: 2,
    isUseSliderOnMobile: false,
    rowsNumberInSliderOnMobile: 1,
    images: [],
  }),
})

// const swiperModules = [Grid, Pagination]
const isFullscreen = ref(false)
const mainSwiper = ref<SwiperClass | undefined>(undefined)
const wrapper = ref<HTMLDivElement | undefined>(undefined)

const onSlideClick = async (index: number) => {
  if (isFullscreen.value) {
    return
  }

  if (mainSwiper.value) {
    mainSwiper.value.activeIndex = index
  }

  if (wrapper.value) {
    const height = wrapper.value.offsetHeight

    wrapper.value.style.height = `${height}px`
  }

  isFullscreen.value = true
}

const exitFromfullscreen = () => {
  if (wrapper.value) {
    wrapper.value.style.height = ''
  }

  isFullscreen.value = false
}

const onKeydown = (event: KeyboardEvent) => {
  if (!isFullscreen.value) {
    return
  }

  switch (event.key) {
  case 'Escape':
    exitFromfullscreen()
    break
  case 'ArrowRight':
    mainSwiper.value?.slideNext()
    break
  case 'ArrowLeft':
    mainSwiper.value?.slidePrev()
    break
  }
}

const gridColsClass = computed(() => {
  return getGridColsClass(
    props.formData.colsNumberOnDesktop,
    props.formData.colsNumberOnMobile
  )
})

const swiperClassList = computed(() => {
  if (isFullscreen.value) {
    return 'fullscreen group relative'
  } else {
    return props.formData.isUseSliderOnMobile ? 'block md:hidden' : 'hidden'
  }
})

onMounted(() => {
  document.addEventListener('keydown', onKeydown)
})

onUnmounted(() => {
  document.removeEventListener('keydown', onKeydown)
})
</script>

<style scoped>
.fullscreen {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #161418;
  overflow: hidden;
}
</style>
