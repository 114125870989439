import { reactive, toRef } from 'vue'
import { Api } from '/-/plugins/api'
import ProcessorElastic from '/-/plugins/processor/processor-elastic'
import { Member, MemberLite } from '/~/models/member'
import { plainToClass } from '/-/plugins/helpers'
import { useEvents } from '/~/state/events'
import { OptionInterface } from '/-/components/controls/setup'
import { autocompleteToOption, AutocompleteResponse } from '../models/autocomplete'

const { eventId } = useEvents()

interface MembersStateInterface {
  list: ProcessorElastic | null
  companyMembers: ProcessorElastic | null
  memberPromise: Promise<Member> | null
  memberProps: {
    eventId: number | null,
    userId: number | string | null
  }
}

const state = reactive({
  list: null,
  companyMembers: null,
  memberPromise: null,
  memberProps: {
    eventId: null,
    userId: null
  }

}) as MembersStateInterface

const sortMap = {
  last_seen: 'desc',
} as { [key: string]: string }

function initProcessor(isPreviousMembersEnabled: boolean, isShowEmptyMembers = false) {
  state.list = new ProcessorElastic({
    filter: {
      search: {
        type: String
      },
      region_id: {
        type: Array
      },
      sort: {
        type: String
      },
      features: {
        type: Array
      },
      look_id: {
        type: Array
      },
      offer_id: {
        type: Array
      },
      show_previous: {
        type: String,
        default: isPreviousMembersEnabled.toString(),
      },
      show_empty: {
        type: String,
        default: isShowEmptyMembers.toString(),
      }
    },
    fetch: async (page, params, fetchParams) => {
      const data = await Api.fetch({
        url: `/${eventId.value}/members`,
        params: {
          sort: params.filter.sort || undefined,
          ...(sortMap[params.filter.sort] && { order: sortMap[params.filter.sort] }),
          page,
          'filter[query]': params.filter.search || undefined,
          'filter[looking]': params.filter.look_id,
          'filter[features]': params.filter.features,
          'filter[offer]': params.filter.offer_id,
          'filter[geo]': params.filter.region_id,
          'filter[show_previous]': params.filter.show_previous === 'true' ? 1 : undefined,
          'filter[empty]': params.filter.show_empty === 'true' ? 1 : undefined,
        },
        ...fetchParams,
      })

      return data
    },
    mapping: (data) => plainToClass(data, MemberLite),
    allowCancel: true
  })
}

function resetProcessor() {
  state.list = null
}

async function getMember(id: number | string = 'me'): Promise<Member> {
  if (state.memberPromise && state.memberProps.eventId === eventId.value && state.memberProps.userId === id) {
    return await state.memberPromise
  }

  state.memberProps = {
    eventId: eventId.value || null,
    userId: id
  }

  state.memberPromise = Api.fetch({
    url: `/${eventId.value}/members/${id}`,
  }).then(({ data }) => { return plainToClass(data, Member) })

  return await state.memberPromise
}

function resetMemberCache() {
  state.memberPromise = null
  state.memberProps = {
    eventId: null,
    userId: null
  }
}

async function getMembersByIds(ids: number[]): Promise<Member[]> {
  const { data } = await Api.fetch({
    url: `/${eventId.value}/members`,
    params: {
      'filter[ids]': ids
    }
  }) as { data: any[] }

  return plainToClass(data, Member)
}

function initCompanyMembers(companyId: number) {
  state.companyMembers = new ProcessorElastic({
    fetch: async (page: number) => {
      const data = await Api.fetch({
        url: `/${eventId.value}/members`,
        params: {
          page,
          'filter[company_id]': companyId,
        },
      })

      return data
    },
    mapping: (data) => plainToClass(data, MemberLite),
  })
}

function resetCompanyMembers() {
  state.companyMembers = null
}

async function getMembersAutocomplete(query: string, role?: number): Promise<OptionInterface[]> {
  const { data } = await Api.fetch({
    url: `/${eventId.value}/members/autocomplete`,
    params: {
      'filter[query]': query,
      ...(role && { 'filter[role_ids][]': role })
    }
  }) as { data: AutocompleteResponse[] }

  return data.map(autocompleteToOption)
}

// returns member's user id
async function getMembersUserAutocomplete(query: string, role?: number): Promise<OptionInterface[]> {
  const { data } = await Api.fetch({
    url: `/${eventId.value}/members/autocomplete-user`,
    params: {
      'filter[query]': query,
      ...(role && { 'filter[role_ids][]': role })
    }
  }) as { data: AutocompleteResponse[] }

  return data.map(autocompleteToOption)
}

export function useMembers() {
  return {
    members: toRef(state, 'list'),
    initProcessor,
    resetProcessor,
    getMember,
    resetMemberCache,
    getMembersByIds,
    getMembersAutocomplete,
    getMembersUserAutocomplete,
    companyMembers: toRef(state, 'companyMembers'),
    initCompanyMembers,
    resetCompanyMembers
  }
}
