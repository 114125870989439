<template>
  <BaseModal
    :is-open="isOpen"
    :fullscreen="true"
    size="2xl"
    :bg-class="bgClass"
    @close="close">
    <template #header>
      <div class="absolute right-4 top-4">
        <BaseButton theme="white"
                    size="sm"
                    class="w-8 !p-0"
                    @click="close">
          <BaseIcon name="outline_x" />
        </BaseButton>
      </div>
    </template>
    <template #body>
      <div
        class="grid md:grid-cols-2 gap-6 p-10 pt-14 md:pt-10"
        :style="{ color: textColor }">
        <div>
          <BaseRatio :ratio="1">
            <img
              :src="speaker.image"
              class="w-full h-full object-cover rounded-xl">
          </BaseRatio>
        </div>
        <div class="flex flex-col gap-2">
          <div class="pr-6">
            <div
              class="font-bold text-lg text-gray-900"
              :style="{ color: titleColor }">
              {{ speaker.title }}
            </div>
            <div
              class="marked text-gray-400"
              :style="{ color: subtitleColor }"
              v-html="getMarkedTextWithBlankLinks(speaker.subtitle, linkColor)" />
          </div>
          <div
            v-if="speaker.text"
            class="marked pt-2"
            v-html="getMarkedTextWithBlankLinks(speaker.text, linkColor)" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from 'vue'
import type { Speaker } from './types'
import { getMarkedTextWithBlankLinks } from '../utils'
import BaseModal from '/~/components/base/base-modal.vue'
import BaseButton from '/-/components/button/base-button.vue'
import BaseRatio from '/-/components/ratio/base-ratio.vue'

interface PropsInterface {
  speaker: Speaker
  isOpen: boolean
  titleColor?: string
  subtitleColor?: string
  linkColor?: string
  bgClass?: any
  textColor?: any
}

defineProps<PropsInterface>()

const emit = defineEmits(['close'])

const close = () => {
  emit('close')
}
</script>
