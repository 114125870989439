import { WNMeetVideo } from '/~/models/wnmeet'
import { Api } from '/-/plugins/api'
import { reactive, toRef } from 'vue'
import { BroadcastType } from '/~/models/broadcast'
import { plainToClass } from '/-/plugins/helpers'

export const state = reactive({
  videos: [] as WNMeetVideo[],
  isFetched: false,
})

async function getJWTToken(eventId: number, roomId: string) {
  const { data } = await Api.fetch({
    method: 'POST',
    url: `/${eventId}/rooms/${roomId}/token`
  }) as { data: any }

  return data?.token
}

async function fetchRoomVideos(eventId: number, roomId: string) {
  const { data } = await Api.fetch({
    url: `/${eventId}/rooms/${roomId}/videos`,
    params: { broadcast: BroadcastType.Jitsi },
  }) as { data: any[] }

  state.videos = plainToClass(data, WNMeetVideo)
  state.isFetched = true
}

async function getVideoLink(eventId: number, url: string) {
  const { data } = await Api.fetch({
    url: `/${eventId}/video-url`,
    params: { url },
  }) as { data: any }

  return data.url
}

async function deleteRecord(eventId: number, roomId: string, link: string) {
  await Api.fetch({
    url: `/${eventId}/rooms/${roomId}/videos`,
    params: { link },
    method: 'DELETE',
  }) as { data: any }
}

export function useWNMeet() {
  return {
    videos: toRef(state, 'videos'),
    isFetched: toRef(state, 'isFetched'),
    getJWTToken,
    fetchRoomVideos,
    getVideoLink,
    deleteRecord,
  }
}
