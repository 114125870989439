import {
  DEFAULT_MARKDOWN_PLACEHOLDER,
  DEFAULT_COLOR_PRIMARY,
} from '../constants'
import { basePropertiesSchema } from '../base-properties-schema'
import { buttonsPropertiesSchema } from '../common'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Banners',
  description: 'Banners with background, text and buttons',
  type: 'object',
  required: [],
  properties: {
    ...basePropertiesSchema,
    banners: {
      type: 'array',
      title: 'A list of banners',
      'ui:options': {
        showIndexNumber: true,
      },
      items: {
        title: 'Banner',
        type: 'object',
        properties: {
          text: {
            title: 'Text',
            type: 'string',
            'ui:options': {
              placeholder: DEFAULT_MARKDOWN_PLACEHOLDER,
              type: 'textarea',
              rows: 6,
            },
          },
          textColor: {
            title: 'Text Color',
            type: 'string',
            default: '#ffffff',
          },
          linkColor: {
            title: 'Link Color',
            type: 'string',
            default: DEFAULT_COLOR_PRIMARY,
          },
          textAlign: {
            title: 'Text Align',
            enum: ['left', 'center', 'right'],
            enumNames: ['Left', 'Center', 'Right'],
            default: 'center',
            'ui:widget': 'RadioWidget',
          },
          isBgImage: {
            title: 'Use image for background',
            type: 'boolean',
            default: true,
          },
          bgImage: {
            title: 'Background image',
            type: 'string',
            default: 'https://cdn.wnhub.io/39253/65dde526e1a6c4.93735968.jpg',
            'ui:hidden': '{{ parentFormData.isBgImage === false }}',
          },
          bgColor: {
            title: 'Background color',
            type: 'string',
            'ui:hidden': '{{ parentFormData.isBgImage === true }}',
          },
          paddingSize: {
            title: 'Paddings',
            enum: ['xs', 'sm', 'md', 'lg'],
            enumNames: ['Extra small', 'Small', 'Medium', 'Large'],
            default: 'md',
            'ui:widget': 'RadioWidget',
          },
          ...buttonsPropertiesSchema,
        },
      },
    },
  },
}
