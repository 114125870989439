export const URL_REGEXP =
  /^(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)$/

export function checkUrl(url: string): boolean {
  return URL_REGEXP.test(url)
}

export function prepareExternalUrl(url?: string | null): string {
  if (!url || !checkUrl(url)) {
    return ''
  }

  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = 'http://' + url
  }

  return url
}

export const YOUTUBE_REGEXP = /^(https?:\/\/)?((www\.)?youtube\.com\/(watch\?v=|embed\/|shorts\/)|youtu\.be\/)([a-zA-Z0-9\-_]{11})/

export function getYouTubeVideoId(url: string): string {
  const match = url.match(YOUTUBE_REGEXP)

  return (match && match[5].length === 11) ? match[5] : ''
}

export function isYouTubeUrl(url: string): boolean {
  return !!getYouTubeVideoId(url)
}
