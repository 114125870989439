import { AnalyticEvent } from '/~/models/analyticEvent'
import { plainToClass } from '/-/plugins/helpers'
import { useEvents } from './events'
import { useProfile } from './profile'
import { useError } from '/~/plugins/error'

interface EventModelInterface {
  entity_id?: number
  lecture_id?: string
  entity_type?: string
  info?: string
}

async function sendEvent(name: string, data?: EventModelInterface) {
  if (import.meta.env.VITE_ENV !== 'production') {
    return false
  }

  const { event } = useEvents()
  const { profile } = useProfile()
  const { processError } = useError()

  try {
    const eventModel = plainToClass({
      evt: name,
      ts: Math.round(new Date().getTime()),
      user_id: profile.value?.id,
      event_id: event.value?.id,
      ...data
    }, AnalyticEvent)

    console.log('Skip sending bigquery data', eventModel)
    // TODO: uncomment once service become available again

    // await Api.fetch({
    //   url: '',
    //   resource: 'bigquery',
    //   method: 'PUT',
    //   body: classToPlain(eventModel, AnalyticEvent)
    // })
  } catch (error: any) {
    processError(error, false)
    console.warn('BigQuery analytics error', error)
  }
}

async function openEntity(id: number, type: string) {
  return await sendEvent(`open_${type}`, {
    entity_id: id,
    entity_type: type
  })
}

async function clickEntity(id: number | null, type: string, entity: string, data?: any) {
  const obj: EventModelInterface = {
    entity_type: entity
  }

  if (id) {
    obj.entity_id = id
  }

  if (data) {
    obj.info = JSON.stringify(data)
  }

  return await sendEvent(`${type}_click`, obj)
}

async function openPage(url: string) {
  return await sendEvent('open_page', {
    info: JSON.stringify({ url })
  })
}

async function chatEvent(name: string, chat: any, channelType: string) {
  const url = chat.url
  const customType = chat.customType

  return await sendEvent(name, {
    entity_type: `${channelType}_chat`,
    info: JSON.stringify({ url, customType })
  })
}

export function useAnalytic() {
  return {
    openPage,
    sendEvent,
    chatEvent,
    openEntity,
    clickEntity
  }
}
