<template>
  <BaseSelectAsync
    v-model="value"
    label="Email Template"
    :fetch="fetchAutocomplete"
    :initial="initialEmail"
    empty="Not selected"
    name="email"
    class="w-full" />
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { useEvents } from '/~/state/events'
import BaseSelectAsync from '/-/components/controls/select-async/base-select-async.vue'
import { OptionInterface } from '/-/components/controls/setup'
import { useEmails } from '/~/state/emails'
import { EmailTypes } from '/~/models/emails'

const props = defineProps({
  modelValue: {
    type: Number,
    default: undefined,
  },
})

const emit = defineEmits(['update:modelValue'])
const { eventId } = useEvents()
const emailsStore = useEmails()
const initialEmail = ref<undefined | OptionInterface>(undefined)

const value = computed({
  get: () => {
    return props.modelValue
  },
  set: (value) => {
    emit('update:modelValue', value)
  },
})

function fetchAutocomplete(query: string) {
  if (!eventId.value) {
    return []
  }
  return emailsStore.getEmailsAutocomplete(eventId.value, query, [
    EmailTypes.Custom,
  ])
}

onMounted(async () => {
  if (props.modelValue && eventId.value) {
    const email = await emailsStore.getEmailByEvent(
      eventId.value,
      props.modelValue
    )

    initialEmail.value = {
      value: email.id,
      label: email.name,
    }
  }
})
</script>
