<template>
  <div>
    <BaseSelect
      v-model="value"
      :options="blocksOptions"
      name="ticket"
      class="w-64 sm:w-80" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useAcademyLessons } from '/~/state/academy-lessons'
import BaseSelect from '/-/components/controls/select/base-select.vue'
import { getBlockId } from '/~/page-editor/utils'

interface PropsInterface {
  modelValue?: string | number
}

const props = withDefaults(defineProps<PropsInterface>(), {
  modelValue: undefined,
})

const emit = defineEmits(['update:modelValue'])
const pageEditorStore = useAcademyLessons()

const blocksOptions = computed(() => {
  return pageEditorStore.lessonBlocks.value.map((block) => {
    const value = getBlockId(block)

    return {
      label: value,
      value,
    }
  })
})

const value = computed({
  get: () => {
    return props.modelValue
  },
  set: (value) => {
    emit('update:modelValue', value)
  },
})
</script>
