export default {
  modules_list: 'Список модулей',
  view_module: 'Модуль',
  no_modules: 'Пока нет ни одного модуля',
  no_lessons: 'Пока нет ни одного урока',
  pick_teachers: 'Выбрать преподавателей',
  load_more: 'Загрузить больше',
  table_id: 'Код',
  table_title: 'Заголовок',
  table_is_open: 'Открыт',
  table_is_active: 'Активен',
  table_start_date: 'Дата начала',
  table_active: 'Активен',
  edit_module: 'Редактировать модуль',
  create_module: 'Создать модуль',
  delete_module: 'Удалить',
  back_to_modules: 'Список модулей',
  title: 'Заголовок',
  description: 'Описание',
  is_open: 'Открыт',
  active: 'Активен',
  date: 'Дата открытия',
  date_description: 'Дата в часовом поясе курса',
  has_homework: 'Домашнее задание видно учащимся',
  is_blocking_homework: 'Обязательное домашнее задание',
  homework_description: 'Описание домашнего задания',
  teachers: 'Преподаватели',
  submit: 'Сохранить',
  update_sorting_error: 'Ошибка, не удалось обновить порядок в списке',
  update_sorting_success: 'Порядок успешно обновлён',
  module_create_success: 'Модуль успешно создан',
  module_update_success: 'Модуль успешно обновлён',
  module_delete_success: 'Модуль успешно удалён',
  module_create_error: 'Ошибка, не удалось создать модуль',
  module_update_error: 'Ошибка, не удалось обновить модуль',
  module_delete_error: 'Ошибка, не удалось удалить модуль',
  module_confirm_delete_title: 'Удалить модуль',
  module_confirm_delete_text: 'Вы уверены что хотите безвозвратно удалить модуль со всеми уроками в нём?',
  lesson_create_success: 'Урок успешно создан',
  lesson_update_success: 'Урок успешно обновлён',
  lesson_delete_success: 'Урок успешно удалён',
  lesson_create_error: 'Ошибка, не удалось создать урок',
  lesson_update_error: 'Ошибка, не удалось обновить урок',
  lesson_delete_error: 'Ошибка, не удалось удалить урок',
  lesson_confirm_delete_title: 'Удалить урок',
  lesson_confirm_delete_text: 'Вы уверенны что хотите безвозвратно удалить урок?',
  lessons_list: 'Список уроков',
  create_lesson: 'Создать урок',
  edit_lesson: 'Редактировать урок',
  delete_lesson: 'Удалить урок',
  view_lesson: 'Урок',
  back_to_module: 'Вернуться к модулю',
  back_to_lesson: 'Вернуться к уроку',
  students: 'Студенты',
  require_admin: 'Только администратор курса может редактировать программу курса',
  require_staff: 'Вы должны быть преподавателем или администратором курса для просмотра этой страницы',
  homeworks: 'Домашние задания',
  promo_messages_note: 'Вы можете запросить рассылку для всех участников курса через вашего менеджера WN Media',
  lesson: 'Урок',
  student: 'Студент',
  status: 'Статус домашнего задания',
  select_status: 'Выберите статус',
  homework_approved: 'Одобрено',
  homework_declined: 'Отклонено',
  homework_created: 'Ожидает проверки',
  all_students: 'Все студенты',
  all_lessons: 'Все уроки',
  any_status: 'Любой',
  lesson_content: 'Содержание урока',
  program_upload_error: 'Ошибка, не удалось загрузить программу',
  open_from: 'Открыт с ',
  homework_is_required: 'Для доступа к следующему уроку необходимо выполнить это домашнее задание',
  lesson_homework_title: 'Домашнее задание к уроку',
  lesson_homework_form_title: 'Загрузите файлы с домашним заданием и оставьте комментарий для преподавателя',
  lesson_homework_placeholder: 'Ваши комментарии и вопросы по домашнему заданию',
  attach_homework_file: 'Приложить файл',
  lesson_homework_submit: 'Отправить',
  lesson_homework_status: 'Статус домашнего задания',
  homework_is_pending: 'Домашнее задание на проверке',
  homework_is_declined: 'Домашнее задание отклонено',
  homework_is_approved: 'Домашнее задание успешно выполнено',
  homework_required_short: 'Домашнее задание',
  homework_is_pending_short: 'На проверке',
  homework_is_declined_short: 'Отклонено',
  homework_is_approved_short: 'Выполнено',
  homework_submit_success: 'Домашнее задание успешно отправлено',
  homework_submit_error: 'Ошибка, не удалось отправить домашнее задание',
  homework_approve_success: 'Домашнее задание успешно одобрено',
  homework_approve_error: 'Ошибка, не удалось одобрить домашнее задание',
  homework_decline_success: 'Домашнее задание успешно отклонено',
  homework_decline_error: 'Ошибка, не удалось отклонить домашнее задание',
  open_chat: 'Открыть чат',
  hide_chat: 'Скрыть чат',
  close_chat: 'Закрыть чат',
  check_homework: 'Проверить',
  approve: 'Одобрить',
  decline: 'Отклонить',
  back: 'Предыдущий урок',
  next: 'Следующий урок',
  module_block_homework: 'Обязательный модуль',
  settings_tab: 'Основные настройки',
  content_tab: 'Содержание урока',
  homework_tab: 'Домашнее задание',
  attention: 'Внимание',
  progress: 'Общий прогресс',
  required_progress: 'Обязательные ДЗ',
  required_progress_tooltip: 'Обновляется один раз в час',
  start_recording: 'Начать запись',
  stop_recording: 'Остановить запись',
  recording: 'Запись',
  stream_records: 'Записи стримов',
  no_records: 'Еще нет записей',
  copy_id: 'Копировать id',
  copy_url: 'Копировать url',
  stream_record: 'Запись стрима',
  preview: 'Превью',
  id: 'Id',
  video_date: 'Дата',
  name: 'Имя',
  records_wait: 'Если вы не видите здесь свою запись, пожалуйста, подождите. Запись обрабатывается.',
  lectures_title: 'Название',
  lectures_date: 'Дата',
  lectures_category: 'Категория',
  add_lecture: 'Добавить лекцию',
  edit_lecture: 'Редактировать лекцию',
  delete_lecture: 'Удалить лекцию',
  delete_lecture_message: 'Для удаления лекции обратитесь к менеджеру WN Hub',
  lecture_title: 'Заголовок',
  lecture_category: 'Категории',
  lecture_category_placeholder: 'Выберите категории',
  lecture_date: 'Дата',
  lecture_source: 'Источник',
  lecture_video_id: 'Идентификатор видео',
  lecture_video_url: 'Ссылка на видео',
  lecture_language: 'Язык',
  lecture_description: 'Описание',
  lecture_video_preview: 'Превью видео',
  lecture_speakers: 'Спикеры',
  lecture_speakers_placeholder: 'Выберите спикеров',
  lecture_submit: 'Отправить',
  preview_too_big: 'Ошибка, слишком больше изображение для превью',
  preview_upload_error: 'Ошибка, не удалось загрузить изображение для превью',
  lecture_updated: 'Лекция успешно обновлена',
  lecture_created: 'Новая лекция успешно создана',
  lecture_update_error: 'Ошибка, не удалось обновить лекцию',
  lecture_create_error: 'Ошибка, не удалось создать лекцию',
  student_deleted: 'Студент удалён',
  wnmeet_select_record: 'Выбрать из списка записей',
  wnmeet_select_video_item: 'Выбрать запись',
  wnmeet_records: 'Записи WN Meet',
  wnmeet_no_records: 'Нет доступных записей',
  wnmeet_select_video: 'Записи WN Meet',
  delete_record: 'Удалить запись',
  delete_record_text: 'Вы уверенны что хотите удалить запись от :date?',
  delete_record_success: 'Запись успешной удалена',
  delete_record_fail: 'Ошибка! Не удалось удалить запись',
  view_lesson_public: 'Публичный просмотр урока',
  settings: 'Настройки',
  enable_homework_notification: 'Включить уведомления о домашних заданиях',
  rating: 'Средняя оценка пользователей',
}
