export function getFieldLocale(
  value: string | Record<string, string>,
  locale = 'en'
): string {
  return typeof value === 'object' ? value[locale] || value.en : value
}

export function parseFieldLocales(
  value?: string
): null | string | Record<string, string> {
  value ||= ''

  try {
    const data = JSON.parse(value, (_key, value) =>
      typeof value === 'number' ? value.toString() : value
    )

    return data
  } catch (error) {
    return value.toString()
  }
}
