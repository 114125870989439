export default {
  central_western_europe: 'Central & Western Europe',
  eastern_europe: 'Eastern Europe',
  north_america: 'North America',
  asia: 'Asia',
  south_central_america: 'South & Central America',
  middle_east: 'Middle East',
  africa: 'Africa',
  australia_oceania: 'Australia & Oceania',
}
