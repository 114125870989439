import { Expose, Transform } from 'class-transformer'
import { BaseModel } from './baseModel'

export class Media extends BaseModel {
  id!: number
  category!: string
  name!: string

  @Expose({ name: 'mime_type' })
  mimeType!: string

  @Expose({ name: 'thumb_url' })
  thumbUrl!: string

  lr!: string
  hr!: string
  url!: string

  @Transform(({ value }) => value || 'en', { toClassOnly: true })
  locale?: string
}

export const getLocalMedia = (media: Media[], locale = 'en') => {
  if (!media?.length) { return null }

  return media.find(item => item.locale === locale) || media.find(item => item.locale === 'en')
}
