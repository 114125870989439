import { reactive, readonly } from 'vue'
import router from '/~/router'
import { useAnalytic } from './analytic'
import { RouteRecordName } from 'vue-router'

const routeData = reactive({
  params: {} as any,
  query: {} as any,
  path: null as null | string,
  fullPath: null as null | string,
  name: null as RouteRecordName | null | undefined
})

router.afterEach((to, from) => {
  routeData.params = to.params
  routeData.query = to.query
  routeData.path = to.path
  routeData.name = to.name
  routeData.fullPath = to.fullPath
  const { openPage } = useAnalytic()

  if (from.name !== to.name) {
    openPage(to.path)
  }
})

export function useRoute() {
  return routeData
}

export function useRouter() {
  return readonly(router)
}
