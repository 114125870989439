export default {
  bronze_sponsor: 'Бронзовый спонсор',
  silver_sponsor: 'Серебряный спонсор',
  gold_sponsor: 'Золотой спонсор',
  platinum_sponsor: 'Платиновый спонсор',
  organizer: 'Организатор',
  general_sponsor: 'Генеральный спонсор',
  diamond_sponsor: 'Бриллиантовый спонсор',
  sponsor: 'Спонсор',
  partner: 'Партнер',
}
