<template>
  <BaseButton
    @click="selectVideo">
    {{ getLocal('academy.wnmeet_select_record') }}
  </BaseButton>
  <BaseModal
    :title="getLocal('academy.wnmeet_records')"
    :is-open="isVideoSelectOpen"
    :is-loading="isVideoSelectLoading"
    size="xl"
    @close="isVideoSelectOpen = false">
    <div class="flex flex-col gap-10">
      <div v-if="videos.length === 0"
           class="text-gray-500 text-center">
        {{ getLocal('academy.wnmeet_no_records') }}
      </div>
      <div v-for="video in videos">
        <div v-observe-visibility="(visible: boolean) => visible && (video.setTempVideoSrc())">
          <BaseRatio
            :ratio="9/16">
            <video
              v-if="video.tempVideoSrc"
              class="w-full h-full rounded-lg"
              controls>
              <source :src="video.tempVideoSrc"
                      type="video/mp4">
            </video>
            <div v-else>
              <BaseSpinner />
            </div>
          </BaseRatio>
          <div class="flex items-center mt-2 justify-between">
            <div class="text-sm text-gray-700">
              {{ video.formattedDate }}
            </div>
            <BaseButton size="sm"
                        @click="() => onVideoClick(video.link)">
              {{ getLocal('academy.wnmeet_select_video_item') }}
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import BaseRatio from 'client-core/components/ratio/base-ratio.vue'
import BaseSpinner from 'client-core/components/spinner/base-spinner.vue'
import vObserveVisibility from '/-/directives/visibility'
import { useLocale } from '/-/plugins/locale'
import { useWNMeet } from '/~/state/wnmeet'
import { useEvents } from '/~/state/events'
import { useConfig } from '/~/plugins/config'
import BaseModal from '/~/components/base/base-modal.vue'
import BaseButton from '/-/components/button/base-button.vue'

const { getLocal } = useLocale()
const { eventId } = useEvents()
const { config } = useConfig()
const isVideoSelectLoading = ref(false)
const isVideoSelectOpen = ref(false)
const { videos, isFetched, fetchRoomVideos } = useWNMeet()

const emit = defineEmits(['select'])

async function selectVideo() {
  isVideoSelectOpen.value = true
  isVideoSelectLoading.value = true
  !isFetched.value && await fetchRoomVideos(eventId.value as number, config.value.wnmeet?.roomId as string)
  isVideoSelectLoading.value = false
}

function onVideoClick(url: string) {
  emit('select', url)
  isVideoSelectOpen.value = false
}
</script>
