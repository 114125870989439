export default {
  edit_success: 'The private note was successfully edited',
  edit_error: 'Error, failed to edit the private note',
  delete_success: 'The private note was successfully deleted',
  delete_error: 'Error, failed to delete the private note',
  create_success: 'A new private note was successfully created',
  create_error: 'Error, failed to create a new private note',
  create_template_success: 'A private note was successfully created',
  create_template_success_description: 'You can edit the note with your personal comments about the user',
  no_notes: 'No private notes yet',
  private_notes: 'Private notes',
  load_more: 'Load more',
  add_private_note: 'Add private note',
  edit: 'Edit',
  delete: 'Delete',
  edit_note: 'Edit private note',
  save_note: 'Save private note',
  delete_note: 'Delete private note',
  delete_confirm_text: 'Are you sure to delete the note?',
  create_note: 'Create private note',
  note_placeholder: 'Your private note here',
  your_note: 'Private note',
  template_event: 'Event',
  view_all_notes: 'view all private notes'
}
