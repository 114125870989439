import { Expose, Type } from 'class-transformer'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import { useAuth } from '/-/plugins/auth'
import { plainToClass, Observer } from '/-/plugins/helpers'
import { User } from '/~/models/user'
import { useProfile } from '/~/state/profile'
import { useEvents } from '/~/state/events'
import { ConfigInterface } from '/~/plugins/config'
import { AnyCallback } from '/~/types'

export class Notification {
  id!: string
  type!: string
  time!: string

  @Expose({ name: 'meeting_id' })
  meetingId!: number

  @Type(() => User)
  sender!: User
}

window.Pusher = Pusher

const { auth } = useAuth()
const { profile } = useProfile()
const { event } = useEvents()
const observer = new Observer()

let echo: Echo | null = null

function initEcho() {
  echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_MIX_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_MIX_PUSHER_APP_CLUSTER,
    authEndpoint: import.meta.env.VITE_MIX_PUSHER_AUTH_URL,
    auth: {
      headers: {
        Authorization: 'Bearer ' + auth.value?.accessToken
      },
    },
  })

  echo.private('users.' + profile.value?.id).notification((n: Notification) => {
    const notification = plainToClass(n, Notification)

    switch (notification.type) {
    case 'MeetingRequestedNotification':
      observer.broadcast('new-meeting-request', notification)
      break
    case 'MeetingDeclinedNotification':
      observer.broadcast('meeting-declined', notification)
      break
    case 'MeetingAcceptedNotification':
      observer.broadcast('meeting-accepted', notification)
      break
    case 'MeetingRescheduledNotification':
      observer.broadcast('meeting-rescheduled', notification)
      break
    }
  })

  echo.private('events.' + event.value?.id).listen('EventConfigChangedEvent', (data: ConfigInterface) => {
    observer.broadcast('config-update', data)
  })
}

function disconnectEcho() {
  if (echo) {
    echo.disconnect()
  }
}
export function useEcho() {
  return {
    initEcho,
    disconnectEcho,
  }
}

export function useEchoObserver() {
  return {
    unsubscribe() {
      observer.unsubscribe()
    },
    onConfigUpdate(callback: AnyCallback) {
      return observer.subscribe('config-update', callback)
    },
    onNewMeetingRequest(callback: AnyCallback) {
      observer.subscribe('new-meeting-request', callback)
    },
    onMeetingDeclined(callback: AnyCallback) {
      observer.subscribe('meeting-declined', callback)
    },
    onMeetingAccepted(callback: AnyCallback) {
      observer.subscribe('meeting-accepted', callback)
    },
    onMeetingReschedules(callback: AnyCallback) {
      observer.subscribe('meeting-rescheduled', callback)
    }
  }
}
