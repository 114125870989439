export enum Device {
  Windows = 'windows',
  WindowsPhone = 'windows_phone',
  Android = 'android',
  IOS = 'ios',
  Unknown = 'unknown',
}

export default function device() {
  const userAgent = navigator.userAgent || navigator.vendor

  if (/Windows NT/i.test(userAgent)) {
    return Device.Windows
  }

  if (/windows phone/i.test(userAgent)) {
    return Device.WindowsPhone
  }

  if (/android/i.test(userAgent)) {
    return Device.Android
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return Device.IOS
  }

  return Device.Unknown
}
