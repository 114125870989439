import {
  create,
  NConfigProvider,
  NInput,
  NDatePicker,
  NSpace,
  NFormItem,
  NInputNumber,
  NForm,
  NButton,
  NButtonGroup,
  NSelect,
  NRadio,
  NRadioGroup,
  NSwitch,
} from 'naive-ui'

export default create({
  components: [
    NButton,
    NForm,
    NInputNumber,
    NFormItem,
    NSpace,
    NDatePicker,
    NInput,
    NConfigProvider,
    NButtonGroup,
    NSelect,
    NRadio,
    NRadioGroup,
    NSwitch,
  ],
})
