import device, { Device } from '/~/plugins/utils/device'
import { Capacitor } from '@capacitor/core'

const { PROD, VITE_ENV, VITE_IS_GENERAL } = import.meta.env

const isNative = Capacitor.isNativePlatform()
const platform = isNative ? Capacitor.getPlatform() : device()
const isMobile = platform === Device.IOS || platform === Device.Android
const isProd = VITE_ENV === 'production' && PROD
const isGeneral = window.config ? window.config.slug === 'general' : VITE_IS_GENERAL === 'true'

export function useApplication() {
  return {
    isNative,
    platform,
    isMobile,
    isProd,
    isGeneral,
    isIOS: platform === Device.IOS,
    isAndroid: platform === Device.Android,
  }
}
