import { AnalyticsInterface } from '/~/plugins/config'
import config from './config'
import { checkConfig, checkAdBlock, createTag } from './helpers'
import router from '/~/router'

export async function initGoogleTag(options: AnalyticsInterface) {
  config.id = options.id
  config.debug = options.debug
  config.ignoreRoutes = options.ignoreRoutes

  try {
    checkConfig()
    checkAdBlock()

    const tag = await createTag()

    tag.gTagPush({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })

    // this approach do not activate GTM properly
    // tag.gTag('js', new Date())
    // tag.gTag('config', config.id)

    // GA4 tracks url change on its side, support custom view event require proper setup on gtm/ga side
    router.afterEach((to, from) => {
      if ((options.ignoreRoutes || []).indexOf(to.name as string) > -1) { return }

      if (options.skipSamePath && to.path === from.path) { return }

      tag.gTagView(to.name, to.path, { referer: from.path })
    })
  } finally {
    // !
  }
}

export function useGoogleTag() {
  return {
    initGoogleTag
  }
}
