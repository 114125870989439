<template>
  <div class="relative">
    <label
      v-if="label"
      class="block text-sm font-medium leading-5 text-gray-700 mb-1">
      {{ label }}
      {{ required ? '*' : '' }}
    </label>
    <div class="">
      <input
        ref="input"
        class="hidden"
        v-bind="$attrs"
        type="file"
        :accept="accept.join(', ')"
        :multiple="multiple"
        tabindex="-1"
        aria-label=""
        @change="onChange">

      <div
        ref="dropZone"
        class="flex justify-center overflow-hidden transition duration-150 ease-in-out"
        :class="[
          proxyBorder,
          proxyRounded,
          dragover && 'shadow-outline-blue border-blue-300',
          errorMessage ? 'border-red-400' : 'border-gray-300',
        ]">
        <div
          class="cursor-pointer flex-1"
          :class="props.loading && 'pointer-events-none'"
          @click="!cropper && onTarget()">
          <slot name="preview">
            <img
              v-if="cropperPreview || preview"
              ref="imageElement"
              :class="props.loading && 'animate-pulse'"
              class="block w-full"
              :src="cropperPreview || preview || ''">
            <div
              v-else
              class="space-y-1 text-center px-6 py-5"
              :class="props.loading && 'animate-pulse'">
              <svg
                class="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true">
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
              <p class="text-sm text-gray-600">
                <button
                  class="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  type="button">
                  Upload a file
                </button>
                or drag and drop
              </p>
              <p class="text-xs text-gray-500">
                {{
                  accept
                    .map((item) => item.split('/')[1])
                    .join(', ')
                    .toUpperCase()
                }}
                up to
                {{ maxSize }}MB
              </p>
            </div>
          </slot>
        </div>
      </div>
    </div>
    <div class="flex pt-px text-sm leading-4">
      <transition
        enter-active-class="transition-all duration-300"
        enter-from-class="transform -translate-y-3 opacity-0"
        enter-to-class="transform translate-y-0 opacity-100"
        leave-active-class="transition-all duration-300"
        leave-from-class="transform translate-y-0"
        leave-to-class="transform -translate-y-3 opacity-0">
        <span
          v-if="!disabled && (errorMessage || $slots.error)"
          class="text-red-600">
          <slot name="error">
            {{ errorMessage }}
          </slot>
        </span>
      </transition>
    </div>
    <div v-if="useCropper"
         class="flex flex-wrap gap-2 mt-4 justify-center">
      <div v-if="!cropperRatio"
           class="flex gap-1">
        <BaseButton
          :disabled="!cropper"
          @click="setCropperRatio(16 / 9)">
          16:9
        </BaseButton>
        <BaseButton
          :disabled="!cropper"
          @click="setCropperRatio(4 / 3)">
          4:3
        </BaseButton>
        <BaseButton :disabled="!cropper"
                    @click="setCropperRatio(1)">
          1:1
        </BaseButton>
        <BaseButton
          :disabled="!cropper"
          @click="setCropperRatio(2 / 3)">
          2:3
        </BaseButton>
        <BaseButton :disabled="!cropper"
                    @click="setCropperRatio()">
          Free
        </BaseButton>
      </div>
      <div class="flex gap-1">
        <BaseButton v-if="showUploadButton"
                    size="sm"
                    @click="onTarget">
          Upload
        </BaseButton>
        <BaseButton :disabled="!cropper"
                    size="sm"
                    theme="success"
                    @click="onCrop">
          Save
        </BaseButton>
        <BaseButton v-if="allowDelete"
                    size="sm"
                    theme="destructive"
                    @click="onRemove">
          Remove
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, ref, onMounted, onUnmounted, computed, nextTick } from 'vue'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import { readFile, getImageExtension } from '/~/plugins/utils/readFile'
import BaseButton from '/-/components/button/base-button.vue'

const rounded = {
  normal: 'rounded',
  sm: 'rounded-sm',
  md: 'rounded-md',
  lg: 'rounded-lg',
  full: 'rounded-full',
  none: '',
}

const border = {
  dashed: 'border-2 border-dashed',
  solid: 'border-2',
  none: '',
}

const props = defineProps({
  modelValue: {
    type: [Object, String] as PropType<File | string>,
    default: undefined,
  },
  value: {
    type: [Object, String] as PropType<File | string>,
    default: undefined,
  },
  preview: {
    type: String,
    default: '',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: '',
  },
  rules: {
    type: [String, Function, Object],
    default: '',
  },
  error: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  accept: {
    type: Array as PropType<string[]>,
    default: () => ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  maxSize: {
    type: Number,
    default: 10,
  },
  rounded: {
    type: String as PropType<keyof typeof rounded>,
    default: 'normal',
  },
  border: {
    type: String as PropType<keyof typeof border>,
    default: 'dashed',
  },
  allowDelete: {
    type: Boolean,
    default: false,
  },
  showUploadButton: {
    type: Boolean,
    default: true
  },
  useCropper: {
    type: Boolean,
    default: false,
  },
  cropperRatio: {
    type: Number,
    default: undefined,
  },
})

const emits = defineEmits(['update:modelValue', 'drop', 'change', 'error:size', 'remove'])
const dropZone = ref<InstanceType<typeof HTMLDivElement> | null>(null)
const input = ref<InstanceType<typeof HTMLInputElement> | null>(null)

const cropperPreview = ref<string | null>(null)
const imageElement = ref<HTMLImageElement | null>(null)
const cropper = ref<Cropper | null>(null)
const cropperFileType = ref('image/jpeg')

onMounted(() => {
  addEventListener(
    document,
    'drag dragstart dragend dragover dragenter dragleave drop',
    onDragPrevent
  )
  addEventListener(dropZone.value, 'dragover dragenter', onDragOver)
  addEventListener(dropZone.value, 'dragleave dragend drop', onDragStop)
  addEventListener(dropZone.value, 'drop', onDrop)
})

onUnmounted(() => {
  removeEventListener(
    document,
    'drag dragstart dragend dragover dragenter dragleave drop',
    onDragPrevent
  )
  removeEventListener(dropZone.value, 'dragover dragenter', onDragOver)
  removeEventListener(dropZone.value, 'dragleave dragend drop', onDragStop)
  removeEventListener(dropZone.value, 'drop', onDrop)

  cropper.value?.destroy()
})

const dragover = ref(false)
const files = ref([])

const proxyRounded = computed(() => props.rounded && rounded[props.rounded])
const proxyBorder = computed(() => props.border && border[props.border])
const errorMessage = computed(() => props.error)

function addEventListener(
  el: HTMLElement | Document | null,
  eventsList: string,
  listener: (e: any) => void
) {
  const events = eventsList.split(' ')

  events.forEach((event) => {
    el?.addEventListener(event, listener)
  })
}

function removeEventListener(
  el: HTMLElement | Document | null,
  eventsList: string,
  listener: (e: any) => void
) {
  const events = eventsList.split(' ')

  events.forEach((event) => {
    el?.removeEventListener(event, listener)
  })
}

function clear() {
  if (input.value) {
    input.value.value = '' // [] ?
  }
  files.value = []
}

function onDragPrevent(e: DragEvent) {
  e.preventDefault()
  e.stopPropagation()
}

function onDragOver() {
  dragover.value = true
}

function onDragStop() {
  dragover.value = false
}

function checkSize(file: File | null) {
  // file size after cropper is much bigger as it stored in uncompressed way it looks like
  // so we check it right after open
  if (!file) {
    return false
  }
  if (file.size > props.maxSize * 1024 * 1024) {
    emits('error:size')
    return false
  }

  return true
}

function onDrop(e: DragEvent) {
  e.preventDefault()

  if (e.dataTransfer?.items) {
    const files = Object.values(e.dataTransfer.items)
      .filter((file) => {
        return !props.accept || props.accept.includes(file.type)
      })
      .map((file) => file.getAsFile())

    if (!checkSize(files[0])) {
      return
    }
    if (props.useCropper) {
      initCropper(files[0])
    } else {
      addFiles(files)
    }
    emits('drop', files)
  }
}

function onChange(event: Event) {
  const target = event.target as HTMLInputElement

  if (target.files) {
    const items = Object.values(target.files).filter((file) => {
      return !props.accept || props.accept.includes(file.type)
    })

    if (!checkSize(items[0])) {
      return
    }

    if (props.useCropper) {
      initCropper(items[0])
    } else {
      addFiles(items)
    }
  }
}

function onRemove() {
  cropper.value?.destroy()
  cropperPreview.value = null
  cropper.value = null
  emits('remove')
}

function onTarget() {
  input.value?.click()
}

function setCropperRatio(ratio?: number) {
  if (!cropper.value) {
    return
  }

  cropper.value.setAspectRatio(ratio || NaN)
}

async function initCropper(file: File | null) {
  if (file) {
    const fileData = await readFile(file)

    cropperFileType.value = file.type

    if (fileData) {
      cropperPreview.value = fileData.toString()
    }

    nextTick(() => {
      if (props.useCropper && imageElement.value) {
        if (cropper.value) {
          cropper.value.destroy()
        }

        cropper.value = new Cropper(imageElement.value, {
          viewMode: 2,
          aspectRatio: props.cropperRatio,
          autoCropArea: 1,
          rotatable: false,
        })
      }
    })
  }
}

function onCrop() {
  if (!cropper.value) {
    return
  }

  const canvas = cropper.value?.getCroppedCanvas()

  cropperPreview.value = canvas.toDataURL('image/jpeg')

  canvas.toBlob((blob) => {
    if (!blob) {
      return
    }

    const file = new File(
      [blob],
      `cropped-image${getImageExtension(cropperFileType.value)}`,
      {
        lastModified: Date.now(),
        type: cropperFileType.value,
      }
    )

    cropper.value?.destroy()
    cropper.value = null
    addFiles([file])
  })
}

function addFiles(files: Array<File | null>) {
  if (files && files.length) {
    emits('change', props.multiple ? files : files[0])
    emits('update:modelValue', props.multiple ? files : files[0])

    nextTick(() => {
      // TODO: allow validation via use-simple-input
      // if (validate) {
      //   handleBlur()
      //   handleInput(props.modelValue)
      //   validate()
      // }
      clear()
    })

    return files
  }
}

defineExpose({ onTarget })
</script>
