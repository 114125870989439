export default {
  up: 'Переместить выше',
  down: 'Переместить ниже',
  remove: 'Удалить',
  edit_block: 'Редактировать блок',
  save: 'Сохранить',
  cancel: 'Отменить',
  open_block_menu: 'Добавить новый блок',
  open_menu: 'Кликните на блок в меню и он появится внизу страницы',
  blocks: 'Блоки',
  close_menu: 'Закрыть',
  apply: 'Применить',
  have_unsaved_data: 'У вас есть несохранённые изменения!',
  reset: 'Сбросить',
  edit: 'Редактировать',
  close: 'Закрыть',
  block_confirm_delete_title: 'Да',
  block_confirm_delete_text: 'Вы уверенны, что хотите удалить этот блок?',
  dublicate: 'Дублировать',
  hide: 'Скрыть',
  show: 'Показать',
  send_form_success: 'Запрос был успешно отправлен',
  send_form_error: 'Ошибка! Не удалось отправить запрос',
  send_form_success_default_text: 'Запрос был успешно отправлен!',
  send_form_error_default_text: 'Ошибка! Не удалось отправить запрос. Разработчики будут уведомлены об этой проблеме.',
}
