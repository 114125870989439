export default {
  create: 'Создание встречи',
  create_online: 'Создание онлайн встречи',
  is_online: 'Онлайн встреча',
  agenda: 'Тема встречи',
  date: 'Дата',
  time_slot: 'Время',
  time: 'Время',
  request: 'Запросить',
  participants: 'Участники',
  cancel: 'Отмена',
  meetings: 'Встречи',
  meeting: 'Встреча',
  join_meeting: 'Видеозвонок',
  calendar: 'Календарь',
  select_timeslot_prompt: 'Выберите время',
  today: 'Сегодня',
  invites: 'Приглашения',
  upcoming: 'Предстоящие',
  location: 'Локация',
  online: 'Онлайн',
  onsite: 'On-site',
  invited: 'Приглашен',
  accepted: 'Принято',
  rejected: 'Отклонено',
  canceled: 'Отменено',
  cancel_verb: 'Отменить',
  reschedule: 'Перенести',
  send_message: 'Сообщение',
  reject: 'Отклонить',
  decline: 'Decline',
  accept: 'Принять',
  reason: 'Причина',
  cancel_meeting: 'Отменить встречу',
  save: 'Сохранить',
  day: 'День',
  days: 'Дней',
  week: 'Неделя',
  time_slot_locked_title: 'Вы заблокировали этот слот',
  time_slot_locked_message: `Слот заблокирован.
  Разблокируйте его в календаре или перенесите встречу на другой слот.`,
  time_slot_busy_message: `Слот занят.
  Перенесите встречу на другой слот.`,
  no_meetings: 'Нет предстоящих встреч',
  status_outcoming: 'исходящее приглашение',
  status_declined: 'отменено',
  status_accepted: 'принято',
  status_incoming: 'входящее приглашение',
  status_waiting: 'waiting',
  status_blocked: 'blocked',
  unlock: 'разблокировать',
  lock: 'заблокировать',
  location_hint_text: 'Чтобы сориентироваться в экспозоне, ознакомьтесь с',
  location_hint_link: 'планом этажа',
  my_calendar: 'Мой календарь на',
  month: 'Месяц',
  calendar_hint_meetings: 'Есть встречи в этот день',
  next_day: 'Next day',
  prev_day: 'Previous day',
  invites_modal_title: 'Requests',
  invites_modal_subtitle: 'You have unconfirmed meetings. Please review and respond.',
  view_all_requests: 'View all requests',
  invites_page_title: 'Your waiting requests',
  invites_page_subtitle: 'You have unconfirmed meetings. Please review and respond.',
  go_to_calendar: 'Go to calendar',
  back_to_calendar: 'Back to calendar',
  back_to_invites: 'Back to invites',
  no_invites: 'No meeting requests require your attention.',
  create_meeting: 'Create meeting',
  show_more: 'Show more',
  requests_warning_title: 'You have incoming requests',
  requests_warning_subtitle: 'waiting for your decision.',
  view: 'View',
  meeting_view_status_waiting_user_response: 'Status: waiting for your response',
  meeting_view_status_waiting_other_response: 'Status: waiting for :name\'s response',
  meeting_view_status_accepted: 'Status: the meeting is accepted',
  meeting_view_status_declined_by_user: 'Status: you declined the meeting',
  meeting_view_status_declined_by_other: 'Status: the meeting was declined',
  suggets_changes: 'Suggets changes',
  organizer: 'Organizer',
  new_meeting: 'New meeting request',
  new_online_meeting: 'New online meeting request',
  choose_participants: 'Select a member by clicking on their name. There are :count members avalible at this event.',
  sent_notification: 'Send a notification 10 minutes before the meeting',
  select_notification_time: 'Select notification time',
  event_is_over: 'Sorry, this event is over.',
  cannot_create_meetings: 'You can\'t create meetings anymore.',
  no_avaliable_timeslots: 'Sorry, there are no available timeslots for this day.',
  choose_another_day: 'Please try choosing another day.',
  timeslot_busy: 'This slot is blocked or contains approved meetings',
  online_only: 'online only',
  success_screen_title: 'Success',
  success_screen_subtitle: 'A request for a meeting has been sent to the user',
  success_screen_edit_subtitle: 'The request to change the meeting was successfully sent',
  add_to_google_calendar: 'Add to Google Calendar',
  add_to_calendar: 'Добавить в Календарь',
  meeting_point: 'Meeting point',
  open_meeting: 'Open meeting',
  removed_location: 'The location has been removed, please contact the meeting organizer',
  decline_form_description: 'Please let us know the reason for cancelling the meeting. This information helps us improve our services. Your privacy will be respected, and other users will not see this information.',
  decline_reason_1: 'Irrelevant contact',
  decline_reason_2: 'I\'ve changed my plans',
  decline_reason_3: 'It\'s spam',
  decline_reason_custom: 'Your reason',
  send: 'Send',
  reschedule_meeting: 'Reschedule meeting',
  meeting_is_past: 'This meeting has already passed',
  participant: 'Particiant',
  participant_suggested_to_change: ':participant suggested to change',
  change_details: ':type from :old to :new',
  meeting_from_another_event: 'This meeting is from another event',
  initial_timedate_is_invalid: 'Your selected time or date was not available. The nearest available option was automatically selected.',
  change_meeting: 'Change meeting',
  view_meeting: 'View meeting',
  decline_meeting_question: 'Do you really want to cancel the meeting?',
  online_meeting: 'Online meeting',
  onsite_meeting: 'On-site meeting',
  filter_approved: 'Approved',
  filter_rejected: 'Rejected',
  filter_waiting: 'Waiting',
  does_not_participate_onsite: 'doesn\'t participate on-site',
  no_meetings_with_status: 'No meetings with this status',
  cancel_confirm_title: 'Are you sure you want to close the form?',
  cancel_confirm_text: 'In this case, the data will not be saved.',
  meetings_over: 'Sorry, it is no longer possible to create meetings.'
}
