<template>
  <Suspense v-if="component">
    <component :is="component" />
  </Suspense>
  <RouterView v-else
              v-slot="scope">
    <slot v-bind="component ? {...scope, Component: component} : scope" />
  </RouterView>
</template>

<script lang="ts">
import { Component, defineComponent, markRaw } from 'vue'
import { useDisplayRoute } from '/~/plugins/display-route'

export default defineComponent({
  name: 'RootRouterView',
  data() {
    return {
      component: undefined as Component | undefined | null
    }
  },
  watch: {
    '$route'() {
      this.component = null
    }
  },
  created() {
    const { onDisplayRouteRequest } = useDisplayRoute()

    onDisplayRouteRequest(async (data) => {
      await this.$router.isReady()

      // @ts-expect-error because we use some undocumented things
      const module = await this.$router.resolve(data).matched[1].components?.default()
      const component = module.default

      this.component = markRaw(component)
    })
  }
})
</script>
