export interface BannerInterface {
  place_id: string
  href_link: string
  href_type: string
  href_id: number
  media: {
    category: string
    name: string
    url: string
    lr: string
  }[]
  background: string
  background_css: string
  sizes: Record<string, number[]>
  url: string
}
export interface PlaceInterface {
  id: string,
  style: any,
  banners: BannerInterface[]
}

export class Branding {
  constructor(public raw: PlaceInterface[]) {
    this.deserialize(raw)
  }

  places: {
    name: string,
    style: any,
    banners: BannerInterface[]
  }[] = []

  getGroup(group: string) {
    return this.places.find((place) => place.name === group)
  }

  deserialize(raw: PlaceInterface[]) {
    raw.forEach((place) => {
      this.places.push({
        name: place.id,
        ...place
      })
    })
  }
}
