export default {
  not_select_title: 'Добро пожаловать в чат WN Hub!',
  not_select_text: 'Начать общение с другими участниками мероприятия просто!',
  not_select_text_1: 'Найдите профиль интересующиего вас человека.',
  not_select_text_2: 'Нажмите "Отправить сообщение" в выбранном профиле и напишите ваше сообщение.',
  not_select_text_3: 'Готово! Также вы можете отвечать на сообщения от других пользователей WN Hub.',
  not_select_text_mobile: 'Перейдите в интересующий вас профиль собеседника чтобы начать новый диалог',
  members_start_chat: 'Чат',
  reply: 'Ответить',
  delete: 'Удалить',
  type: 'Введите сообщение...',
  main_chat: 'Общий чат',
  meeting_ready: 'Встреча готова',
  join: 'Подключиться',
  share: 'Поделиться',
  one_member: 'Вы единственный участник',
  members: 'Участники',
  join_meeting: 'Присоединиться',
  settings: 'Настройка связи',
  all_chats: 'Все чаты',
  private_chats: 'Личные чаты',
  company_chats: 'Чаты компании',
  lesson_chats: 'Чаты уроков',
  promo_chats: 'Промо чаты',
  grid: 'Сетка',
  grid_plus: 'Сетка со мной',
  accent: 'Акцент',
  exit: 'Выйти',
  copy_link: 'Скопировать ссылку',
  joining_info: 'Ссылка для подключения',
  show_chat: 'Показать чат',
  hide_chat: 'Спрятать чат',
  start_chat: 'Открыть чат',
  audio_device: 'Аудио',
  video_device: 'Видео',
  question_speaker: 'Вопрос спикеру',
  important_message: 'Важное сообщение',
  important_message_description: 'Все участники получат уведомление',
  unread_hidden_message: 'Непрочитанные сообщения скрыты',
  empty: 'Go to the Attendees section and find relevant people to make business with!',
  mute: 'Заблокировать',
  unmute: 'Разблокировать',
  report: 'Пожаловаться на сообщение',
  promo_reply: 'Ответить',
  promo_no_reply: 'Вы не можете ответить на это сообщение, так как являетесь сотрудником компании',
  report_message_success: 'Жалоба на сообщение успешно отправлена',
  report_message_error: 'Ошибка, не удалось отправить жалобу на сообщение',
  report_message_too_many: 'Вы можете отправить жалобу только на 1 сообщение пользователя в час',
  send_message_ban: 'Вам запрещена отправка новых сообщений до :expiration, причина: :reason',
  send_message_channel: 'Канал :channel не существует',
  send_message_error: 'Ошибка, не удалось отправить сообщение',
  upload_size_error: 'Слишком большой размер файла, максимальный размер :size Мб',
  download_error: 'Ошибка! Не удалось получить ссылку для скачивания файла',
  upload_error: 'Ошибка! Не удалось загрузить файл :name',
  upload_success: 'Файл :name успешно загружен',
  homework_message: 'Домашнее задание',
  homework_message_declined: 'Домашнее задание отклонено!',
  homework_message_approved: 'Домашнее задание принято!',
  text_homework_receieved: 'Домашнее задание',
  text_homework_approved: 'Домашнее задание принято',
  text_homework_declined: 'Домашнее задание отклонено',
  text_attachments_recevied: 'Сообщение с вложениями',
  open_lesson: 'Перейти к уроку',
  open_homework: 'Перейти к домашним заданиям',
  student_homework_message: 'Пожалуйста, ожидайте проверки домашнего задания',
  student_homework_message_declined: 'Вы можете ещё раз отправить домашнее задание на странице урока',
  student_homework_message_approved: 'Отлично! Продолжайте выполнение курса',
  no_channels: 'Нет таких каналов',
  delivered: 'Доставлено',
  read: 'Прочитано',
  upload_limit: 'размер до :size Mb',
}
