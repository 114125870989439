export default {
  required: '必填',
  email: '请输入有效电子邮件地址',
  min: '请至少输入0:{length}个字符',
  max: '请不要输入超过0:{length}个字符',
  confirmed: '无法确认。输入值不匹配。',
  regex: '无效字符',
  url: '无效链接',
  youtube: '无效YouTube链接',
  name: '无效字符',
  selected_options_limit: 'Maximum number of selected options: :limit',
}
