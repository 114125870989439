export default {
  ar_vr: 'AR / VR',
  art: 'Искусство',
  sound: 'Звук',
  consultancy: 'Консультирование',
  development_blockchain: 'Разработка (Блокчейн)',
  development_mobile: 'Разработка (мобильный)',
  development_pc_console: 'Разработка (ПК / консоль)',
  distribution: 'Распределение',
  game_testing: 'Тестирование игр',
  investment: 'Инвестиции',
  licensing: 'Лицензирование',
  localisation: 'Локализация',
  marketing: 'Маркетинг',
  media_coverage: 'Освещение в СМИ',
  monetization: 'Монетизация',
  publishing_pc: 'Издательское дело (ПК)',
  publishing_mobile: 'Издательское дело (мобильное)',
  tools_services: 'Инструменты и услуги',
  user_acquisition: 'Привлечение пользователей',
  game_stores: 'Игровые магазины',
  recruitment: 'Набор персонала',
  analytics: 'Аналитика',
  developer: 'Разработчик',
  platform: 'Платформа',
  advertising: 'Реклама',
  graphics: 'Графика',
  billing: 'Биллинг',
  investor: 'Инвестиция',
  press: 'Пресса',
  hosting: 'Хостинг',
  retargeting: 'Ретаргетинг',
  html5_games: 'HTML5 игры',
  mobile_games: 'Мобильные игры',
  pc_console_games: 'ПК / консольные игры',
  other: 'Другое',
}
