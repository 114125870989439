import { ConnectionStatus, Network } from '@capacitor/network'
import { reactive, toRefs, readonly } from '@vue/reactivity'

const state = reactive<ConnectionStatus>({
  connected: true,
  connectionType: 'unknown',
})

getCurrentNetworkStatus()

Network.addListener('networkStatusChange', status => {
  Object.assign(state, status)
})

async function getCurrentNetworkStatus() {
  return await Network.getStatus()
}

export function useNetwork() {
  return {
    ...toRefs(readonly(state)),
    getCurrentNetworkStatus,
  }
}
