export default {
  central_western_europe: 'Центральная и Западная Европа',
  eastern_europe: 'Восточная Европа',
  north_america: 'Северная Америка',
  asia: 'Азия',
  south_central_america: 'Южная и Центральная Америка',
  middle_east: 'Средний Восток',
  africa: 'Африка',
  australia_oceania: 'Австралия и Океания',
}
