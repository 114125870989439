export default {
  select_prompt: 'Select or create',
  search_prompt: 'Company Name',
  about: 'About company',
  show: 'Open Profile',
  add_company_prompt: 'Add new one',
  company_location: 'Company location',
  company_name: 'Company Name',
  company_primary_business: 'Company primary business',
  company_site: 'Company site',
  enter_company_data: 'Enter company data or',
  employees: 'Employees',
  employees_title: 'Employees',
  employees_size: 'Employees',
  primary_business: 'Primary Business',
  name: 'Name',
  tags: 'Tags',
  logo: 'Logo',
  hero: 'Profile Hero',
  hero_size: 'Size',
  location: 'Location',
  site: 'Site',
  empty_error_text: 'Select at least one value',
  organizer: 'Organizer',
  select_company_from_list: 'select company from the list',
  sponsor_platinum: 'Platinum Sponsor',
  sponsor_gold: 'Gold Sponsor',
  sponsor_silver: 'Silver Sponsor',
  sponsor_bronze: 'Bronze Sponsor',
  unable_to_find_company_question: 'Can\'t find company?',
  games: 'Games',
  wn_manager: 'Responsible manager',
  no_wn_manager: 'No responsible manager',
  no_company: 'No company',
  members_title: 'Company members',
  member_role_placeholder: 'Select roles',
  delete_member_button: 'Delete',
  confirm_delete_member_title: 'Delete company member',
  confirm_delete_member_text: 'Are you sure you want to delete this member (:name)?',
  change_member_roles_success: 'Roles have been successfully changed',
  change_member_roles_fail: 'Failed to change roles',
  delete_member_success: 'Member has been successfully deleted from the company',
  delete_member_fail: 'Failed to delete member',
  not_enough_permissions: 'You do not have enough permissions to perform this action',
  member_you: '(You)',
  roles: {
    Chat: 'Chat Manager',
    Admin: 'Admin',
  }
}
