import { markRaw } from 'vue'
import { DEFAULT_COLOR_GRAY, DEFAULT_MARKDOWN_TEXT } from '../constants'
import View from './View.vue'
import schema from './schema'
import uiSchema from './ui-schema'

export default {
  viewComponent: markRaw(View),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {
    text: DEFAULT_MARKDOWN_TEXT,
    textColor: DEFAULT_COLOR_GRAY,
  },
}
