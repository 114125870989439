export default {
  main: 'Основной поток',
  showcase: 'Шоукейс',
  indie: 'Инди-разработка',
  roasting: 'Прожарка',
  show_lecture: 'Показать лекцию',
  speakers: 'Спикеры',
  expand: 'Развернуть',
  archive_title: 'Архив лекций',
  category_all: 'Все',
  agree_badge: 'Эксклюзивная лекция',
  agree_button: 'Посмотреть',
  back_to_lectures: 'Назад к лекциям'
}
