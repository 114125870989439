export default {
  columns: {
    items: {
      text: {
        'ui:widget': 'EditorMarkdownWidget',
      },
      textColor: {
        'ui:widget': 'EditorColorWidget',
      },
    },
  },
}
