import { createRouter, createWebHistory, RouteLocationNormalized } from 'vue-router'
import { ref } from 'vue'
import Storage from '/-/plugins/local-storage'
import { useNetwork } from '/~/plugins/network'
import routes from './routes'
import { Routes } from './types'
import { useEventRoute } from '/~/plugins/event-route'
import { useApplication } from '/~/state/application'
import { useLogin } from '/~/plugins/login'

interface RouterStorageDataInterface {
  reloadCount: number
}

const ROUTER_STORAGE_KEY = 'router'
let resizeObserver: ResizeObserver | null = null

function resetScrollHandler() {
  resizeObserver?.disconnect()
  resizeObserver = null
}

const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (from) {
      if (to.fullPath.split('?')[0] === from.fullPath.split('?')[0]) return
    }
    if (savedPosition) {
      return new Promise((resolve) => {
        resizeObserver = new ResizeObserver(entries => {
          if (entries[0].target.clientHeight >= savedPosition.top + window.innerHeight) {
            resetScrollHandler()
            resolve(savedPosition)
          }
        })

        const container = document.querySelector('#app>div')

        if (container) {
          resizeObserver.observe(container)
        } else {
          resolve(savedPosition)
        }
      })
    }
    if (from.meta?.saveScrollPosition || to.meta?.saveScrollPosition) {
      return {}
    }

    return { left: 0, top: 0 }
  }
})

router.replaceWithinEvent = async location => {
  const { eventRoute } = useEventRoute(ref(location))

  return router.replace(eventRoute.value || '/')
}

router.pushWithinEvent = async location => {
  const { eventRoute } = useEventRoute(ref(location))

  return router.push(eventRoute.value || '/')
}

const RELOAD_COUNT_LIMIT = 1

let lastRouterBeforeTo: RouteLocationNormalized

router.isReady().then(() => {
  const routerStorage: RouterStorageDataInterface = Storage.get(ROUTER_STORAGE_KEY) || { reloadCount: 0 }

  Storage.set(ROUTER_STORAGE_KEY, { ...routerStorage, reloadCount: 0 })
})

router.beforeEach(async (to: RouteLocationNormalized) => {
  resetScrollHandler()
  const { isGeneral } = useApplication()
  const { getCurrentNetworkStatus } = useNetwork()
  const isConnected = (await getCurrentNetworkStatus()).connected // it is more stable than event variable for some reason

  if (to && !isConnected && to.name !== Routes.NetworkError) {
    return { name: Routes.NetworkError, params: { pathMatch: to.path.substring(1).split('/') }}
  }

  lastRouterBeforeTo = to

  if (!to.params?.eventSlug && isGeneral && to.meta.isForbiddenOnGeneral) {
    return { name: Routes.NotFound, params: { pathMatch: to.path.substring(1).split('/') }}
  }
})

router.onError(error => {
  const routerStorage: RouterStorageDataInterface = Storage.get(ROUTER_STORAGE_KEY) || { reloadCount: 0 }
  const isAssetsUpdated = /fetch dynamically imported module/.test(error.message)
  // we want to reload only in case there is no required assets (assets with updated hashes were uploaded)
  // TODO: make autotest for this case to make sure that Vue error text is not updated

  if (isAssetsUpdated && lastRouterBeforeTo && routerStorage.reloadCount < RELOAD_COUNT_LIMIT) {
    Storage.set(ROUTER_STORAGE_KEY, { ...routerStorage, reloadCount: routerStorage.reloadCount + 1 })
    console.warn('App assets were updated, try to reload')

    if (location.href === lastRouterBeforeTo.fullPath) {
      useLogin().reload()
    } else {
      location.href = router.options.history.createHref(lastRouterBeforeTo.fullPath)
    }
  }
})

export default router
