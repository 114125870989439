import { basePropertiesSchema } from '../base-properties-schema'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'IFrame',
  description: 'IFrame block to embed another page',
  type: 'object',
  required: ['url'],
  properties: {
    ...basePropertiesSchema,
    height: {
      title: 'Height in pixels',
      'ui:options': {
        placeholder: 500,
        description:
          'For matching the height of the embedded page, please refer to the Iframe height section in the Integration Instructions',
      },
      type: 'number',
    },
    url: {
      title: 'IFrame URL',
      type: 'string',
      'ui:options': {
        placeholder: 'https://...',
      },
    },
    preloader: {
      title: 'Show preloader',
      type: 'boolean',
    },
  },
}
