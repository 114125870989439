export default {
  lectures: 'Lectures Streaming',
  lectures_archive: 'Lecture Archive',
  program: 'Program',
  chats: 'Messages',
  showcase: 'Showcase',
  showcase_top: 'Selected Games by WN',
  members: 'Attendees',
  companies: 'Companies',
  consultants: 'Consultants',
  vacancies: 'Jobs',
  meetings: 'Meetings',
  speakers: 'Speakers',
  group_call: 'Group call',
  zoom_call: 'Zoom Call',
  wnmeet: 'WN Meet',
  agenda: 'Agenda',
  contacts: 'Contacts',
  events: 'Events',
  publishers: 'Publisher Pavilion',
  host: 'Host your event',
  expo: 'Expo Zone',
  media: 'Media Partners',
  our_media: 'Our Media',
  partyspace: 'Party Space',
  news: 'News',
  volunteer_badges: 'Print Badges',
  home: 'Home',
  favorites: 'Favorites',
  academy_admin: 'Admin panel',
  academy_admin_home: 'Back to course',
  academy_admin_modules: 'Modules & Lessons',
  academy_admin_settings: 'Settings',
  academy_admin_homeworks: 'Homeworks',
  academy_admin_lectures: 'Lectures archive',
  academy_admin_promo_messages: 'Promo messages',
  academy_admin_students: 'Students',
  academy_program: 'Course program',
  academy_stream_records: 'Stream records',
  academy_admin_help: 'Help',
  notifications: 'Notifications',
}
