import { DEFAULT_COLOR_GRAY, DEFAULT_MARKDOWN_TEXT } from '../constants'
import { basePropertiesSchema } from '../base-properties-schema'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Text',
  description: 'Markdown text block with background',
  type: 'object',
  required: ['text'],
  properties: {
    ...basePropertiesSchema,
    text: {
      title: 'Text',
      type: 'string',
      'ui:options': {
        placeholder: DEFAULT_MARKDOWN_TEXT,
        type: 'textarea',
        rows: 6,
      },
    },
    textColor: {
      title: 'Text Color',
      type: 'string',
      default: DEFAULT_COLOR_GRAY,
    },
  },
}
