import { DEFAULT_IMAGE_SQUARE, DEFAULT_TEXT } from '../constants'
import { basePropertiesSchema } from '../base-properties-schema'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Images',
  description: 'Simple block with a configurable number of images per line',
  type: 'object',
  required: [
    'colsNumberOnDesktop',
    'colsNumberOnMobile',
    // 'rowsNumberInSliderOnMobile', // TODO: implement rows avoiding swiper grip option
  ],
  properties: {
    ...basePropertiesSchema,
    colsNumberOnDesktop: {
      title: 'Number of cols on desktop',
      type: 'number',
      default: 4,
      minimum: 1,
      maximum: 6,
    },
    colsNumberOnMobile: {
      title: 'Number of cols on mobile',
      type: 'number',
      default: 2,
      minimum: 1,
      maximum: 6,
    },
    isUseSliderOnMobile: {
      title: 'Use slider on mobile',
      type: 'boolean',
      default: true,
    },
    // rowsNumberInSliderOnMobile: {
    //   title: 'Number of rows in slider on mobile',
    //   type: 'number',
    //   default: 1,
    //   minimum: 1,
    //   maximum: 4,
    //   'ui:hidden': '{{parentFormData.isUseSliderOnMobile === false}}',
    // },
    images: {
      type: 'array',
      title: 'A list of images',
      'ui:options': {
        showIndexNumber: true,
      },
      items: {
        title: 'Image',
        type: 'object',
        properties: {
          url: {
            title: 'Source',
            type: 'string',
            default: DEFAULT_IMAGE_SQUARE,
          },
          description: {
            title: 'Description',
            type: 'string',
            default: DEFAULT_TEXT,
          }
        }
      },
    },
  },
}
