interface YandexMetrikaConfigInterface {
  id?: string | null
  name: string
  options: {
    accurateTrackBounce: boolean
    clickmap: boolean
    defer: boolean
    ecommerce: boolean
    params: any[]
    userParams: Record<string, any>
    trackHash: boolean
    trackLinks: boolean
    type: number
    webvisor:boolean
    triggerEvent: boolean
  },
  scriptSrc: string
  debug?: boolean
  ignoreRoutes?: string[]
  skipSamePath: boolean
}

const config: YandexMetrikaConfigInterface = {
  id: null,
  name: 'yandex-metrika',
  options: {
    accurateTrackBounce: true,
    clickmap: true,
    defer: false,
    ecommerce: false,
    params: [],
    userParams: {},
    trackHash: false,
    trackLinks: true,
    type: 0,
    webvisor: false,
    triggerEvent: false
  },
  scriptSrc: 'https://mc.yandex.ru/metrika/tag.js',
  debug: false,
  ignoreRoutes: [],
  skipSamePath: true
}

export default config
