import { DEFAULT_MARKDOWN_PLACEHOLDER } from '../constants'
import { basePropertiesSchema } from '../base-properties-schema'
import {
  colsNumberOnDesktopSchema,
  colsNumberOnMobileSchema,
  linkColorSchema,
  showAllSettingsSchema,
  textColorSchema,
  titlePropertiesSchema,
} from '../common'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Statistics',
  description: 'Block with statistics cards',
  type: 'object',
  required: ['text', 'colsNumberOnDesktop', 'colsNumberOnMobile'],
  'ui:options': {
    onlyShowIfDependent: true,
  },
  dependencies: {
    tmp_showAllSettings: [
      'textColor',
      'cardTextColor',
      'cardBgColor',
      'linkColor',
    ],
    title: ['titleAlign', 'hideOnEmbed'],
  },
  properties: {
    ...basePropertiesSchema,
    ...titlePropertiesSchema,
    ...showAllSettingsSchema,
    ...textColorSchema,
    ...linkColorSchema,
    cardTextColor: {
      title: 'Card Text Color',
      type: 'string',
      default: '#5818f4',
    },
    cardBgColor: {
      title: 'Card Background Color',
      type: 'string',
      default: '#eee8fe',
    },
    text: {
      title: 'Text',
      type: 'string',
      'ui:options': {
        placeholder: DEFAULT_MARKDOWN_PLACEHOLDER,
        type: 'textarea',
        rows: 6,
      },
    },
    image: {
      title: 'Image',
      type: 'string',
    },
    imagePosition: {
      title: 'Image Position',
      type: 'string',
      enum: ['left', 'right'],
      enumNames: ['Left', 'Right'],
      default: 'right',
      'ui:hidden': '{{!parentFormData.image}}',
    },
    imageSize: {
      title: 'Image Size',
      type: 'string',
      enum: ['w-1/3', 'w-2/3', 'w-1/2'],
      enumNames: ['1/3', '2/3', '1/2'],
      default: 'w-1/3',
      'ui:hidden': '{{!parentFormData.image}}',
    },
    ...colsNumberOnDesktopSchema,
    ...colsNumberOnMobileSchema,
    statCards: {
      type: 'array',
      title: 'A list of statistic cards',
      'ui:options': {
        showIndexNumber: true,
      },
      items: {
        title: 'Card info',
        type: 'object',
        required: ['title', 'subtitle'],
        properties: {
          title: {
            title: 'Title',
            type: 'string',
            default: '500K',
            'ui:width': '50%',
            maxLength: 4,
          },
          subtitle: {
            title: 'Subtitle',
            type: 'string',
            default: 'candidates in our talent pool',
            'ui:width': '50%',
          },
        },
      },
    },
  },
}
