export default {
  required: 'Обязательное поле',
  email: 'Должно быть действительным электронным адресом',
  min: 'Значение должно содержать не менее 0:{length} символов',
  max: 'Значение не может содержать более 0:{length} символов',
  confirmed: 'Значения не совпадают',
  regex: 'Невалидные символы',
  url: 'Некорректный URL',
  youtube: 'Некорректная YouTube ссылка',
  name: 'Невалидные символы',
  selected_options_limit: 'Максимальное число выбранных вариантов: :limit',
}
