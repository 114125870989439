export default {
  select_prompt: 'Выбрать или добавить',
  search_prompt: 'Название компании',
  about: 'О компании',
  show: 'Открыть компанию',
  add_company_prompt: 'Добавьте свою',
  company_location: 'Локация компании',
  company_name: 'Название компании',
  company_primary_business: 'Основная деятельность компании',
  company_site: 'Сайт компании',
  enter_company_data: 'Введите данные компании или',
  employees: 'Сотрудников',
  employees_title: 'Сотрудники',
  employees_size: 'Количество сотрудников',
  primary_business: 'Основная деятельность',
  name: 'Название',
  tags: 'Тэги',
  logo: 'Логотип',
  hero: 'Шапка в профиле',
  hero_size: 'Размер',
  location: 'Локация',
  site: 'Сайт',
  empty_error_text: 'Выберите как минимум одно значение',
  organizer: 'Организатор',
  select_company_from_list: 'выберите компанию из списка',
  sponsor_platinum: 'Платиновый спонсор',
  sponsor_gold: 'Золотой спонсор',
  sponsor_silver: 'Серебряный спонсор',
  sponsor_bronze: 'Бронзовый спонсор',
  unable_to_find_company_question: 'Не удается найти компанию?',
  games: 'Игры',
  wn_manager: 'Ответственный менеджер',
  no_wn_manager: 'Ответственный менеджер не назначен',
  no_company: 'Нет компании',
  members_title: 'Сотрудники компании',
  member_role_placeholder: 'Выберите роли',
  delete_member_button: 'Удалить',
  confirm_delete_member_title: 'Удалить сотрудника компании',
  confirm_delete_member_text: 'Вы уверенные что хотите удалить сотрудника компании (:name)?',
  change_member_roles_success: 'Роли сотрудника успешно обновлены',
  change_member_roles_fail: 'Не удалось обновить роли сотрудника',
  delete_member_success: 'Сотрудник успешно удалён из компании',
  delete_member_fail: 'Не удалось удалить сотрудника',
  not_enough_permissions: 'У вас недостаточно прав для выполнения этого действия',
  member_you: '(Вы)',
  roles: {
    Chat: 'Менеджер чата',
    Admin: 'Администратор',
  }
}
