interface GoogleTagConfigInterface {
  id?: null | string
  name: string
  dataLayer: any[]
  router: null
  scriptSrc: string
  debug?: boolean
  ignoreRoutes?: string[]
  skipSamePath: boolean
}

const config: GoogleTagConfigInterface = {
  id: null,
  name: 'google-tag',
  dataLayer: [],
  router: null,
  scriptSrc: 'https://www.googletagmanager.com/gtag/js',
  debug: false,
  ignoreRoutes: [],
  skipSamePath: true
}

export default config
