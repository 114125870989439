import config from './config'

declare let window: {
  ym: any
}

export function checkConfig() {
  if (typeof document === 'undefined') { return }

  if (!config.id) {
    throw new Error('Please enter a Yandex Metrika tracking ID')
  }
}

export function checkAdBlock() {
  const el = document.getElementById('wrapfabtest')

  if (el && el.clientHeight === 0) {
    throw new Error('AdBlock Detected')
  }
}

async function loadScript() {
  return new Promise((resolve, reject) => {
    try {
      // typed official metrika integration code
      (function(
        m: any,
        e: Document,
        t: string,
        r: string,
        i: string,
        k?: HTMLScriptElement,
        a?: Element
      ) {
        m[i] =
          m[i] ||
          function() {
            // eslint-disable-next-line prefer-rest-params
            (m[i].a = m[i].a || []).push(arguments)
          }
        m[i].l = 1 * Number(new Date())
        for (let j = 0; j < document.scripts.length; j++) {
          if (document.scripts[j].src === r) {
            return
          }
        }
        k = e.createElement(t) as HTMLScriptElement
        a = e.getElementsByTagName(t)[0]
        k.async = true
        k.src = r
        a.parentNode?.insertBefore(k, a)
      })(
        window,
        document,
        'script',
        'https://mc.yandex.ru/metrika/tag.js',
        'ym'
      )
      resolve({ success: true })
    } catch (error: any) {
      reject(error)
    }
  })
}

function debug(message: string, args?: any) {
  if (config.debug) {
    console.log(`[${config.name}] ${message}`, args)
  }
}

export async function createMetrika() {
  await loadScript()

  if (import.meta.env.VITE_ENV === 'production') {
    window.ym(config.id, 'init', {
      accurateTrackBounce: true,
      clickmap: true,
      defer: false,
      ecommerce: false,
      params: [],
      userParams: {},
      trackHash: false,
      trackLinks: true,
      type: 0,
      webvisor: true,
      triggerEvent: false,
    })

    return (...args: any[]) => window.ym(config.id, ...args)
  } else {
    console.warn('Tracking is disabled, because env option is not "production"')
    debug('DEBUG is true: you\'ll see all API calls in the console')

    return (...args: any[]) => debug('ym:', args)
  }
}
