export default {
  create: '创建会议',
  create_online: '创建线上会议',
  is_online: '在线会议',
  agenda: '议程',
  date: '日期',
  time_slot: '时段',
  time: '所用时间',
  request: '请求',
  participants: '参与者',
  cancel: '取消',
  meetings: '会议',
  meeting: '会议',
  join_meeting: '视频会议',
  calendar: '日历',
  select_timeslot_prompt: '选择时间',
  today: '今日',
  invites: '邀请',
  upcoming: '即将到来',
  location: '地点',
  online: '线上',
  onsite: '线下',
  invited: '已邀请',
  accepted: '已接受',
  rejected: '已拒绝',
  canceled: 'Canceled',
  cancel_verb: '取消',
  reschedule: '重新计划',
  send_message: '发送消息',
  reject: '拒绝',
  decline: 'Decline',
  accept: '接受',
  reason: '原因',
  cancel_meeting: '取消会议',
  save: '保存',
  day: '天',
  days: '天',
  week: '周',
  time_slot_locked_title: '您锁定了这个时段。',
  time_slot_locked_message: `您锁定了这个时段。
  在您的日程安排中解锁该时段，或将此会议重新安排到另一个时段。`,
  time_slot_busy_message: `您在本时间已安排了一个会议。
  将这次会议重新安排到另一个时段。`,
  no_meetings: '没有会议',
  status_outcoming: '发送邀请',
  status_declined: '已谢绝',
  status_accepted: '已接受',
  status_incoming: '传入邀请',
  status_waiting: 'waiting',
  status_blocked: 'blocked',
  unlock: '解锁',
  lock: '锁定',
  location_hint_text: '游览上海展区，请查看',
  location_hint_link: '会场平面图',
  my_calendar: 'My calendar for',
  month: 'Month',
  calendar_hint_meetings: 'There are meetings on this day',
  next_day: 'Next day',
  prev_day: 'Previous day',
  invites_modal_title: 'Requests',
  invites_modal_subtitle: 'You have unconfirmed meetings. Please review and respond.',
  view_all_requests: 'View all requests',
  invites_page_title: 'Your waiting requests',
  invites_page_subtitle: 'You have unconfirmed meetings. Please review and respond.',
  go_to_calendar: 'Go to calendar',
  back_to_calendar: 'Back to calendar',
  back_to_invites: 'Back to invites',
  no_invites: 'No meeting requests require your attention.',
  create_meeting: 'Create meeting',
  show_more: 'Show more',
  requests_warning_title: 'You have incoming requests',
  requests_warning_subtitle: 'waiting for your decision.',
  view: 'View',
  meeting_view_status_waiting_user_response: 'Status: waiting for your response',
  meeting_view_status_waiting_other_response: 'Status: waiting for :name\'s response',
  meeting_view_status_accepted: 'Status: the meeting is accepted',
  meeting_view_status_declined_by_user: 'Status: you declined the meeting',
  meeting_view_status_declined_by_other: 'Status: the meeting was declined',
  suggets_changes: 'Suggets changes',
  organizer: 'Organizer',
  new_meeting: 'New meeting request',
  new_online_meeting: 'New online meeting request',
  choose_participants: 'Select a member by clicking on their name. There are :count members avalible at this event.',
  sent_notification: 'Send a notification 10 minutes before the meeting',
  select_notification_time: 'Select notification time',
  event_is_over: 'Sorry, this event is over.',
  cannot_create_meetings: 'You can\'t create meetings anymore.',
  no_avaliable_timeslots: 'Sorry, there are no available timeslots for this day.',
  choose_another_day: 'Please try choosing another day.',
  timeslot_busy: 'This slot is blocked or contains approved meetings',
  online_only: 'online only',
  success_screen_title: 'Success',
  success_screen_subtitle: 'A request for a meeting has been sent to the user',
  success_screen_edit_subtitle: 'The request to change the meeting was successfully sent',
  add_to_google_calendar: 'Add to Google Calendar',
  add_to_calendar: 'Add to Calendar',
  meeting_point: 'Meeting point',
  open_meeting: 'Open meeting',
  removed_location: 'The location has been removed, please contact the meeting organizer',
  decline_form_description: 'Please let us know the reason for cancelling the meeting. This information helps us improve our services. Your privacy will be respected, and other users will not see this information.',
  decline_reason_1: 'Irrelevant contact',
  decline_reason_2: 'I\'ve changed my plans',
  decline_reason_3: 'It\'s spam',
  decline_reason_custom: 'Your reason',
  send: 'Send',
  reschedule_meeting: 'Reschedule meeting',
  meeting_is_past: 'This meeting has already passed',
  participant: 'Particiant',
  participant_suggested_to_change: ':participant suggested to change',
  change_details: ':type from :old to :new',
  meeting_from_another_event: 'This meeting is from another event',
  initial_timedate_is_invalid: 'Your selected time or date was not available. The nearest available option was automatically selected.',
  change_meeting: 'Change meeting',
  view_meeting: 'View meeting',
  decline_meeting_question: 'Do you really want to cancel the meeting?',
  online_meeting: 'Online meeting',
  onsite_meeting: 'On-site meeting',
  filter_approved: 'Approved',
  filter_rejected: 'Rejected',
  filter_waiting: 'Waiting',
  does_not_participate_onsite: 'doesn\'t participate on-site',
  no_meetings_with_status: 'No meetings with this status',
  cancel_confirm_title: 'Are you sure you want to close the form?',
  cancel_confirm_text: 'In this case, the data will not be saved.',
  meetings_over: 'Sorry, it is no longer possible to create meetings.'
}
