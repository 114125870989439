import {
  DEFAULT_COLOR_GRAY,
  DEFAULT_IMAGE_HORIZONTAL,
  DEFAULT_MARKDOWN_PLACEHOLDER,
} from '../constants'
import { basePropertiesSchema } from '../base-properties-schema'
import {
  showAllSettingsSchema,
  titlePropertiesSchema,
  linkColorSchema,
  textColorSchema,
  isBlankSchema,
} from '../common'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Cards with links',
  description:
    'Block with a configurable number of cards per line. It can contain link, text or image',
  type: 'object',
  required: [
    'colsNumberOnDesktop',
    'colsNumberOnMobile',
    // 'rowsNumberInSliderOnMobile', // TODO: implement rows avoiding swiper grip option
  ],
  'ui:options': {
    onlyShowIfDependent: true,
  },
  dependencies: {
    tmp_showAllSettings: [
      'colsNumberOnDesktop',
      'colsNumberOnMobile',
      'isUseSliderOnMobile',
      'isShadowed',
      'shadowColor',
      'isBordered',
      'borderColor',
      'bgColor',
      'linkColor',
      'textColor',
    ],
    title: ['titleAlign', 'hideOnEmbed'],
  },
  properties: {
    ...basePropertiesSchema,
    ...titlePropertiesSchema,
    ...showAllSettingsSchema,
    ...textColorSchema,
    colsNumberOnDesktop: {
      title: 'Number of cols on desktop',
      type: 'number',
      default: 4,
      minimum: 1,
      maximum: 6,
    },
    colsNumberOnMobile: {
      title: 'Number of cols on mobile',
      type: 'number',
      default: 2,
      minimum: 1,
      maximum: 6,
    },
    isUseSliderOnMobile: {
      title: 'Use slider on mobile',
      type: 'boolean',
      default: true,
    },
    // rowsNumberInSliderOnMobile: {
    //   title: 'Number of rows in slider on mobile',
    //   type: 'number',
    //   default: 1,
    //   minimum: 1,
    //   maximum: 4,
    //   'ui:hidden': '{{parentFormData.isUseSliderOnMobile === false}}',
    // },
    isShadowed: {
      title: 'Add shadow',
      type: 'boolean',
      default: true,
    },
    shadowColor: {
      title: 'Shadow Color',
      type: 'string',
      default: DEFAULT_COLOR_GRAY,
      'ui:hidden': '{{parentFormData.isShadowed === false}}',
    },
    isBordered: {
      title: 'Add border',
      type: 'boolean',
      default: false,
    },
    borderColor: {
      title: 'Border Color',
      type: 'string',
      default: DEFAULT_COLOR_GRAY,
      'ui:hidden': '{{parentFormData.isBordered === false}}',
    },
    cards: {
      type: 'array',
      title: 'A list of cards',
      'ui:options': {
        showIndexNumber: true,
      },
      items: {
        title: 'Card',
        type: 'object',
        properties: {
          cardType: {
            title: 'Card type',
            type: 'string',
            enum: ['image', 'text'],
            enumNames: ['Image', 'Text'],
            default: 'image',
          },
          imageUrl: {
            title: 'Image url',
            type: 'string',
            default: DEFAULT_IMAGE_HORIZONTAL,
            'ui:hidden': '{{parentFormData.cardType !== "image"}}',
          },
          text: {
            title: 'Text',
            type: 'string',
            default: DEFAULT_MARKDOWN_PLACEHOLDER,
            'ui:hidden': '{{parentFormData.cardType !== "text"}}',
          },
          link: {
            title: 'Card link',
            type: 'string',
          },
          ...isBlankSchema,
          textColor: {
            title: 'Text Color',
            type: 'string',
            default: DEFAULT_COLOR_GRAY,
            'ui:hidden': '{{parentFormData.cardType !== "text"}}',
          },
          linkColor: {
            ...linkColorSchema.linkColor,
            'ui:hidden': '{{parentFormData.cardType !== "text"}}',
          },
          bgColor: {
            title: 'Background Color',
            type: 'string',
            default: '',
            'ui:hidden': '{{parentFormData.cardType !== "text"}}',
          },
        },
      },
    },
  },
}
