// TODO use lodash or any other library because our clone doesn't support specific types

export function clone<T>(obj: T): T
export function clone<T>(obj: T[]): T[] {
  if (Array.isArray(obj)) {
    return obj.map(item => clone(item))
  } else if (typeof obj === 'object') {
    const instance = Object.create((obj as any).constructor.prototype)

    Object.entries(obj).forEach(([key, value]) => {
      if (value !== null && typeof value === 'object') {
        instance[key] = clone(value)
      } else {
        instance[key] = value
      }
    })

    return instance
  } else {
    return obj
  }
}

export default clone
