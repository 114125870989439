import type { EmailTemplate } from '/~/models/emails'
import { EmailTypes } from '/~/models/emails'
import { Api } from '/-/plugins/api'

async function getEmailsAutocomplete(
  eventId: number,
  query: string,
  typefilter?: EmailTypes[]
) {
  const { data: emailsList } = await Api.fetch(
    {
      resource: 'gateway-admin',
      url: `/events/${eventId}/emails`,
      params: {
        'filter[query]': query || undefined,
      },
    }
  ) as { data: EmailTemplate[] }

  const emailsListFiltered = typefilter
    ? emailsList.filter((email) => typefilter.includes(email.type_id))
    : emailsList

  return emailsListFiltered.map((item) => {
    return {
      value: item.id,
      label: item.name,
    }
  })
}

async function getEmailByEvent(
  eventId: number,
  id: number
): Promise<EmailTemplate> {
  const { data } = await Api.fetch(
    {
      resource: 'gateway-admin',
      url: `/events/${eventId}/emails/${id}`,
    }
  ) as { data: EmailTemplate }

  return data
}

export function useEmails() {
  return {
    getEmailsAutocomplete,
    getEmailByEvent,
  }
}
