import { DEFAULT_MARKDOWN_TEXT } from '../constants'
import { basePropertiesSchema } from '../base-properties-schema'
import {
  titlePropertiesSchema,
  titleSizeSchema,
  textColorSchema,
  linkColorSchema,
} from '../common'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Text Columns',
  description: 'Block with a configurable number of columns',
  type: 'object',
  required: ['text'],
  'ui:options': {
    onlyShowIfDependent: true,
  },
  dependencies: {
    title: ['titleAlign', 'hideOnEmbed', 'titleSize', 'titleColor'],
  },
  properties: {
    ...basePropertiesSchema,
    ...titlePropertiesSchema,
    ...titleSizeSchema,
    titleColor: {
      ...textColorSchema.textColor,
      title: 'Title color',
    },
    colsNumberOnDesktop: {
      title: 'Number of cols on desktop',
      type: 'number',
      default: 2,
      minimum: 1,
      maximum: 4,
    },
    columns: {
      type: 'array',
      title: 'Text columns',
      'ui:options': {
        showIndexNumber: false,
      },
      items: {
        type: 'object',
        required: ['text'],
        minItems: 1,
        properties: {
          text: {
            title: 'Text',
            type: 'string',
            'ui:options': {
              placeholder: DEFAULT_MARKDOWN_TEXT,
              type: 'textarea',
              rows: 6,
            },
          },
          ...textColorSchema,
          ...linkColorSchema,
        },
      },
    },
  },
}
