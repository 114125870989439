import langRu from './ru'
import langEn from './en'
import langZh from './zh'
import { LanguageInterface } from '/-/plugins/locale'

const languages: LanguageInterface[] = [
  {
    abbr: 'ru',
    value: langRu,
    label: 'Русский'
  },
  {
    abbr: 'en',
    value: langEn,
    label: 'English'
  },
  {
    abbr: 'zh',
    value: langZh,
    label: '中文'
  }
]

export default languages
