import { reactive, readonly, computed, toRef } from 'vue'
import { Api } from '/-/plugins/api'
import { Event, SortedEventsGroup } from '/~/models/event'
import { plainToClass } from '/-/plugins/helpers'

interface EventsStateInterface {
  event?: Event
  sortedEvents: SortedEventsGroup,
}

const API_URL = '/events'

export function getEventsUrl(eventId?: number): string {
  return API_URL + (eventId ? `/${eventId}` : '')
}

export const state: EventsStateInterface = reactive({
  event: undefined,
  sortedEvents: new SortedEventsGroup(),
})

export async function fetchEvent(eventId: number | string): Promise<Event> {
  const { data } = await Api.fetch({
    url: `/events/${eventId}`
  })

  state.event = plainToClass(data, Event)

  return state.event
}

export async function getEvent(eventId: number | string): Promise<Event> {
  const { data } = await Api.fetch({
    url: `/events/${eventId}`
  })

  return plainToClass(data, Event)
}

export async function getCurrentEvent(): Promise<Event> {
  let data: any = null

  if (window.config) {
    console.log(`inject ${window.config.slug} config`)
    data = window.config
  } else {
    const eventId = import.meta.env.VITE_BASE_EVENT_ID

    data = (await Api.fetch({
      url: `/events/${eventId}`
    })).data
  }

  state.event = plainToClass(data, Event)

  return state.event
}

export async function fetchSortedEvents(): Promise<void> {
  const { data } = await Api.fetch({
    url: '/events/grouped'
  })

  state.sortedEvents = plainToClass(data, SortedEventsGroup)
}

const eventId = computed(() => {
  return state.event?.id
})

export function useEvents() {
  return {
    event: readonly(toRef(state, 'event')),
    sortedEvents: toRef(state, 'sortedEvents'),
    eventId,
    fetchEvent,
    getEvent,
    getEventsUrl,
    fetchSortedEvents,
    getCurrentEvent,
  }
}
