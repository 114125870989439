export default {
  pc: 'PC',
  steam: 'Steam',
  html5: 'HTML5',
  webgl: 'WebGL',
  web: 'Web',
  ios: 'iOS',
  android: 'Android',
  switch: 'Switch',
  business_development: 'Business Development',
  monetization: 'Monetization',
  ua: 'UA',
  ai: 'AI',
  publishing: 'Publishing',
  mobile: 'Mobile',
  blockchain: 'Blockchain',
  outsource: 'Outsource',
  ar_vr: 'AR/VR',
  other: 'Other',
  d2: '2D',
  d3: '3D',
  ads: 'Ads',
  advertising: 'Advertising',
  agency: 'Agency',
  analytics: 'Analytics',
  app: 'App',
  aso: 'ASO',
  attribution: 'Attribution',
  automation: 'Automation',
  billing: 'Billing',
  bloggers: 'Bloggers',
  business_development_2: 'Business Development',
  casual: 'Casual',
  cloud: 'Cloud',
  community_management: 'Community Management',
  console: 'Console',
  contract: 'Contract',
  core_gaming: 'Core Gaming',
  creatives: 'Creatives',
  cyber_security: 'Cyber Security',
  data: 'Data',
  data_driven_ua: 'Data Driven UA',
  design: 'Design',
  ecosystem: 'Ecosystem',
  engine: 'Engine',
  facebook: 'Facebook',
  fraud_prevention: 'Fraud Prevention',
  funding: 'Funding',
  financing: 'Financing',
  game_design: 'Game Design',
  game_development: 'Game Development',
  games: 'Games',
  gaming: 'Gaming',
  gdpr: 'GDPR',
  hardcore: 'Hardcore',
  hosting: 'Hosting',
  hypercasual: 'Hypercasual',
  incrementality: 'Incrementality',
  indie: 'Indie',
  infrastructure: 'Infrastructure',
  kubernetes: 'Kubernetes',
  launch_ops: 'Launch Ops',
  legal: 'Legal',
  licensing: 'Licensing',
  live_ops: 'Live Ops',
  localization: 'Localization',
  marketing: 'Marketing',
  measurement: 'Measurement',
  midcore: 'Midcore',
  motion_design: 'Motion Design',
  outsourcing: 'Outsourcing',
  payments: 'Payments',
  performance_marketing: 'Performance Marketing',
  platform: 'Platform',
  profit_share: 'Profit Share',
  programmatic_advertising: 'Programmatic Advertising',
  programmatic_ua: 'Programmatic UA',
  push_notifications: 'Push-Notifications',
  retargeting: 'Retargeting',
  rpg: 'RPG',
  servers: 'Servers',
  service: 'Service',
  strategy: 'Strategy',
  studio: 'Studio',
  taxes: 'Taxes',
  tracking: 'Tracking',
  trailers: 'Trailers',
  translation: 'Translation',
  unity: 'Unity',
  unreal_engine: 'Unreal Engine',
  user_acquisition: 'User Acquisition',
  vendor: 'Vendor',
  video: 'Video',
  hr: 'HR',
  software: 'Software',
  software_development: 'Software Development',
  tooling: 'Tooling',
  programming: 'Programming',
  jetbrains: 'Jetbrains',
}
