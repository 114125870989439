export default {
  analytics: 'Аналитика',
  developer: 'Разработка',
  platform: 'Платформа',
  advertising: 'Реклама',
  graphics: 'Графика',
  sound: 'Звук',
  billing: 'Billing',
  publisher: 'Издательство',
  investor: 'Инвестиция',
  press: 'Пресса',
  hosting: 'Хостинг',
  retargeting: 'Ретаргетинг',
  other: 'Другое',
}
