import { Autocomplete } from '/~/models/autocomplete'
import { Api } from '/-/plugins/api'
import { plainToClass } from '/-/plugins/helpers'

const state = {
  detectedGeo: null as Geo | null
}

async function getDetectedGeo(): Promise<Geo> {
  const { detectGeo } = useGeo()

  if (!state.detectedGeo) {
    state.detectedGeo = await detectGeo()
  }
  return state.detectedGeo
}

export interface Geo {
  city: Autocomplete
  country: Autocomplete
}

async function detectGeo(): Promise<Geo> {
  const { data } = await Api.fetch({
    url: '/geo/detect',
    method: 'POST'
  })

  return {
    city: toAutocomplete(data?.city),
    country: toAutocomplete(data?.country)
  }
}

async function getCountriesAutocomplete(query?: string): Promise<Autocomplete[]> {
  const { data } = await Api.fetch({
    url: '/geo/countries/autocomplete',
    params: {
      query
    }
  })

  return data.map(toAutocomplete)
}

async function getCountriesAll(): Promise<Autocomplete[]> {
  const { data } = await Api.fetch({
    url: '/geo/countries'
  })

  return data.map(toAutocomplete)
}

async function getCountryById(id: string): Promise<Autocomplete> {
  const { data } = await Api.fetch({
    url: `/geo/countries/${id}`
  })

  return toAutocomplete(data)
}

async function getCitiesAutocomplete(country: string, query: string): Promise<Autocomplete[]> {
  const { data } = await Api.fetch({
    url: '/geo/cities/autocomplete',
    params: { country, query }
  })

  return data.map(toAutocomplete)
}

async function getCityById(id: number): Promise<Autocomplete> {
  const { data } = await Api.fetch({
    url: `/geo/cities/${id}`
  })

  return toAutocomplete(data)
}

function toAutocomplete(data: any): Autocomplete {
  return plainToClass({
    value: data?.id,
    label: data?.name,
  }, Autocomplete)
}

export function useGeo() {
  return {
    getDetectedGeo,
    detectGeo,
    getCountriesAutocomplete,
    getCountryById,
    getCitiesAutocomplete,
    getCityById,
    getCountriesAll
  }
}
