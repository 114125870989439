<template>
  <div class="flex w-full flex-wrap gap-2">
    <NInput :value="modelValue"
            @input="onInput" />

    <WNMeetRecordsModal v-if="props.options?.source === 'jitsi'"
                        @select="onSelect" />
  </div>
</template>

<script setup lang="ts">
import WNMeetRecordsModal from '/~/views/academy-admin/wnmeet-records-modal.vue'

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  options: {
    type: Object,
    default: undefined
  }
})

function onInput(value: any) {
  emit('update:modelValue', value)
}
const emit = defineEmits(['update:modelValue'])

function onSelect(url: string) {
  emit('update:modelValue', url)
}
</script>
