import 'reflect-metadata'
import { createApp } from 'vue'
import App from './app.vue'
import auth from '/-/plugins/auth'
import { bootstrap } from '/~/core/bootstrap'
import api from '/~/core/api'
import { displayRoute } from './plugins/display-route'
import { displayContent } from './plugins/display-content'
import locale from '/-/plugins/locale'
import theme from '/-/plugins/theme'
import icons from '/-/plugins/icons'
import directives from '/-/directives'
import components from '/-/components'
import dayjs from './plugins/datejs'
import marked from './plugins/marked'
import naiveui from './plugins/naive-ui'
import academy from './plugins/academy'
import native from './plugins/native'
import veeValidateI18n from './plugins/vee-validate-i18n'
import customIcons from './icons'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { useApplication } from '/~/state/application'
import './index.scss'

const app = createApp(App)

app.use(theme, 'main')
app.use(icons, customIcons)
app.use(directives)
app.use(naiveui)
app.use(academy)
app.use(components)
app.use(api)
app.use(dayjs)
app.use(marked)
app.use(veeValidateI18n)
app.use(displayRoute)
app.use(displayContent)

app.use(locale, [])

bootstrap(app).then(({ app, router }) => {
  const { isNative, isProd } = useApplication()

  if (isNative) {
    app.use(native, { router })
  }

  if (isProd) {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN ? `${import.meta.env.VITE_SENTRY_DSN}` : undefined,
      logErrors: true,
      beforeBreadcrumb(breadcrumb, hint) {
        if (breadcrumb.category === 'fetch' && hint) {
          const { input } = hint
          const [_url, request] = input || []
          const { body } = request || {}

          if (body && breadcrumb.data) {
            breadcrumb.data.body = body
          }
        }
        return breadcrumb
      },
      // beforeSend(event: any, hint: any) {
      //   console.log('on before send', event, hint)

      //   return null
      // },
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracePropagationTargets: [import.meta.env.VITE_API_GATEWAY_URL],
        }),
      ],
      tracesSampleRate: 0.1,
    })
  }

  app.use(auth, {
    router,
    loginRoute: 'auth-login'
  })

  app.use(router)
  app.mount('#app')
}).catch(error => {
  // TODO: refactor to provide informative error view when app loading without network
  // https://app.asana.com/0/0/1201963069352222/f
  app.mount('#app')
  throw error
})
