export default {
  unmute: '非静音',
  mute: '静音',
  empty: '前往“参会者”页面，寻找相关人士开始商谈！',
  start_chat: '开始聊天',
  not_select_title: '欢迎来到 WN Hub 聊天室!',
  not_select_text: '与其他参会者交谈十分轻松！',
  not_select_text_1: '转到您要与之聊天的用户的个人资料。',
  not_select_text_2: '在嘉宾详情页点击“发送信息”，即可编写信息。',
  not_select_text_3: '全部搞定！ 你也可以回复其他人的信息。',
  not_select_text_mobile: '转到您要与之聊天的用户的个人资料以开始新对话',
  members_start_chat: 'Chat',
  reply: '重播',
  delete: '删除',
  type: '输入消息……',
  main_chat: '重要聊天',
  meeting_ready: '会议已准备就绪',
  join: '立即加入',
  share: '共享链接',
  one_member: '您是唯一的参与者',
  members: '成员',
  join_meeting: '加入会议',
  settings: '会议设置',
  all_chats: '所有聊天',
  private_chats: '私人聊天',
  company_chats: '公司聊天',
  lesson_chats: 'Lesson Chats',
  promo_chats: 'Promo Chats',
  grid: '网格',
  grid_plus: '加入我的网格视图',
  accent: '口音',
  exit: '退出',
  copy_link: '复制链接',
  joining_info: '加入信息',
  show_chat: '显示聊天',
  hide_chat: '隐藏聊天',
  audio_device: '音频设备',
  video_device: '视频设备',
  question_speaker: '向演讲人提出的问题',
  important_message: 'Important message',
  important_message_description: 'All the event members will be notified',
  unread_hidden_message: '屏蔽未读信息。',
  report: '举报留言',
  promo_reply: 'Reply',
  promo_no_reply: 'You can\'t reply on this message as a company member',
  report_message_success: 'The message successfully reported',
  report_message_error: 'Error! Failed to report the message',
  report_message_too_many: 'You can report only 1 message per user per hour',
  send_message_ban: 'You were banned until :expiration, reason: :reason',
  send_message_channel: 'Channel :channel is not found',
  send_message_error: 'Error! Failed to send a message',
  upload_size_error: 'File is too big, maximum file size is :size Mb',
  download_error: 'Failed to get a link for the file',
  upload_error: 'Failed to upload the file :name',
  upload_success: 'File :name successfully uploaded',
  homework_message: 'Homework',
  homework_message_declined: 'Homework declined!',
  homework_message_approved: 'Homework approved!',
  text_homework_receieved: 'Homework files',
  text_homework_approved: 'Homework approved',
  text_homework_declined: 'Homework declined',
  text_attachments_recevied: 'Attachments files',
  open_lesson: 'Open lesson',
  open_homework: 'Open homework page',
  student_homework_message: 'Please wait for the teacher response',
  student_homework_message_declined: 'You can send a new homework on the lesson page',
  student_homework_message_approved: 'Great! Please continue the course',
  no_channels: 'No such channels',
  delivered: 'Delivered',
  read: 'Read',
  upload_limit: 'up to :size Mb',
}
