import { clone, difference } from '/~/plugins/utils'

export class BaseModel {
  public clone() {
    return clone(this)
  }

  public diff(target: BaseModel) {
    return difference(this, target)
  }
}
