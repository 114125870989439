<template>
  <div
    class="rounded-xl overflow-hidden h-full"
    :class="isBordered && 'border'"
    :style="{
      borderColor: borderColor || '#414141',
      boxShadow: isShadowed
        ? `1px 3px 5px ${hexToRgba(shadowColor || '#414141', 0.2)}`
        : '',
    }">
    <div v-if="isNotValid"
         class="text-red-700 px-4 py-2">
      Please, don't use links in markdown text if you have the link on the card
    </div>
    <BaseLink
      v-else-if="card.link && linkUrl"
      :href="isRelativeLink(card.link) ? undefined : linkUrl"
      :to="isRelativeLink(card.link) ? linkUrl : undefined"
      :target="getLinkTarget(card.link, card.isBlank)"
      class="!block w-full h-full">
      <div
        v-if="card.cardType === 'text'"
        class="marked px-4 py-2 h-full"
        :style="{
          color: card.textColor || '#414141',
          backgroundColor: card.bgColor || '',
        }"
        v-html="markedText" />
      <img v-else
           :src="card.imageUrl"
           class="object-cover w-full h-full">
    </BaseLink>
    <template v-else>
      <div
        v-if="card.cardType === 'text'"
        class="marked px-4 py-2 h-full"
        :style="{
          color: card.textColor || '#414141',
          backgroundColor: card.bgColor || '',
        }"
        v-html="markedText" />
      <img v-else
           :src="card.imageUrl"
           class="object-cover w-full h-full">
    </template>
  </div>
</template>

<script setup lang="ts">
import { hexToRgba } from '/~/plugins/utils'
import { getMarkedTextWithBlankLinks } from '../utils'
import { computed, defineProps } from 'vue'
import { useEditorLinks } from '/~/plugins/editor-links'
import BaseLink from '/-/components/link/base-link.vue'

interface CardInterface {
  cardType: 'image' | 'text'
  imageUrl?: string
  text?: string
  textColor?: string
  bgColor?: string
  link?: string
  linkColor?: string
  isBlank?: boolean
}

interface PropsInterface {
  card: CardInterface
  isShadowed: boolean
  shadowColor: string
  isBordered: boolean
  borderColor: string
}

const { getLinkUrl, isRelativeLink, getLinkTarget } = useEditorLinks()
const props = defineProps<PropsInterface>()

const markedText = computed(() =>
  props.card.cardType === 'text' && props.card.text
    ? getMarkedTextWithBlankLinks(props.card.text, props.card.linkColor)
    : ''
)

const linkUrl = computed(() => getLinkUrl(props.card.link))

// cards with links that also contains links in markdown cause the hydration mismatch error
const isNotValid = computed(
  () => props.card.link && markedText.value.includes('<a')
)
</script>
