export default {
  analytics: 'Analytics',
  developer: 'Developer',
  platform: 'Platform',
  advertising: 'Advertising',
  graphics: 'Graphics',
  sound: 'Sound',
  billing: 'Billing',
  publisher: 'Publisher',
  investor: 'Investor',
  press: 'Press',
  hosting: 'Hosting',
  retargeting: 'Retargeting',
  other: 'Other',
}
