export default {
  added: 'added',
  add_success: '":title" successfully added to your favorites',
  remove_success: '":title" successfully removed from your favorites',
  add_fail: '":title" was failed to add to your favorites',
  remove_fail: '":title" was failed to remove from your favorites',
  welcome_to_favorites: 'Welcome to your "Favorites"',
  no_news_description: 'You haven\'t added any news to your favorites yet.',
  no_events_description: 'You haven\'t added any events to your favorites yet.',
  no_lectures_description: 'You haven\'t added any lectures to your favorites yet.',
  no_companies_description: 'You haven\'t added any companies to your favorites yet.',
  no_users_description: 'You haven’t added anything yet. You can add a user of the WN Hub platform to your "Favorites" to keep in touch with them:',
  no_users_visit_profile: 'Visit their profile and click the "Favorites" button',
  no_users_scan_qr: 'Scan the QR code on their badge when you meet them at in-person WN events',
}
