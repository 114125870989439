export default {
  diamond_sponsor: '钻石赞助商',
  bronze_sponsor: '铜牌赞助商',
  silver_sponsor: '银牌赞助商',
  gold_sponsor: '金牌赞助商',
  platinum_sponsor: '铂金赞助商',
  organizer: '组织方',
  general_sponsor: '领衔赞助商',
  sponsor: '赞助商',
  partner: 'Partner',
}
