export default {
  analytics: '分析师',
  developer: '开发者',
  platform: '平台',
  advertising: '广告',
  graphics: '平面设计',
  sound: '音效',
  billing: '支付',
  publisher: '发行',
  investor: '投资者',
  press: '媒体',
  hosting: '托管',
  retargeting: '再营销',
  other: '其他',
}
