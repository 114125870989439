export default {
  ar_vr: '扩增现实 (AR)/虚拟现实 (VR)',
  art: '艺术设计',
  sound: '音效',
  consultancy: '咨询',
  development_blockchain: '开发 (区块链)',
  development_mobile: '开发 (手机)',
  development_pc_console: '开发 (PC/游戏机)',
  distribution: '分发',
  game_testing: '游戏测试',
  investment: '投资',
  licensing: '授权',
  localisation: '本地化',
  marketing: '市场推广',
  media_coverage: '媒体报道',
  monetization: '广告变现',
  publishing_pc: '发行 (PC)',
  publishing_mobile: '发行（手机端）',
  tools_services: '工具和服务',
  user_acquisition: '用户获取',
  game_stores: '游戏商店',
  recruitment: '招聘',
  analytics: '分析师',
  developer: '开发者',
  platform: '平台',
  advertising: '广告',
  graphics: '平面设计',
  billing: '支付',
  investor: '投资者',
  press: '记者媒体',
  hosting: '托管',
  retargeting: '再营销',
  html5_games: 'HTML5 游戏',
  mobile_games: '手游',
  pc_console_games: 'PC/端游',
  other: '其他',
}
