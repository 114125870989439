import { App } from 'vue'
import { RouteLocationRaw } from 'vue-router'
import { Observer } from '../utils'
import { AnyCallback } from '/~/types'

const observer = new Observer()
const broadcastDisplayRoute = (to: RouteLocationRaw) => {
  observer.broadcast('display-route', to)
}

export const displayRoute = {
  install: (app: App<Element>) => {
    app.config.globalProperties.$displayRoute = broadcastDisplayRoute
  }
}

export function useDisplayRoute() {
  return {
    unsubscribe() {
      observer.unsubscribe()
    },
    onDisplayRouteRequest(callback: AnyCallback) {
      observer.subscribe('display-route', callback)
    },
    displayRoute: broadcastDisplayRoute
  }
}
