export default {
  select_prompt: 'Select or create',
  search_prompt: 'Company Name',
  about: '企业简介',
  show: '打开简介',
  add_company_prompt: '添加新的',
  company_location: '地点',
  company_name: '企业名称',
  company_primary_business: '主营业务',
  company_site: '企业网站',
  enter_company_data: '输入企业信息或者',
  employees: '员工',
  employees_title: '员工',
  employees_size: '员工',
  primary_business: '主要业务',
  name: '企业名称',
  tags: '标签',
  logo: '标志',
  hero: 'Profile Hero',
  hero_size: 'Size',
  location: '地点',
  site: '网站',
  empty_error_text: '至少选择一个选项',
  organizer: '组织者',
  select_company_from_list: '从列表中选择公司',
  sponsor_platinum: '白金赞助商',
  sponsor_gold: '金牌赞助商',
  sponsor_silver: '银牌赞助商',
  sponsor_bronze: '铜牌赞助商',
  unable_to_find_company_question: '找不到公司？',
  games: '游戏',
  wn_manager: 'Responsible manager',
  no_wn_manager: 'No responsible manager',
  no_company: '没有这个企业',
  members_title: 'Company members',
  member_role_placeholder: 'Select roles',
  delete_member_button: 'Delete',
  confirm_delete_member_title: 'Delete company member',
  confirm_delete_member_text: 'Are you sure you want to delete this member (:name)?',
  change_member_roles_success: 'Roles have been successfully changed',
  change_member_roles_fail: 'Failed to change roles',
  delete_member_success: 'Member has been successfully deleted from the company',
  delete_member_fail: 'Failed to delete member',
  not_enough_permissions: 'You do not have enough permissions to perform this action',
  member_you: '(You)',
  roles: {
    Chat: 'Chat Manager',
    Admin: 'Admin',
  }
}
