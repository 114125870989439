import { reactive, toRef, computed, ref } from 'vue'
import type { ConfigInterface, PromoMessageInterface, RouteInterface, AnalyticsInterface } from '/-/models/config'
export { ConfigInterface, PromoMessageInterface, RouteInterface, AnalyticsInterface }

interface StateInterface {
  config: ConfigInterface
}

export const state: StateInterface = reactive({
  config: {}
})

const routes = computed(() => {
  return state.config?.menu?.routes
})

function setConfig(config: ConfigInterface) {
  state.config = config
}

export function useConfig() {
  return {
    config: toRef(state, 'config'),
    routes: ref(routes),
    setConfig
  }
}
