export default {
  pc: 'PC',
  steam: 'Steam',
  html5: 'HTML5',
  webgl: 'WebGL',
  web: 'Web',
  ios: 'iOS',
  android: 'Android',
  switch: 'Switch',
  business_development: 'Бизнес разработка',
  monetization: 'Монетизация',
  ua: 'UA',
  ai: 'AI',
  publishing: 'Издательство',
  mobile: 'Mobile',
  blockchain: 'Блокчейн',
  outsource: 'Аутсорсинг',
  ar_vr: 'AR/VR',
  other: 'Другое',
  d2: '2D',
  d3: '3D',
  ads: 'Объявления',
  advertising: 'Реклама',
  agency: 'Агентство',
  analytics: 'Аналитика',
  app: 'Приложение',
  aso: 'ASO',
  attribution: 'Атрибуция',
  automation: 'Автоматизация',
  billing: 'Выручка',
  bloggers: 'Bloggers',
  business_development_2: 'Бизнес разработка',
  casual: 'Казуал',
  cloud: 'Облако',
  community_management: 'Управление сообществом',
  console: 'Консоль',
  contract: 'Договор',
  core_gaming: 'Core Gaming',
  creatives: 'Креативы',
  cyber_security: 'Кибербезопасность',
  data: 'Данные',
  data_driven_ua: 'Основа данных',
  design: 'Дизайн',
  ecosystem: 'Экосистема',
  engine: 'Движок',
  facebook: 'Facebook',
  fraud_prevention: 'Предотвращение мошенничества',
  funding: 'Funding',
  financing: 'Финансирование',
  game_design: 'Игровой дизайн',
  game_development: 'Разработка игр',
  games: 'Игры',
  gaming: 'Гейминг',
  gdpr: 'GDPR',
  hardcore: 'Хардкор',
  hosting: 'Хостинг',
  hypercasual: 'Гиперказуал',
  incrementality: 'Инкрементальность',
  indie: 'Инди',
  infrastructure: 'Инфраструктура',
  kubernetes: 'Kubernetes',
  launch_ops: 'Launch Ops',
  legal: 'Legal',
  licensing: 'Лицензирование',
  live_ops: 'Live Ops',
  localization: 'Локализация',
  marketing: 'Маркетинг',
  measurement: 'Измерение',
  midcore: 'Мидкор',
  motion_design: 'Моушн-дизайн',
  outsourcing: 'Аутсорсинг',
  payments: 'Платежи',
  performance_marketing: 'Performance Marketing',
  platform: 'Платформа',
  profit_share: 'Доля прибыли',
  programmatic_advertising: 'Программная реклама',
  programmatic_ua: 'Programmatic UA',
  push_notifications: 'Push-уведомления',
  retargeting: 'Ретаргетинг',
  rpg: 'RPG',
  servers: 'Серверы',
  service: 'Услуги',
  strategy: 'Стратегия',
  studio: 'Студия',
  taxes: 'Налоги',
  tracking: 'Отслеживание',
  trailers: 'Трейлеры',
  translation: 'Перевод',
  unity: 'Unity',
  unreal_engine: 'Unreal Engine',
  user_acquisition: 'Привлечение пользователей',
  vendor: 'Продавец',
  video: 'Видео',
  hr: 'HR',
  software: 'Програмное обеспечение',
  software_development: 'Разработка ПО',
  tooling: 'Инструменты',
  programming: 'Программирование',
  jetbrains: 'Jetbrains',
}
