import { Autocomplete } from '/~/models/autocomplete'
import { Api } from '/-/plugins/api'
import { plainToClass } from '/-/plugins/helpers'
import { useError } from '/~/plugins/error'

export class Timezone {
  id!: string
  name!: string
  offset!: number
  offsetLabel!: string
}

async function getTimezonesAutocomplete(query: string): Promise<Autocomplete[]> {
  const { data } = await Api.fetch({
    url: '/timezones',
    params: { query }
  })

  return data.map((item: Timezone) => toAutocompleteOption(item))
}

async function getTimezoneById(id: string): Promise<Autocomplete> {
  const { data } = await Api.fetch({
    url: `/timezones/${id}`
  })

  return toAutocompleteOption(data)
}

async function getTimezoneByIdRaw(id: string): Promise<Timezone> {
  const { data } = await Api.fetch({
    url: `/timezones/${id}`,
    headers: {
      'Accept-Language': 'en'
    }
  })

  return data
}

function toAutocompleteOption(timezone: any): Autocomplete {
  return plainToClass({
    label: `${timezone.id} - ${timezone.name} (${timezone.offsetLabel})`,
    value: timezone.id,
  }, Autocomplete)
}

function getBrowserTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

async function isTimezoneExists(timezone: string) {
  const { processError } = useError()

  try {
    await getTimezoneById(timezone)
    return true
  } catch (error: any) {
    if (error.status === 404) {
      processError(`Error! Unknown timezone: "${timezone}"`, false)
    }
    return false
  }
}

export function useTimezones() {
  return {
    getTimezonesAutocomplete,
    getTimezoneById,
    getTimezoneByIdRaw,
    isTimezoneExists,
    getBrowserTimezone
  }
}
