export default {
  not_found_title: 'Ooops... 404',
  not_found_text: 'The page you requested could not be found',
  main_page: 'Main page',
  error_title: 'Server Error',
  forbidden_title: 'Forbidden',
  forbidden_text: 'Register to start using the platform.',
  forbidden_text_1: 'You don’t have access to this event',
  forbidden_text_2: 'To access the platform you need a ticket.',
  forbidden_text_3: 'Log in with a different account or get a ticket.',
  log_in: 'Log in',
  room_full_title: 'Room is full',
  room_full_text: 'Try to join again later',
  change_account: 'Sign in to another account',
  link_error_title: 'Link Expired',
  link_error_text: 'That magic link has expired',
  upgrade_required_title: 'Upgrade required',
  upgrade_required_text_1: 'At this stage there are no more free passes available for service providers.',
  upgrade_required_text_2: 'If you would like to join the :name, please purchase a Business Pass',
  upgrade_required_text_2_1: 'If you’d like to unlock this feature, please upgrade your ticket here.',
  upgrade_required_text_2_2: 'If you would like to join Stream please upgrade your ticket here.',
  upgrade_required_text_2_3: 'Contact us if you have questions',
  upgrade_required_text_3: 'Do not forget to refresh the page after the purchase is made.',
  upgrade_required_button: 'Upgrade',
  no_network_title: 'Network Error',
  no_network_text: 'Please, check your network connection and try again',
  no_network_button: 'Try to reload',
  vote_failed: 'Vote failed',
  no_company_title: 'Company required',
  no_company_body: 'If you would like to add a game, please set company in your profile.',
  no_company_tip: 'Do not forget to refresh the page after setting the company.',
  forbidden_route_title_default: 'This section is not available',
  forbidden_route_title_ticket: 'This section is not available for your ticket type',
  forbidden_route_ticket_upgrade_text: 'You can upgrade your ticket',
  forbidden_route_ticket_upgrade_button: 'Upgrade ticket',
  unknown_error_title: 'Unknown error',
  unknown_error_text: 'Developers will be notified about this event.',
}
