<template>
  <div ref="wrapper">
    <div
      class="gap-6"
      :class="[gridColsClass, isUseSliderOnMobile ? 'hidden md:grid' : 'grid']">
      <template
        v-for="(url, index) in images"
        :key="'images-grid' + url + index">
        <div class="w-full">
          <img
            :src="url"
            class="w-full rounded-xl"
            :class="isOpenFullscreen && 'cursor-pointer'"
            @click="onSlideClick(index)">
        </div>
      </template>
    </div>
    <div :class="swiperClassList">
      <Swiper
        :slides-per-view="isFullscreen ? 1 : 'auto'"
        :space-between="isFullscreen ? 0 : SLIDES_GAP"
        :pagination="{
          clickable: true,
        }"
        class="h-full swiper-custom"
        @swiper="mainSwiper = $event">
        <template
          v-for="(url, index) in images"
          :key="'images-slider' + url + index">
          <SwiperSlide
            :style="
              !isFullscreen && {
                marginRight: SLIDES_GAP + 'px',
                width: getSlideWidth(colsNumberOnMobile, SLIDES_GAP),
              }
            ">
            <div
              class="flex items-center justify-center w-full h-full max-h-screen"
              :class="isFullscreen && 'px-6 lg:px-14 pb-16'"
              :style="[
                isFullscreen ? 'paddingTop: calc(3.5rem + env(safe-area-inset-top))' : '',
              ]">
              <img
                :src="url"
                :class="
                  isFullscreen
                    ? 'h-full w-full object-contain'
                    : 'rounded-xl cursor-pointer'
                "
                @click="onSlideClick(index)">
            </div>
          </SwiperSlide>
        </template>
      </Swiper>
      <template v-if="isFullscreen">
        <div
          class="absolute right-3 z-30 transition opacity-50"
          style="top: calc(0.75rem + env(safe-area-inset-top))">
          <BaseButton
            theme="dark"
            size="sm"
            class="w-8 !p-0"
            @click="exitFromfullscreen">
            <BaseIcon name="outline_x" />
          </BaseButton>
        </div>
        <div
          class="absolute left-3 z-30 transition opacity-0 group-hover:opacity-100"
          style="top: calc(50% - 1.5rem + env(safe-area-inset-top))"
          :class="mainSwiper?.isBeginning && 'hidden'">
          <BaseButton
            size="sm"
            theme="white"
            class="w-8 !p-0 shadow-lg"
            rounded="full"
            @click="mainSwiper?.slidePrev()">
            <BaseIcon name="outline_arrow_left"
                      size="sm" />
          </BaseButton>
        </div>

        <div
          class="absolute right-3 z-30 transition opacity-0 group-hover:opacity-100"
          style="top: calc(50% - 1.5rem + env(safe-area-inset-top))"
          :class="mainSwiper?.isEnd && 'hidden'">
          <BaseButton
            size="sm"
            theme="white"
            class="w-8 !p-0 shadow-lg"
            rounded="full"
            @click="mainSwiper?.slideNext()">
            <BaseIcon name="outline_arrow_right"
                      size="sm" />
          </BaseButton>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Swiper as SwiperClass } from 'swiper'
import { getGridColsClass, getSlideWidth } from '../utils'
import 'swiper/css'
import 'swiper/css/grid'
import 'swiper/css/pagination'

interface PropsInterface {
  colsNumberOnDesktop?: number
  colsNumberOnMobile?: number
  isUseSliderOnMobile?: boolean
  rowsNumberInSliderOnMobile?: number
  images?: string[]
  isOpenFullscreen?: boolean
}

const SLIDES_GAP = 24
const props = withDefaults(defineProps<PropsInterface>(), {
  colsNumberOnDesktop: 2,
  colsNumberOnMobile: 2,
  isUseSliderOnMobile: false,
  rowsNumberInSliderOnMobile: 1,
  images: () => [],
  isOpenFullscreen: false,
})

const isFullscreen = ref(false)
const mainSwiper = ref<SwiperClass | undefined>(undefined)
const wrapper = ref<HTMLDivElement | undefined>(undefined)

const onSlideClick = (index: number) => {
  if (!props.isOpenFullscreen || isFullscreen.value) {
    return
  }

  if (wrapper.value) {
    const height = wrapper.value.offsetHeight

    wrapper.value.style.height = `${height}px`
  }

  if (mainSwiper.value) {
    mainSwiper.value.activeIndex = index
  }

  isFullscreen.value = true

  document.body.style.overflow = 'hidden'
}

const exitFromfullscreen = () => {
  document.body.style.overflow = ''
  if (wrapper.value) {
    wrapper.value.style.height = ''
  }
  isFullscreen.value = false
}

const onKeydown = (event: KeyboardEvent) => {
  if (!isFullscreen.value) {
    return
  }

  switch (event.key) {
  case 'Escape':
    exitFromfullscreen()
    break
  case 'ArrowRight':
    mainSwiper.value?.slideNext()
    break
  case 'ArrowLeft':
    mainSwiper.value?.slidePrev()
    break
  }
}

const gridColsClass = computed(() => {
  return getGridColsClass(props.colsNumberOnDesktop, props.colsNumberOnMobile)
})

const swiperClassList = computed(() => {
  if (isFullscreen.value) {
    return 'fullscreen group relative'
  } else {
    return props.isUseSliderOnMobile ? 'block md:hidden' : 'hidden'
  }
})

onMounted(() => {
  document.addEventListener('keydown', onKeydown)
})

onUnmounted(() => {
  document.removeEventListener('keydown', onKeydown)
  document.body.style.overflow = ''
})
</script>

<style scoped>
.fullscreen {
  position: fixed;
  z-index: 1000;
  padding-bottom: 0.5rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #161418;
  overflow: hidden;
}
</style>
