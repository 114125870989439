import { Api } from '/-/plugins/api'
import { reactive, toRef, computed, watch } from 'vue'
import ProcessorElastic from '/-/plugins/processor/processor-elastic'
import { plainToClass } from '/-/plugins/helpers'
import { ChatChannel, ChatMessage, MessageCustomType, ChatUnreadData } from '/~/models/chat'
import { io, Socket } from 'socket.io-client'
import { useAuth } from '/-/plugins/auth'
import { useRoute } from './route'
import router from '/~/router'
import { useLocale } from '/-/plugins/locale'
import { useNotifications } from './notifications'
import { Company } from '/~/models/company'
import { Member } from '/~/models/member'
import { useCompanies } from './companies'
import { useMembers } from '/~/state/members'
import { useEvents } from '/~/state/events'
import { useError } from '/~/plugins/error'
import { useAcademyLessons } from './academy-lessons'
import { AcademyChatLesson } from '/~/models/academy'

const { eventId } = useEvents()
const { auth } = useAuth()
const { processError } = useError()

interface ChatState {
  channels: ProcessorElastic<ChatChannel> | null
  activeChannel: ChatChannel | null
  messages: ProcessorElastic<ChatMessage> | null
  connected: boolean
  socket: Socket | null,
  unreadMessages: ChatUnreadData | null,
  companyPromises: { [key: number]: Promise<Company>| undefined },
  companies: { [key: number]: Company | null },
  lessonPromises: { [key: number]: Promise<AcademyChatLesson>| undefined },
  lessons: { [key: number]: AcademyChatLesson | null },
  channelMember: { [key: string]: Member | undefined },
  isInResume: Promise<boolean>,
}

const state: ChatState = reactive({
  channels: null,
  activeChannel: null,
  messages: null,
  connected: true,
  socket: null,
  unreadMessages: null,
  companyPromises: {},
  companies: {},
  lessonPromises: {},
  lessons: {},
  channelMember: {},
  isInResume: Promise.resolve(true),
})

async function initChat() {
  initSocket()
  await connect()
  updateTotalUnreadCount()
}

async function resetChat() {
  await disconnect()
  state.socket = null
}

function initSocket() {
  if (state.socket) {
    return
  }
  if (!auth.value?.accessToken) {
    throw new Error('Chat: no access token on init connection')
  }

  state.socket = io(import.meta.env.VITE_WS_CHAT_URL as string, {
    autoConnect: false,
    transports: ['websocket'],
    auth: {
      token: auth.value?.accessToken
    }
  })

  state.socket.on('recieve', async (data) => {
    const message = plainToClass(data, ChatMessage)

    if (message.isPrivate) {
      onNewPrivateMessage(message)
    } else if (message.isPublic) {
      onNewPublicMessage(message)
    }
  })

  state.socket.on('delete', async (data) => {
    if (state.messages) {
      // create shallow copy for correct watching
      const copy = state.messages.hits.slice()

      copy.splice(state.messages.hits.findIndex(msg => msg.uuid === data.uuid), 1)
      state.messages.hits = copy
    }
  })

  state.socket.on('delete_channel', async (data) => {
    console.log('chat: delete_channel message', data)
    if (data.uuid) {
      const channelIdx = state.channels?.hits.findIndex(channel => channel.uuid === data.uuid)

      if (channelIdx !== undefined && channelIdx >= 0) {
        console.log('chat: remove channel from the list', channelIdx)
        state.channels?.hits.splice(channelIdx, 1)
      }

      if (state.activeChannel?.uuid === data.uuid) {
        console.log('chat: clear active channel, navigate to chats-view')
        router.pushWithinEvent({ name: 'chats-view' })
        state.activeChannel = null
      }
    }
  })

  state.socket.on('read_by_user', async (data) => {
    console.log('chat: read by user message', data)
    if (state.messages) {
      const message = state.messages.hits.find(message => message.uuid === data.message_id)

      if (message) {
        (message.readBy ??= []).push(data.read_user)
      }
    }
  })
}

function onNewPublicMessage(message: ChatMessage) {
  console.log('chat: new public message', message)
  if (message.channelId === state.activeChannel?.uuid) {
    state.messages?.hits.push(message)
  }
}

async function onNewPrivateMessage(message: ChatMessage) {
  const { pushNotification } = useNotifications()
  const { getLocal } = useLocale()

  const channelIdx = state.channels?.hits.findIndex(channel => channel.uuid === message.channelId)
  let loadedChannel = (channelIdx !== undefined && channelIdx >= 0) && state.channels?.hits[channelIdx]
  const isChannelSelected = state.activeChannel?.uuid === message.channelId

  console.log('chat: new private message', state.channels, state.channels?.hits.length, message, state.activeChannel, loadedChannel, isChannelSelected)

  if (isChannelSelected) {
    state.messages?.hits.push(message)
  }

  if (loadedChannel && channelIdx !== undefined && channelIdx >= 0) {
    loadedChannel.lastMessage = message
    if (!message.isMeAuthor) {
      loadedChannel.unreadMessages += 1
    }
    state.channels?.hits.splice(0, 0, state.channels?.hits.splice(channelIdx, 1)[0])
  }

  updateTotalUnreadCount()

  if (!loadedChannel) {
    loadedChannel = await getChannel(message.channelId)

    if (loadedChannel.relatedType === 'event' && String(loadedChannel.relatedId) !== String(eventId.value)) {
      console.log('chat: skip message as not matched by related type/id', loadedChannel, message)
      return
    }
    // we rely on chats view filter if channel has a type different from selected one
    // so channel doesn't show up for user if it shouldn't
    state.channels && state.channels.hits.unshift(loadedChannel)
  }

  // if (loadedChannel && loadedChannel.isLesson) {
  //   console.log('chat: skipt notification for new message on lesson channel', loadedChannel, loadedChannel?.isLesson)
  //   // skip notifications for lesson channels
  //   return
  // }

  const route = useRoute()

  if (!message.isMeAuthor && message.isPrivate && (!route.name?.toString().includes('chats-view') || !isChannelSelected)) {
    let messageFrom = message.sender?.name

    if (message.isCompany) {
      if (!state.companies[message.channelEntityId as number]) {
        await cacheCompany(message.channelEntityId as number)
      }

      messageFrom = state.companies[message.channelEntityId as number]?.name as string
    }
    pushNotification({
      title: `${getLocal('notifications.new_message')} ${messageFrom}`,
      icon: 'outline_chat_alt',
      theme: 'info',
      description: getMessageText(message),
      changeTitle: true,
      playSound: true,
      clickHandler: () => {
        router.pushWithinEvent({ name: 'chats-view', params: { id: message.channelId }})
      }
    })
  }
}

let connectChatPromise: null | Promise<boolean> = null

async function connect() {
  if (connectChatPromise) {
    console.log('chat: already connecting, return promise')
    return connectChatPromise
  }
  if (state.socket?.disconnected) {
    console.log('chat: connecting start')
    state.socket.connect()

    connectChatPromise = new Promise((resolve, reject) => {
      state.socket?.once('connect', () => {
        resolve(true)
      })
      state.socket?.once('connect_error', (error: any) => reject(error))
    })

    await connectChatPromise
    connectChatPromise = null
    console.log('chat: service connected')
  } else {
    console.log('chat: already connected')
  }
}

async function disconnect() {
  console.log('chat: try to disconnect')
  if (state.socket?.connected) {
    let gotEvent = false
    const reason = await new Promise((resolve, reject) => {
      state.socket?.once('disconnect', reason => {
        console.log('chat: got disconnect event')
        gotEvent = true
        resolve(reason)
      })
      console.log('chat: start disconnect')
      state.socket?.connected && state.socket.disconnect()
      setTimeout(() => {
        if (!gotEvent) {
          reject(new Error('chat: did not get disconnect event in time on disconnect'))
        }
      }, 1000)
    })

    console.log('chat: service disconnected, reason:', reason)
  }
}

function initChannelsProcessor(type?: string, relatedId = eventId.value, relatedType = 'event') {
  state.channels = new ProcessorElastic<ChatChannel>({
    fetch: async (page) => {
      const data = await Api.fetch({
        url: '/chat/channels',
        params: {
          page,
          per_page: 20,
          ...(relatedId && { 'filter[related_id]': relatedId }),
          ...(relatedType && { 'filter[related_type]': relatedType }),
          ...(type && { 'filter[entity_type]': type })
        }
      })

      return data
    },
    mapping: (data) => plainToClass(data, ChatChannel),
  })
}

async function startChat(id: number) {
  const channel = await Api.fetch({
    url: `/chat/channels/user/${id}`,
  })

  return channel.uuid
}

async function startChatCompany(companyId: number) {
  const { data } = await Api.fetch({
    url: `/${eventId.value}/chat/company/${companyId}`,
    method: 'POST',
  }) as { data: { chat_id: string }}

  return data.chat_id
}

async function startChatLesson(lessonId: number) {
  const { data } = await Api.fetch({
    url: `/${eventId.value}/chat/lesson/${lessonId}`,
    method: 'POST',
  }) as { data: { chat_id: string }}

  return data.chat_id
}

async function getChatLesson(lessonId: number, userId: number) {
  const { data } = await Api.fetch({
    url: `/${eventId.value}/chat/lesson/${lessonId}/${userId}`,
  }) as { data: { chat_id: string }}

  return data.chat_id
}

async function getChannel(id: string) {
  const channel = await Api.fetch({
    url: `/chat/channels/${id}`,
  })

  return plainToClass(channel, ChatChannel)
}

async function connectToChannel(id: string) {
  console.log('chat: connect to channel', id)
  state.activeChannel = await getChannel(id)

  state.messages = new ProcessorElastic<ChatMessage>({
    fetch: async (page) => {
      const data = await Api.fetch({
        url: `/chat/channels/${id}/messages`,
        params: {
          ...(page && { page: page }),
          per_page: 10,
        }
      })

      return data
    },
    mapping: (data) => plainToClass(data, ChatMessage),
  })

  await state.messages.getLastPage()

  if (!state.activeChannel) {
    processError(new Error('chat: no active channel on connect to channel, probably user left the chat page'), false)
  }
  if (state.activeChannel && !state.activeChannel.isPrivate) {
    if (!state.socket) {
      processError(new Error('chat: no socket on enter event'), false)
      return
    }
    console.log('chat: connect before emit enter event')
    await connect()

    const channelToEnter = state.activeChannel?.uuid
    const intervalTimer = setInterval(() => {
      processError(new Error('chat: did not get enter_complete in time, retry'), false)
      if (state.socket && channelToEnter === state.activeChannel?.uuid) {
        console.log('chat: retry to enter', channelToEnter)
        state.socket?.emit('enter', {
          uuid: channelToEnter,
        })
      } else {
        console.log('chat: no socket or active channel changed, stop trying to enter', channelToEnter, state.activeChannel?.uuid)
        clearInterval(intervalTimer)
      }
    }, 500)

    state.socket.once('enter_complete', data => {
      console.log('chat: got enter complete event', data, data.uuid === channelToEnter)
      if (data.uuid === channelToEnter) {
        clearInterval(intervalTimer)
      }
    })
    console.log('chat: emit enter')
    state.socket.emit('enter', {
      uuid: channelToEnter,
    })
  }
}

function leaveChannel() {
  if (!state.activeChannel) {
    console.log('chat: no channel to leave, return')
    return
  }
  if (state.activeChannel?.isPrivate) {
    state.messages = null
    state.activeChannel = null

    return
  }

  if (!state.socket) {
    processError(new Error('chat: no socket on leave event'), false)
    return
  }

  const channelToLeave = state.activeChannel?.uuid
  const intervalTimer = setInterval(() => {
    processError(new Error('chat: did not get leave_complete in time, retry'), false)
    if (state.socket) {
      console.log('chat: retry to leave', channelToLeave)
      state.socket?.emit('leave', {
        uuid: channelToLeave,
      })
    } else {
      console.log('chat: no socket, stop trying to leave', channelToLeave)
      clearInterval(intervalTimer)
    }
  }, 500)

  state.socket.once('leave_complete', data => {
    console.log('chat: got leave complete event', data, data.uuid === channelToLeave)
    if (data.uuid === channelToLeave) {
      clearInterval(intervalTimer)
    }
  })

  state.socket.emit('leave', {
    uuid: channelToLeave,
  })
  state.messages = null
  state.activeChannel = null
}

function resetPrivateChannel() {
  state.messages = null
  state.activeChannel = null
}

interface SendMessagePayload {
  channel: string,
  text: string,
  customType?: MessageCustomType,
  replyMessageId?: string,
  files?: string[]
}

function prepareMessage(payload: SendMessagePayload) {
  const finalText = (payload.text || '').trim()

  return {
    text: finalText,
    ...(payload.customType && { custom_type: payload.customType }),
    ...(payload.replyMessageId && { reply_message_id: payload.replyMessageId }),
    custom_data: JSON.stringify({ event_id: eventId.value }),
    ...(payload.files && payload.files.length && { files: payload.files }),
  }
}

async function sendMessage(payload: SendMessagePayload) {
  // const { getLocal } = useLocale()
  // if (!finalText) {
  //   if (payload.customType === MessageCustomType.Homework) {
  //     finalText = getLocal('chat.text_homework_receieved')
  //   } else if (payload.customType === MessageCustomType.HomeworkDeclined) {
  //     finalText = getLocal('chat.text_homework_declined')
  //   } else if (payload.customType === MessageCustomType.HomeworkApproved) {
  //     finalText = getLocal('chat.text_homework_approved')
  //   } else if (payload.files && payload.files.length) {
  //     finalText = getLocal('chat.text_attachments_recevied')
  //   }
  // }

  const message = await Api.fetch({
    method: 'POST',
    url: `/chat/channels/${payload.channel}/messages`,
    body: prepareMessage(payload)
  })

  return message
}

async function sendImportantMessage(payload: { message: SendMessagePayload, path: string }) {
  const message = await Api.fetch({
    method: 'POST',
    url: `/${eventId.value}/chat/common/message`,
    body: {
      path: payload.path,
      channel_id: payload.message.channel,
      message: prepareMessage(payload.message)
    }
  })

  return message
}

async function readChannel(id: string): Promise<ChatUnreadData> {
  const data = await Api.fetch({
    url: `/chat/channels/${id}/markasread`,
    method: 'POST',
  })

  return plainToClass(data, ChatUnreadData)
}

const activeChannelInList = computed(() => {
  return state.channels?.hits.find(channel => channel.uuid === state.activeChannel?.uuid)
})

async function readActiveChannel() {
  const activeChannel = activeChannelInList.value

  if (!state.activeChannel || !activeChannel || activeChannel.unreadMessages === 0) { return }
  activeChannel.unreadMessages = 0
  await readChannel(state.activeChannel.uuid)
  await updateTotalUnreadCount()
}

async function updateTotalUnreadCount(): Promise<void> {
  const data = await Api.fetch({
    url: '/chat/channels/unreadmessages',
    params: {
      'filter[related_id]': eventId.value,
      'filter[related_type]': 'event',
    }
  }) as ChatUnreadData

  state.unreadMessages = plainToClass(data, ChatUnreadData)
}

async function deleteOwnMessage(message: ChatMessage) {
  await Api.fetch({
    method: 'DELETE',
    url: `/chat/messages/${message.uuid}`,
  })
}

async function deleteMessage(message: ChatMessage) {
  await Api.fetch({
    method: 'DELETE',
    url: `/${eventId.value}/chat/messages/${message.uuid}`,
  })
}

async function reportMessage(message: ChatMessage) {
  await Api.fetch({
    method: 'POST',
    url: `/chat/messages/${message.uuid}/report`,
  })
}
async function uploadFile(file: File) {
  const response = await Api.fetch({
    url: '/chat/files/upload',
    method: 'POST',
    formData: {
      file
    }
  }) as { key: string }

  return response.key
}

async function getFileLink(key: string) {
  const response = await Api.fetch({
    url: `/chat/files/get_signed_url/${key}`,
  }) as { signed_url: string }

  return response.signed_url
}

async function cacheCompany(companyId: number) {
  const { getCompany } = useCompanies()

  async function awaitCompany() {
    const company = await state.companyPromises[companyId]

    state.companies[companyId] = company as Company

    return company
  }

  if (state.companyPromises[companyId]) {
    return await awaitCompany()
  }
  state.companyPromises[companyId] = getCompany(companyId)

  return await awaitCompany()
}

async function cacheLesson(lessonId: number) {
  const { getLesson } = useAcademyLessons()

  async function awaitLesson() {
    const lesson = await state.lessonPromises[lessonId]

    state.lessons[lessonId] = lesson as AcademyChatLesson

    return lesson
  }

  if (state.lessonPromises[lessonId]) {
    return await awaitLesson()
  }
  state.lessonPromises[lessonId] = getLesson(lessonId)

  return await awaitLesson()
}

function getCompanyData(id: number) {
  if (state.companies[id] === undefined) {
    state.companies[id] = null
    cacheCompany(id) // detach async company loading to change reactive data
  }
  return state.companies[id]
}

function getLessonData(id: number) {
  if (state.lessons[id] === undefined) {
    state.lessons[id] = null
    cacheLesson(id) // detach async company loading to change reactive data
  }
  return state.lessons[id]
}

watch(() => state.channels, () => {
  updateChannelMembers()
}, { deep: true })

watch([eventId, auth], () => {
  state.channelMember = {}
})

async function updateChannelMembers() {
  const { getMembersByIds } = useMembers()
  const map: {[key: number]: string } = {}

  state.channels?.hits.forEach(channel => {
    // if not company, have a partner id(member), not already cached
    if (!channel.isCompany && channel.partnerId && !state.channelMember[channel.uuid]) {
      map[channel.partnerId] = channel.uuid
    }
  })

  const memberIds = Object.keys(map).map(key => Number(key))

  if (memberIds.length === 0) {
    return
  }
  const profiles = await getMembersByIds(Object.keys(map).map(key => Number(key)))

  profiles.forEach(profile => {
    if (profile.user?.id) {
      state.channelMember[map[profile.user.id]] = profile
    }
  })
}

const getChannelLinkId = computed(() => {
  return (channel: ChatChannel) => {
    if (channel.isLesson) {
      return channel.entityId
    }
    if (channel.isCompany) {
      return getCompanyData(channel.entityId as number)?.id
    }

    return channel.partnerId
  }
})

const getChannelName = computed(() => {
  return (channel: ChatChannel) => {
    if (channel.isLesson) {
      return getLessonData(channel.entityId as number)?.name
    } else if (channel.isCompany) {
      return getCompanyData(channel.entityId as number)?.name
    }

    return channel.partnerName
  }
})

const getChannelAvatar = computed(() => {
  return (channel: ChatChannel) => {
    if (channel.isLesson) {
      return undefined
    }
    if (channel.isCompany) {
      return getCompanyData(channel.entityId as number)?.avatarUrl
    }

    return channel.partnerAvatar
  }
})

const getMessageText = (message: ChatMessage) => {
  const { getLocal } = useLocale()

  if (message.customType === MessageCustomType.HomeworkApproved) {
    return getLocal('chat.text_homework_approved')
  } else if (message.customType === MessageCustomType.HomeworkDeclined) {
    return getLocal('chat.text_homework_declined')
  } else if (message.attachments?.length && !message?.text) {
    return getLocal('chat.text_attachments_recevied')
  } else {
    return (message?.text || '').trim()
  }
}

const getChannelLastMessage = (channel: ChatChannel) => {
  return getMessageText(channel.lastMessage)
}

const totalUnreadCount = computed(() => state.unreadMessages?.unreadMessages || 0)

export function useChatService() {
  return {
    startChat,
    initChannelsProcessor,
    connectToChannel,
    sendMessage,
    sendImportantMessage,
    getMessageText,
    connect,
    disconnect,
    initChat,
    resetChat,
    readActiveChannel,
    updateTotalUnreadCount,
    leaveChannel,
    reportMessage,
    deleteMessage,
    deleteOwnMessage,
    startChatCompany,
    startChatLesson,
    getChatLesson,
    getChannelLastMessage,
    resetPrivateChannel,
    setIsInResume: (value: Promise<boolean>) => { state.isInResume = value },
    uploadFile,
    getFileLink,
    isInResume: toRef(state, 'isInResume'),
    socket: toRef(state, 'socket'),
    activeChannelInList,
    getChannelLinkId,
    getChannelName,
    getChannelAvatar,
    unreadMessagesData: toRef(state, 'unreadMessages'),
    unreadMessages: totalUnreadCount,
    activeChannel: toRef(state, 'activeChannel'),
    channels: toRef(state, 'channels'),
    channelsList: computed(() => [
      ...new Map(state.channels?.hits.map(channel => [channel.uuid, channel])).values()
    ]),
    connected: toRef(state, 'connected'),
    messages: toRef(state, 'messages'),
    channelMemberMap: toRef(state, 'channelMember'),
  }
}
