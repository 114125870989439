<template>
  <BaseLink
    v-if="isExternalLink && linkUrl"
    :href="isRelativeLink(button.url) ? undefined : linkUrl"
    :to="isRelativeLink(button.url) ? linkUrl : undefined"
    :target="getLinkTarget(button.url, button.isBlank)"
    class="hover:brightness-110 duration-150">
    <BaseButton
      v-if="button.type === 'button'"
      look="none"
      :style="getButtonStyle(button)">
      {{ button.text }}
    </BaseButton>
    <span v-else
          :style="{ color: button.color }">
      {{ button.text }}
    </span>
  </BaseLink>
  <template v-if="!isExternalLink && button.blockHash">
    <BaseLink
      v-if="button.isBlank"
      :href="getBlockLink(button.blockHash)"
      target="_blank"
      class="hover:brightness-110 duration-150">
      <BaseButton look="none"
                  :style="getButtonStyle(button)">
        {{ button.text }}
      </BaseButton>
    </BaseLink>
    <BaseButton
      v-else
      look="none"
      class="hover:brightness-110 duration-150"
      :style="getButtonStyle(button)"
      @click="scrollToHash(button.blockHash)">
      {{ button.text }}
    </BaseButton>
  </template>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue'
import BaseButton from '/-/components/button/base-button.vue'
import BaseLink from '/-/components/link/base-link.vue'
import { useEditorLinks } from '/~/plugins/editor-links'
import { getButtonStyle, type ButtonInterface } from '../common'

interface PropsInterface {
  button: ButtonInterface
}

const props = defineProps<PropsInterface>()

const { getBlockLink, scrollToHash, getLinkUrl, isRelativeLink, getLinkTarget } = useEditorLinks()

// for already existing buttons where the isExternalLink is undefined
const isExternalLink = computed(() => {
  return props.button.isExternalLink ?? true
})

const linkUrl = computed(() => getLinkUrl(props.button.url))
</script>
