import { Expose } from 'class-transformer'
import { BaseModel } from './baseModel'

// we don't use class because don't need any class transformations
export interface DictionaryItemInterface {
  id?: number
  value: string | number
  label: string
  badge?: string
  icon?: string
}

export class Dictionary extends BaseModel {
  name!: string
  locale!: string

  items!: DictionaryItemInterface[]

  @Expose({ name: 'updated_at' })
  updatedAt!: Date
}
