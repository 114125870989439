export default {
  main: '主流',
  showcase: '展示',
  indie: '独立制作',
  roasting: '烘烤',
  show_lecture: '展示演讲',
  speakers: '演讲嘉宾',
  expand: '扩展',
  archive_title: '以往演讲',
  category_all: '全部',
  agree_badge: '独家专场',
  agree_button: '加入',
  back_to_lectures: 'Back to lectures'
}
