export default {
  modules_list: 'Modules list',
  view_module: 'View module',
  no_modules: 'No modules yet',
  no_lessons: 'No lessons yet',
  pick_teachers: 'Select teachers',
  load_more: 'Load more',
  table_id: 'Id',
  table_title: 'Title',
  table_is_open: 'Is open',
  table_is_active: 'Is active',
  table_start_date: 'Start date',
  table_active: 'Active',
  edit_module: 'Edit module',
  create_module: 'Create module',
  delete_module: 'Delete',
  back_to_modules: 'Back to modules',
  title: 'Title',
  description: 'Description',
  is_open: 'Is open',
  active: 'Is active',
  date: 'Start date',
  date_description: 'Date in the course timezone',
  has_homework: 'Is homework visible for students',
  is_blocking_homework: 'Is blocking homework',
  homework_description: 'Homework description',
  teachers: 'Teachers',
  submit: 'Save',
  update_sorting_success: 'Order successfully updated',
  update_sorting_error: 'Error, failed to update order',
  module_create_success: 'Module successfully created',
  module_update_success: 'Module successfully updated',
  module_delete_success: 'Module successfully deleted',
  module_create_error: 'Error, failed to create module',
  module_update_error: 'Error, failed to update module',
  module_delete_error: 'Error, failed to delete module',
  module_confirm_delete_title: 'Delete module',
  module_confirm_delete_text: 'Are sure to permanently delete the module and all the lessons in the module?',
  lesson_create_success: 'Lesson successfully created',
  lesson_update_success: 'Lesson successfully updated',
  lesson_delete_success: 'Lesson successfully deleted',
  lesson_create_error: 'Error, failed to create lesson',
  lesson_update_error: 'Error, failed to update lesson',
  lesson_delete_error: 'Error, failed to delete lesson',
  lesson_confirm_delete_title: 'Delete lesson',
  lesson_confirm_delete_text: 'Are sure to permanently delete the lesson?',
  lessons_list: 'Lessons list',
  create_lesson: 'Create lesson',
  edit_lesson: 'Edit lesson',
  delete_lesson: 'Delete lesson',
  view_lesson: 'View lesson',
  back_to_module: 'Back to module',
  back_to_lesson: 'Back to the lesson',
  students: 'Students',
  require_admin: 'You have to be admin to edit the course',
  require_staff: 'You have to be admin or teacher of the course to view this page',
  homeworks: 'Homeworks',
  promo_messages_note: 'You can request to send a promo message for all members on the course via personal manager',
  lesson: 'Lesson',
  student: 'Student',
  status: 'Homework status',
  select_status: 'Select status',
  homework_approved: 'Approved',
  homework_declined: 'Declined',
  homework_created: 'Waiting for review',
  all_students: 'All students',
  all_lessons: 'All lessons',
  any_status: 'Any',
  lesson_content: 'Lesson content',
  program_upload_error: 'Error, failed to upload program',
  open_from: 'Open from ',
  homework_is_required: 'You need to complete the homework to access the next lesson',
  lesson_homework_title: 'Homework for the lesson',
  lesson_homework_form_title: 'Upload your homework files with your comments for the teacher',
  lesson_homework_placeholder: 'Your comments and questions for the homework',
  attach_homework_file: 'Attach homework file',
  lesson_homework_submit: 'Submit',
  lesson_homework_status: 'Your homework status',
  homework_is_pending: 'Your homework is about to be reviewed soon',
  homework_is_declined: 'Your homework is declined',
  homework_is_approved: 'Great! You successfully complete the lesson homework',
  homework_required_short: 'Homework',
  homework_is_pending_short: 'In review',
  homework_is_declined_short: 'Declined',
  homework_is_approved_short: 'Completed',
  homework_submit_success: 'Homework successfully submitted',
  homework_submit_error: 'Error, failed to submit a homework',
  homework_approve_success: 'Homework successfully approved',
  homework_approve_error: 'Error, failed to approve the homework',
  homework_decline_success: 'Homework successfully declined',
  homework_decline_error: 'Error, failed to decline the homework',
  open_chat: 'Open chat',
  hide_chat: 'Hide chat',
  close_chat: 'Close chat',
  check_homework: 'Check',
  approve: 'Approve',
  decline: 'Decline',
  back: 'Previous lesson',
  next: 'Next lesson',
  module_block_homework: 'Required to complete',
  settings_tab: 'Main settings',
  content_tab: 'Lesson content',
  homework_tab: 'Homework',
  attention: 'Attention',
  progress: 'Common progress',
  required_progress: 'Mandatory hometasks',
  required_progress_tooltip: 'Updated once an hour',
  start_recording: 'Start recording',
  stop_recording: 'Stop recording',
  recording: 'Recording',
  stream_records: 'Stream records',
  no_records: 'There are no records yet',
  copy_id: 'Copy id',
  copy_url: 'Copy url',
  stream_record: 'Stream record',
  preview: 'Preview',
  id: 'Id',
  video_date: 'Date',
  name: 'Name',
  records_wait: 'If you don\'t see your entry here, please wait. The record is being processed.',
  lectures_title: 'Title',
  lectures_date: 'Date',
  lectures_category: 'Category',
  add_lecture: 'Add a new lecture',
  edit_lecture: 'Edit lecture',
  delete_lecture: 'Delete lecture',
  delete_lecture_message: 'To delete the lecture, please, contact with a WN Hub manager',
  lecture_title: 'Title',
  lecture_category: 'Categories',
  lecture_category_placeholder: 'Select categories',
  lecture_date: 'Date',
  lecture_source: 'Source',
  lecture_video_id: 'Video Id',
  lecture_video_url: 'Video Url',
  lecture_language: 'Language',
  lecture_description: 'Description',
  lecture_video_preview: 'Video preview',
  lecture_speakers: 'Speakers',
  lecture_speakers_placeholder: 'Select speakers',
  lecture_submit: 'Submit',
  preview_too_big: 'Error, preview image is too big',
  preview_upload_error: 'Error, failed to upload the preview image',
  lecture_updated: 'The lecture was successfully updated',
  lecture_created: 'A lecture was successfully created',
  lecture_update_error: 'Error, failed to update the lecture',
  lecture_create_error: 'Error, failed to create a lecture',
  student_deleted: 'Student deleted',
  wnmeet_select_record: 'Select from records',
  wnmeet_select_video_item: 'Select record',
  wnmeet_records: 'WN Meet records',
  wnmeet_no_records: 'No records yet',
  wnmeet_select_video: 'WN Meet records',
  delete_record: 'Delete record',
  delete_record_text: 'Are you sure to delete the record from :date?',
  delete_record_success: 'The record was successfully deleted',
  delete_record_fail: 'Error! Failed to delete the record',
  view_lesson_public: 'Public view of the lesson',
  settings: 'Settings',
  enable_homework_notification: 'Enable homework notifications',
  rating: 'Average Rating',
}
