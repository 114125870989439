import {
  DEFAULT_COLOR_GRAY,
  DEFAULT_MARKDOWN_PLACEHOLDER,
  DEFAULT_JOB,
  DEFAULT_AVATAR,
  DEFAULT_LONG_MARKDOWN_TEXT,
  DEFAULT_NAME,
} from '../constants'
import { basePropertiesSchema } from '../base-properties-schema'
import {
  linkColorSchema,
  showAllSettingsSchema,
  textColorSchema,
  titlePropertiesSchema,
} from '../common'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Reviews',
  description: 'Cards with user\'s avatar, name, company and review text',
  type: 'object',
  required: ['colsNumberOnDesktop', 'colsNumberOnMobile'],
  'ui:options': {
    onlyShowIfDependent: true,
  },
  dependencies: {
    tmp_showAllSettings: [
      'cardBgColor',
      'userInfoColor',
      'titleColor',
      'textColor',
      'linkColor',
    ],
    title: ['titleAlign', 'hideOnEmbed'],
  },
  properties: {
    ...basePropertiesSchema,
    ...titlePropertiesSchema,
    ...showAllSettingsSchema,
    titleColor: {
      ...textColorSchema.textColor,
      title: 'Title color',
    },
    cardBgColor: {
      title: 'Cards background color',
      type: 'string',
      default: '#f0f6ff',
    },
    userInfoColor: {
      title: 'Cards user info color',
      type: 'string',
      default: DEFAULT_COLOR_GRAY,
    },
    textColor: {
      ...textColorSchema.textColor,
      title: 'Cards text color',
    },
    ...linkColorSchema,
    reviews: {
      type: 'array',
      title: 'A list of reviews',
      'ui:options': {
        showIndexNumber: true,
      },
      items: {
        title: 'Review',
        type: 'object',
        required: ['name', 'company', 'text'],
        properties: {
          name: {
            title: 'Name',
            type: 'string',
            default: DEFAULT_NAME,
          },
          company: {
            title: 'Company',
            type: 'string',
            default: DEFAULT_JOB,
          },
          image: {
            title: 'Image url',
            type: 'string',
            default: DEFAULT_AVATAR,
          },
          text: {
            title: 'Review text',
            type: 'string',
            'ui:options': {
              placeholder: DEFAULT_MARKDOWN_PLACEHOLDER,
              type: 'textarea',
              rows: 6,
            },
            default: DEFAULT_LONG_MARKDOWN_TEXT,
          },
        },
      },
    },
  },
}
