import { RouteLocationRaw } from 'vue-router'
import { computed, Ref } from 'vue'
import router from '/~/router'
import { EVENT_ROUTE_PREFIX } from '/~/router/types'

export function useEventRoute(link?: Ref<RouteLocationRaw | undefined>) {
  const eventSlug = computed(() => router.currentRoute.value?.params?.eventSlug)
  const eventRoute = computed(() => {
    if (!link?.value) { return undefined }
    if (!eventSlug.value) {
      return link.value
    }

    if (typeof link.value === 'string' && !link.value.includes(`events/${eventSlug.value}`) && link.value.startsWith('/')) {
      return `/events/${eventSlug.value}${link.value === '/' ? '' : link}`
    }

    if (typeof link.value !== 'string' && 'name' in link.value) {
      return {
        ...link.value,
        name: `${EVENT_ROUTE_PREFIX}${String(link.value.name)}`
      }
    }

    if (typeof link.value !== 'string' && 'path' in link.value && !link.value.path.includes(`events/${eventSlug.value}`) && link.value.path.startsWith('/')) {
      return {
        ...link.value,
        path: `/events/${eventSlug.value}${link.value.path === '/' ? '' : link.value.path}`,
      }
    }

    return link.value
  })

  return {
    eventSlug, eventRoute
  }
}
