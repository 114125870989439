import { Expose, Type } from 'class-transformer'
import { BaseModel } from './baseModel'

export class TicketFeature extends BaseModel {
  feature!: string
  value!: null | number
}

export enum TicketFeatureName {
  LimitedChats = 'limited_chats',
  LimitedMeetings = 'limited_meetings',
  LimitedVideoCall = 'limited_video_call',
  UnlimitedChats = 'unlimited_chats',
  UnlimitedMeetings = 'unlimited_meetings',
  UnlimitedVideoCall = 'unlimited_video_call',
  Highlight = 'highlight',
  GamePoll= 'game_poll',
  WatchLecture = 'watch_lecture',
  Jury = 'jury',
  Offline = 'offline',
}

export class Ticket extends BaseModel {
  id!: number
  name!: string

  @Expose({ name: 'event_id' })
  eventId!: number

  paid!: boolean
  upgradable!: boolean

  @Type(() => TicketFeature)
  features!: TicketFeature[]

  isTicketHasFeature(featureName: TicketFeatureName): boolean {
    return this.features.some(feature => feature.feature === featureName)
  }
}
