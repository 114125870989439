export default {
  central_western_europe: '中欧和西欧',
  eastern_europe: '东欧',
  north_america: '北美州',
  asia: '亚洲',
  south_central_america: '南美洲和中美洲',
  middle_east: '中东',
  africa: '非洲',
  australia_oceania: '澳大利亚和大洋洲',
}
