import * as Sentry from '@sentry/vue'
import { useApplication } from '/~/state/application'

const { isProd } = useApplication()

function processError(e: unknown, isThrow = true) {
  if (isProd) {
    Sentry.captureException(e)
  } else {
    console.error(e)
  }

  if (isThrow) {
    throw (e)
  }
}

export function useError() {
  return {
    processError
  }
}
