import { configure, defineRule } from 'vee-validate'
import { localize } from '@vee-validate/i18n'
import * as AllRules from '@vee-validate/rules'
import langEn from '/~/languages/en/formErrors'
import langRu from '/~/languages/ru/formErrors'
import { isYouTubeUrl } from '../utils'

export default {
  install: () => {
    Object.keys(AllRules).forEach(rule => {
      // @ts-expect-error we can iterate this
      defineRule(rule, AllRules[rule])
    })

    const youtubeValidator = (value: string) => {
      return !value || isYouTubeUrl(value)
    }

    const nameValidator = (value: string) => {
      return !value || !!value.match(/^([\p{sc=Han}a-zа-яё'-]+\s?)*[\p{sc=Han}a-zа-яё'-]$/ui)
    }

    defineRule('youtube', youtubeValidator)
    defineRule('name', nameValidator)

    configure({
      generateMessage: localize({
        en: {
          messages: langEn,
        },
        ru: {
          messages: langRu,
        },
      }),
    })
  }
}
