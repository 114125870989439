import { useNotifications } from '/~/state/notifications'
import { useRoute } from '/~/state/route'
import { useHost } from '/~/plugins/host'
import { useApplication } from '/~/state/application'

export const useEditorLinks = () => {
  const { VITE_WNHUB_PREFIX: wnhubPrefix, VITE_WNHUB_EVENT_PREFIX: wnhubEventPrefix } = import.meta.env
  const route = useRoute()
  const host = useHost()
  const { pushNotification } = useNotifications()
  const { isNative } = useApplication()

  const getBlockLink = (hash: string) => {
    return `${host}${route.path}#${hash}`
  }

  function scrollToHash(hash = '') {
    const element = document.querySelector(`#${hash}`)

    if (!element) {
      pushNotification({
        title: 'No block with such hash on the page',
        theme: 'destructive',
      })
      return
    }
    element.scrollIntoView({ behavior: 'smooth' })
  }

  function getLinkTarget(initialLink = '', isBlankInitial = false) {
    const link = initialLink?.trim()

    if (isNative) {
      return link.startsWith(wnhubEventPrefix) ||
        link.startsWith(wnhubPrefix) ||
        link.startsWith('/')
        ? '_self'
        : '_blank'
    } else {
      return isBlankInitial ? '_blank' : '_self'
    }
  }

  function getLinkUrl(initialLink?: string) {
    const link = initialLink?.trim()

    if (!isNative || !link) {
      return link
    }

    if (link.startsWith(wnhubEventPrefix)) {
      return `/eventhub/index.html?redirect=${link.split(wnhubPrefix)[1]}`
    }

    if (link.startsWith(wnhubPrefix)) {
      return `${link.split(wnhubPrefix)[1]}`
    }

    return link
  }

  function isRelativeLink(link?: string) {
    return link?.trim().startsWith('/')
  }

  return {
    getBlockLink,
    scrollToHash,
    isRelativeLink,
    getLinkUrl,
    getLinkTarget,
  }
}
