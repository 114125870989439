export default {
  ar_vr: 'AR/VR',
  art: 'Art',
  sound: 'Sound',
  consultancy: 'Consultancy',
  development_blockchain: 'Development (Blockchain)',
  development_mobile: 'Development (mobile)',
  development_pc_console: 'Development (PC/console)',
  distribution: 'Distribution',
  game_testing: 'Game Testing',
  investment: 'Investment',
  licensing: 'Licensing',
  localisation: 'Localisation',
  marketing: 'Marketing',
  media_coverage: 'Media Coverage',
  monetization: 'Monetization',
  publishing_pc: 'Publishing (PC)',
  publishing_mobile: 'Publishing (mobile)',
  tools_services: 'Tools & Services',
  user_acquisition: 'User Acquisition',
  game_stores: 'Game Stores',
  recruitment: 'Recruitment',
  analytics: 'Analytics',
  developer: 'Developer',
  platform: 'Platform',
  advertising: 'Advertising',
  graphics: 'Graphics',
  billing: 'Billing',
  investor: 'Investor',
  press: 'Press',
  hosting: 'Hosting',
  retargeting: 'Retargeting',
  html5_games: 'HTML5 Games',
  mobile_games: 'Mobile Games',
  pc_console_games: 'PC/Console Games',
  other: 'Other',
}
