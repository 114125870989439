export default {
  pc: '电脑',
  steam: 'Steam',
  html5: 'HTML5',
  webgl: 'WebGL',
  web: 'Web',
  ios: 'iOS',
  android: 'Android',
  switch: 'Switch',
  business_development: '商务',
  monetization: '广告变现',
  ua: '用户获取',
  ai: '人工智能',
  publishing: '发行',
  mobile: '手机',
  blockchain: '区块链',
  outsource: '外包',
  ar_vr: '扩增现实 (AR)/虚拟现实 (VR)',
  other: '其他',
  d2: '2D',
  d3: '3D',
  ads: '广告',
  advertising: '广告',
  agency: '代理',
  analytics: '分析',
  app: '应用（程序）',
  aso: '应用商店优化 (ASO)',
  attribution: '广告归属服务/归因',
  automation: '自动化',
  billing: '支付',
  bloggers: '博主',
  business_development_2: '商务',
  casual: '休闲游戏',
  cloud: '云',
  community_management: '社区管理',
  console: '游戏机',
  contract: '合约',
  core_gaming: '中型游戏',
  creatives: '广告素材',
  cyber_security: '网络安全',
  data: '数据',
  data_driven_ua: '数据驱动的用户获取',
  design: '设计',
  ecosystem: '生态系统',
  engine: '引擎',
  facebook: 'FaceBook',
  fraud_prevention: '防诈骗',
  funding: '投资',
  financing: '融资',
  game_design: '游戏设计',
  game_development: '游戏开发',
  games: '游戏',
  gaming: '游戏',
  gdpr: '一般数据保护条例 (GDPR)',
  hardcore: '重型游戏',
  hosting: '托管',
  hypercasual: '超休闲游戏',
  incrementality: '增量游戏',
  indie: '独立',
  infrastructure: '基础架构',
  kubernetes: 'Kubernetes',
  launch_ops: '游戏发行运营',
  legal: '合法',
  licensing: '授权',
  live_ops: '常态化运营',
  localization: '本地化',
  marketing: '市场推广',
  measurement: '测量',
  midcore: '中型游戏',
  motion_design: '动画特效设计',
  outsourcing: '外包',
  payments: '支付',
  performance_marketing: '效果营销',
  platform: '平台',
  profit_share: '利润分成',
  programmatic_advertising: '程序化广告',
  programmatic_ua: '程序化用户获取',
  push_notifications: '推送通知',
  retargeting: '再营销',
  rpg: '角色扮演游戏 (PRG)',
  servers: '服务器',
  service: '服务',
  strategy: '策略类游戏',
  studio: '工作室',
  taxes: '税收',
  tracking: '跟踪',
  trailers: '预告',
  translation: '翻译',
  unity: 'Unity',
  unreal_engine: '虚幻引擎 (Unreal Engine)',
  user_acquisition: '用户获取',
  vendor: '销售商',
  video: '视频',
  hr: 'HR',
  software: '软件',
  software_development: '软件开发',
  tooling: '工具',
  programming: '编程',
  jetbrains: 'Jetbrains',
}
