export default {
  not_select_title: 'Welcome to the WN Hub chat!',
  not_select_text: 'Chatting with other participants is easy!',
  not_select_text_1: 'Go to the profile of the user you want to chat with.',
  not_select_text_2: 'Push the ‘Send message’ button in the chosen profile and write your message.',
  not_select_text_3: 'All done! You can also reply to other people\'s messages.',
  not_select_text_mobile: 'Go to the profile of the user you want to chat with to start a new conversation',
  members_start_chat: 'Chat',
  reply: 'Reply',
  delete: 'Delete',
  type: 'Type a message...',
  main_chat: 'Main Chat',
  meeting_ready: 'Meeting is ready',
  join: 'Join Now',
  share: 'Share Link',
  one_member: 'You are the only participant',
  members: 'Members',
  join_meeting: 'Join the meeting',
  settings: 'Meeting Settings',
  all_chats: 'All Chats',
  private_chats: 'Private Chats',
  company_chats: 'Company Chats',
  lesson_chats: 'Lesson Chats',
  promo_chats: 'Promo Chats',
  grid: 'Grid',
  grid_plus: 'Grid With Me',
  accent: 'Accent',
  exit: 'Exit',
  copy_link: 'Copy Link',
  joining_info: 'Joining Info',
  show_chat: 'Show chat',
  hide_chat: 'Hide chat',
  start_chat: 'Start chat',
  audio_device: 'Audio Device',
  video_device: 'Video Device',
  question_speaker: 'Question for the speaker',
  important_message: 'Important message',
  important_message_description: 'All the event members will be notified',
  unread_hidden_message: 'Unread messages are hidden',
  empty: 'Go to the Attendees section and find relevant people to make business with!',
  mute: 'Mute',
  unmute: 'Unmute',
  report: 'Report a message',
  promo_reply: 'Reply',
  promo_no_reply: 'You can\'t reply on this message as a company member',
  report_message_success: 'The message successfully reported',
  report_message_error: 'Error! Failed to report the message',
  report_message_too_many: 'You can report only 1 message per user per hour',
  send_message_ban: 'You were banned until :expiration, reason: :reason',
  send_message_channel: 'Channel :channel is not found',
  send_message_error: 'Error! Failed to send a message',
  upload_size_error: 'File is too big, maximum file size is :size Mb',
  download_error: 'Failed to get a link for the file',
  upload_error: 'Failed to upload the file :name',
  upload_success: 'File :name successfully uploaded',
  homework_message: 'Homework',
  homework_message_declined: 'Homework declined!',
  homework_message_approved: 'Homework approved!',
  text_homework_receieved: 'Homework files',
  text_homework_approved: 'Homework approved',
  text_homework_declined: 'Homework declined',
  text_attachments_recevied: 'Attachments files',
  open_lesson: 'Open lesson',
  open_homework: 'Open homework page',
  student_homework_message: 'Please wait for the teacher response',
  student_homework_message_declined: 'You can send a new homework on the lesson page',
  student_homework_message_approved: 'Great! Please continue the course',
  no_channels: 'No such channels',
  delivered: 'Delivered',
  read: 'Read',
  upload_limit: 'up to :size Mb',
}
