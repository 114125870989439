<template>
  <div
    :id="id"
    :class="[formData.blockShadow && 'shadow-lg']"
    :style="{
      'scroll-margin-top': 'env(safe-area-inset-top)',
    }">
    <template v-if="!isShowPopup">
      <h2
        v-if="formData.title && !(formData.hideOnEmbed && isInIframe)"
        class="text-xl font-bold mb-2"
        :style="{
          textAlign: formData.titleAlign,
          color: formData.mainTitleColor,
        }">
        {{ formData.title }}
      </h2>
      <div :class="formData.isUseSliderOnMobile ? 'hidden md:block' : 'block'">
        <div class="grid gap-x-6 gap-y-10"
             :class="gridColsClass">
          <template
            v-for="(speaker, index) in isShowAll
              ? formData.speakers
              : formData.speakers.slice(0, formData.visibleNumber)"
            :key="'speakers-grid' + speaker.title + index">
            <SpeakersCard
              :speaker="speaker"
              :title-color="formData.titleColor"
              :subtitle-color="formData.subtitleColor"
              :link-color="formData.linkColor"
              @click="onSpeakerClick(index)" />
          </template>
        </div>
        <div
          v-if="
            !isShowAll &&
              formData.visibleNumber &&
              formData.speakers.length > formData.visibleNumber
          "
          class="flex justify-center mt-10">
          <BaseButton @click="onShowAll">
            {{ getLocal('core.show_all') }}
          </BaseButton>
        </div>
      </div>
      <div :class="formData.isUseSliderOnMobile ? 'block md:hidden' : 'hidden'">
        <Swiper
          :slides-per-view="'auto'"
          :space-between="SLIDES_GAP"
          :pagination="{
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 10,
          }"
          class="swiper-custom">
          <template
            v-for="(speaker, index) in formData.speakers"
            :key="'speakers-slide' + speaker.title + index">
            <SwiperSlide
              :style="{
                marginRight: SLIDES_GAP + 'px',
                width: getSlideWidth(formData.colsNumberOnMobile, SLIDES_GAP),
              }">
              <SpeakersCard
                :speaker="speaker"
                :title-color="formData.titleColor"
                :subtitle-color="formData.subtitleColor"
                :link-color="formData.linkColor"
                @click="onSpeakerClick(index)" />
            </SwiperSlide>
          </template>
        </Swiper>
      </div>
    </template>
    <SpeakersModal
      :speaker="
        isShowPopup
          ? formData.speakers[popupSpeakerId]
          : formData.speakers[selectedSpeakerIndex]
      "
      :is-open="isModalOpen || isShowPopup"
      :title-color="formData.modalTitleColor"
      :subtitle-color="formData.modalSubtitleColor"
      :text-color="formData.textColor"
      :bg-color="formData.modalBgColor"
      :bg-class="isShowPopup ? '' : undefined"
      :link-color="formData.linkColor"
      @close="onPopupClose" />
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps, defineEmits, ref, nextTick } from 'vue'
import BaseButton from '/-/components/button/base-button.vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import SpeakersModal from './SpeakersModal.vue'
import SpeakersCard from './SpeakersCard.vue'
import { useRoute } from 'vue-router'
import { getGridColsClass, getSlideWidth } from '../utils'
import { useLocale } from '/-/plugins/locale'
import 'swiper/css'
import 'swiper/css/pagination'
const { getLocal } = useLocale()

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  formData: {
    type: Object,
    default: () => ({}),
  },
  isInIframe: {
    type: Boolean,
    default: false,
  },
})

const SLIDES_GAP = 24
const popupSpeakerId = Number(useRoute().query.id)
const isShowPopup =
  popupSpeakerId !== undefined &&
  props.formData.speakers[popupSpeakerId] !== undefined

const emit = defineEmits(['update', 'close', 'open'])
const isShowAll = ref(false)
const isModalOpen = ref(false)
const selectedSpeakerIndex = ref(0)

function onPopupClose() {
  isModalOpen.value = false
}

function onShowAll() {
  isShowAll.value = true
  nextTick(() => {
    emit('update')
  })
}

const onSpeakerClick = (idx: number) => {
  selectedSpeakerIndex.value = idx
  isModalOpen.value = true
}

const gridColsClass = computed(() => {
  return getGridColsClass(
    props.formData.colsNumberOnDesktop,
    props.formData.colsNumberOnMobile
  )
})
</script>
