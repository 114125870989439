import { markRaw } from 'vue'
import { DEFAULT_IMAGE_SQUARE, DEFAULT_TEXT } from '../constants'
import ImagesView from './ImagesView.vue'
import schema from './schema'
import uiSchema from './ui-schema'

export default {
  viewComponent: markRaw(ImagesView),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {
    colsNumberOnDesktop: 2,
    colsNumberOnMobile: 2,
    isUseSliderOnMobile: false,
    rowsNumberInSliderOnMobile: 1,
    images: [
      {
        url: DEFAULT_IMAGE_SQUARE,
        description: DEFAULT_TEXT
      },
      {
        url: DEFAULT_IMAGE_SQUARE,
        description: DEFAULT_TEXT
      }
    ],
  },
}
