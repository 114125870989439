<template>
  <div
    :id="id"
    :style="{
      'scroll-margin-top': 'env(safe-area-inset-top)',
    }"
    class="relative">
    <img
      v-if="formData.bgImage && formData.useImageRatio"
      :src="formData.bgImage"
      class="w-full h-auto overflow-hidden rounded-xl">
    <div
      class="flex px-6 md:px-8 overflow-hidden rounded-xl"
      :class="[
        flexAlignClass,
        formData.bgImage && formData.useImageRatio
          ? 'items-center absolute top-0 left-0 w-full h-full'
          : paddingClass,
      ]"
      :style="{
        background: formData.bgImage
          ? `center / cover no-repeat url(${formData.bgImage})`
          : formData.bgColor || '#000000',
        color: formData.textColor,
      }">
      <div
        class="flex flex-col gap-4 md:gap-8 w-full"
        :style="{
          maxWidth: formData.maxWidth ? `${formData.maxWidth}px` : '576px',
        }">
        <div
          class="marked"
          :class="[`text-${formData.textAlign}`]"
          v-html="
            getMarkedTextWithBlankLinks(formData.text, formData.linkColor)
          " />
        <div
          v-if="formData.buttons?.length"
          class="flex flex-wrap gap-4"
          :class="flexAlignClass">
          <template v-for="(button, index) in formData.buttons"
                    :key="index">
            <EditorButton :button="button" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue'
import type { ButtonInterface } from '../common'
import EditorButton from '../components/PageEditorButton.vue'
import { getMarkedTextWithBlankLinks } from '../utils'

interface FormDataInterface {
  text: string
  textColor?: string
  linkColor?: string
  textAlign?: 'left' | 'center' | 'right'
  paddingSize: 'xs' | 'sm' | 'md' | 'lg'
  isBgImage: boolean
  useImageRatio: boolean
  bgImage?: string
  bgColor?: string
  buttons: ButtonInterface[]
  maxWidth?: number
}

interface PropsInterface {
  id: string
  formData: FormDataInterface
}

const props = defineProps<PropsInterface>()

const flexAlignClass = computed(() => {
  switch (props.formData.textAlign) {
  case 'left':
    return 'justify-start'
  case 'center':
    return 'justify-center'
  case 'right':
    return 'justify-end'
  default:
    return 'justify-center'
  }
})

const paddingClass = computed(() => {
  switch (props.formData.paddingSize) {
  case 'xs':
    return 'py-6 md:py-8'
  case 'sm':
    return 'py-14 md:py-20'
  case 'lg':
    return 'py-32 md:py-56'
  default:
    return 'py-20 md:py-40'
  }
})
</script>
