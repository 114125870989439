export default {
  edit_description: '關於您的個人資料的基本信息',
  upgrade_ticket: '升级门票',
  send_message: '发送信息',
  view: '查看个人资料',
  edit: '编辑个人资料',
  profile: '个人信息',
  info: '信息',
  security: '安全性',
  call: '视频通话',
  request_meeting: '请求会议',
  open_chat: '打开聊天',
  name: '姓名',
  first_name: '名字',
  last_name: '姓氏',
  looking: '我正在寻找',
  offer: '我可以提供',
  bio: '简介',
  job: '职位',
  phone: '电话',
  disable_notifications: '禁用邮件通知',
  information: '基本信息',
  information_description: '将对其他成员可见。',
  portfolio: '产品',
  company: '公司简介',
  company_members: 'Manage company members',
  company_description: '您的公司信息',
  add_game: '添加游戏',
  edit_game: '編輯遊戲',
  dev_start_at: '开发开始日期',
  release_at: '发布日期',
  delete_title: '删除游戏',
  delete_text_1: '是否确定要删除游戏',
  delete_text_2: '要删除游戏，请写信至 gamefinder@wn.media 输入游戏名称和公司',
  language: '语言',
  organizer: '组织方',
  sponsor: '赞助商',
  speaker: '演讲嘉宾',
  registration_person: '自我简介',
  registration_company: '公司',
  no_company: '公司不存在',
  new_company: '(+ 新公司)',
  fill_data_prompt: '填写您的个人资料',
  registration_text: '请完成填写其余选项以参与活动。',
  privacy_policy: '我接受 {link}',
  privacy_policy_link: '隐私政策',
  games: '游戏',
  linkedin_profile: '领英介绍',
  whatsapp: 'Contacts',
  whatsapp_placeholder: 'Facebook / WhatsApp / Telegram / WeChat',
  pro: 'PRO'
}
