import { useTabEvents } from '/-/plugins/tab-events'
import { EventSubscriptionInterface } from '/-/plugins/helpers/observer'
import { useAuth } from '/-/plugins/auth'
import { useAuthToken } from '/~/plugins/auth-token'
import { useProfile } from '/~/state/profile'
import { usePushService } from '/~/plugins/push-service'
import { useApplication } from '/~/state/application'
import router from '/~/router'

const LOGOUT_EVENT = 'logout'
let subscriber: null | EventSubscriptionInterface = null

function watchTabsLogout() {
  const { addListener } = useTabEvents()

  if (subscriber) { subscriber.unsubscribe() }

  subscriber = addListener(LOGOUT_EVENT, async () => {
    await logoutCurrentTab()
    router.replaceWithinEvent({ name: 'auth-login' })
  })
}

async function login(token: any) {
  const { setToken } = useAuth()
  const { saveToken } = useAuthToken()

  await usePushService().registerNotifications()

  setToken(token)
  await saveToken(token)
}

async function logoutCurrentTab() {
  const { logout } = useAuth()
  const { removeProfile } = useProfile()
  const { removeToken } = useAuthToken()

  await usePushService().removeAppToken()
  await logout()
  await removeProfile()
  removeToken()

  reload()
}

async function logoutAllTabs() {
  const { triggerEvent } = useTabEvents()

  await logoutCurrentTab()
  triggerEvent(LOGOUT_EVENT)
}

function reload() {
  const { isNative } = useApplication()

  if (isNative) {
    location.replace(`/eventhub/index.html?redirect=${router.currentRoute.value.path}`)
  } else {
    location.reload()
  }
}

export function useLogin() {
  return {
    login,
    logout: logoutAllTabs,
    watchTabsLogout,
    reload,
  }
}
