import { basePropertiesSchema } from '../base-properties-schema'
import { DEFAULT_COLOR_GRAY, DEFAULT_MARKDOWN_TEXT } from '../constants'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Video',
  description: 'Video block with title and markdown description',
  type: 'object',
  required: [
    'sourse',
    'videoId',
    'videoUrl',
  ],
  properties: {
    ...basePropertiesSchema,
    title: {
      title: 'Title',
      type: 'string',
    },
    description: {
      title: 'Description',
      type: 'string',
      'ui:options': {
        placeholder: DEFAULT_MARKDOWN_TEXT,
        type: 'textarea',
        rows: 6,
      },
    },
    textColor: {
      title: 'Text Color',
      type: 'string',
      default: DEFAULT_COLOR_GRAY,
    },
    source: {
      title: 'Source',
      type: 'string',
      default: 'youtube',
      enum: ['youtube', 'gcore', 'googledrive', 'jitsi'],
      enumNames: ['Youtube', 'G-Core', 'Google Drive', 'WN Meet']
    },
    videoId: {
      title: 'Video id',
      type: 'string',
      'ui:hidden': '{{!parentFormData.videoId || parentFormData.source === "jitsi"}}',
    },
    videoUrl: {
      title: 'Video url',
      type: 'string',
      minLength: 20,
      'ui:options': '{{ { source: parentFormData.source } }}',
      'ui:hidden': '{{!!parentFormData.videoId && parentFormData.source !== "jitsi"}}',
    },
  },
}
