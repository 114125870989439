export default {
  edit_success: 'Личная заметка успешно отредактирована',
  edit_error: 'Не удалось отредактировать личную заметку',
  delete_success: 'Личная заметка успешно удалена',
  delete_error: 'Не удалось удалить личную заметку',
  create_success: 'Новая личная заметка успешно создана',
  create_error: 'Не удалось создать новую личную заметку',
  create_template_success: 'Личная заметка успешно создана',
  create_template_success_description: 'Вы можете добавить дополнительные комментарии о пользователе',
  no_notes: 'Нет личных заметок',
  private_notes: 'Личные заметки',
  load_more: 'Загрузить больше',
  add_private_note: 'Добавить личную заметку',
  edit: 'Редактировать',
  delete: 'Удалить',
  edit_note: 'Редактировать личную заметку',
  save_note: 'Сохранить личную заметку',
  delete_note: 'Удалить личную заметку',
  delete_confirm_text: 'Вы точно хотите удалить заметку?',
  create_note: 'Создать личную заметку',
  note_placeholder: 'Текст личной заметки',
  your_note: 'Личная заметка',
  template_event: 'Мероприятие',
  view_all_notes: 'посмотреть все заметки'
}
