<template>
  <NConfigProvider preflight-style-disabled>
    <NSwitch v-model:value="value">
      <template #checked>
        Hide settings
      </template>
      <template #unchecked>
        Show settings
      </template>
    </NSwitch>
  </NConfigProvider>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'
const props = defineProps({
  modelValue: {
    type: String,
    default: undefined,
  },
})

const emit = defineEmits(['update:modelValue'])

const value = computed({
  get: () => {
    return !!props.modelValue
  },
  set: (value) => {
    emit('update:modelValue', value ? 'true' : undefined)
  },
})
</script>
