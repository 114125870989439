import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { useCatalogs } from '/~/state/catalogs'
import { formatLinks, removeTags } from '/-/plugins/format'
import { Media } from './media'
import { BaseModel } from './baseModel'
import { EntryItem } from './entry'
import { UserLite } from './user'
import { marked } from '/~/plugins/marked'

export class CompanyLite extends BaseModel {
  id!: number
  name!: string
  description!: string
  tags!: number[]
  logo!: Media | null
  cover!: Media | null
  size!: number

  site!: string

  @Expose({ name: 'geo' }) // to PATCH
  @Transform(({ obj }) => obj.country?.id || '', { toClassOnly: true })
  geo!: string | null

  @Exclude({ toPlainOnly: true })
  email!: string | null

  media!: Media[]

  @Transform(({ obj }) => obj.business.map((business: EntryItem) => business.id), { toClassOnly: true })
  business!: number[]

  @Exclude({ toPlainOnly: true })
  country!: EntryItem

  @Expose({ name: 'has_chat' })
  hasChat?: boolean

  @Expose({ name: 'sponsor_type' })
  sponsorType?: number

  get employeesSize() {
    const { employees } = useCatalogs()

    return this.size && employees.value?.find(({ value }) => value === this.size)
  }

  get domain() {
    let host

    if (!this.site) {
      return ''
    }

    try {
      host = new URL(this.site).host
    } catch (err) {}

    return host
  }

  get heroMedia() {
    return this.media.filter(media => media.category.startsWith('hero'))
  }

  get avatarUrl(): string {
    if (!this.logo) { return '' }
    return this.logo?.url.startsWith('http') ? this.logo.url : import.meta.env.VITE_BASE_MEDIA_URL + this.logo.url
  }

  get coverUrl(): string {
    if (!this.cover) { return '' }
    return this.cover?.url.startsWith('http') ? this.cover.url : import.meta.env.VITE_BASE_MEDIA_URL + this.cover.url
  }

  get primaryBusiness() {
    const { business } = useCatalogs()

    return this.business.map((item) => {
      return business.value?.find(({ value }) => value === item)
    }).filter((item) => item)
  }

  get tagsItems() {
    const { tags } = useCatalogs()

    return this.tags.map((item) => {
      return tags.value.find(({ value }) => value === item)
    }).filter((item) => item)
  }

  get descriptionHtml(): string {
    if (!this.description) {
      return ''
    }
    return formatLinks(removeTags(this.description))
  }

  get descriptionMarked(): string {
    return this.description
      ? marked(removeTags(this.description)).replace(/<a href=/g, '<a target="_blank" href=')
      : ''
  }
}

export class Company extends CompanyLite {
  @Expose({ name: 'has_chat' })
  hasChat!: boolean

  @Expose({ name: 'sponsor_type' })
  sponsorType!: number

  @Expose({ name: 'wn_manager' })
  @Type(() => UserLite)
  wnManager!: UserLite

  @Expose({ name: 'wn_manager_id' })
  wnManagerId!: number
}

export class CompanyAutocomplete extends BaseModel {
  @Expose({ name: 'id' })
  value!: string

  @Expose({ name: 'name' })
  label!: string
}
