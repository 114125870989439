<template>
  <div
    :id="id"
    :style="{
      'scroll-margin-top': 'env(safe-area-inset-top)',
    }">
    <h2
      v-if="formData.title && !(formData.hideOnEmbed && isInIframe)"
      class="text-xl font-bold mb-2"
      :style="{
        textAlign: formData.titleAlign,
        color: formData.titleColor,
      }">
      {{ formData.title }}
    </h2>
    <div
      class="grid gap-6"
      :class="[
        gridColsClass,
        formData.reviews.length > 1 && 'max-md:hidden',
        formData.reviews.length > 2 && 'hidden',
      ]">
      <template
        v-for="(review, index) in formData.reviews"
        :key="'review-grid' + review.title + index">
        <ReviewsCard
          :review="review"
          :user-info-color="formData.userInfoColor"
          :text-color="formData.textColor"
          :bg-color="formData.cardBgColor"
          :link-color="formData.linkColor" />
      </template>
    </div>
    <div
      :class="[
        formData.reviews.length === 1 && 'hidden',
        formData.reviews.length === 2 && 'md:hidden',
      ]">
      <Swiper
        :slides-per-view="'auto'"
        :space-between="SLIDES_GAP"
        :pagination="{
          clickable: true,
          dynamicBullets: true,
          dynamicMainBullets: 10,
        }"
        class="swiper-custom">
        <template
          v-for="(review, index) in formData.reviews"
          :key="'review-slide' + review.name + index">
          <!-- can't use getSlideWidth with tailwind endpoints -->
          <SwiperSlide
            class="!w-[calc(100%-64px)] md:!w-[calc(50%-44px)]"
            :style="{
              marginRight: SLIDES_GAP + 'px',
            }">
            <ReviewsCard
              :review="review"
              :user-info-color="formData.userInfoColor"
              :text-color="formData.textColor"
              :bg-color="formData.cardBgColor"
              :link-color="formData.linkColor" />
          </SwiperSlide>
        </template>
      </Swiper>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import ReviewsCard from './ReviewsCard.vue'
import { getGridColsClass } from '../utils'
import 'swiper/css'
import 'swiper/css/pagination'

defineProps({
  id: {
    type: String,
    required: true,
  },
  formData: {
    type: Object,
    default: () => ({}),
  },
  isInIframe: {
    type: Boolean,
    default: false,
  },
})

const SLIDES_GAP = 24

const gridColsClass = computed(() => {
  return getGridColsClass(2, 1)
})
</script>
