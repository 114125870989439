import { App } from 'vue'
import { RouteLocationRaw } from 'vue-router'
import { Observer } from '../utils'
import { AnyCallback } from '/~/types'

const observer = new Observer()
const broadcastDisplayContent = (to: RouteLocationRaw) => {
  observer.broadcast('display-content', to)
}

export const displayContent = {
  install: (app: App<Element>) => {
    app.config.globalProperties.$displayContent = broadcastDisplayContent
  }
}

export function useDisplayContent() {
  return {
    unsubscribe() {
      observer.unsubscribe()
    },
    onDisplayContentRequest(callback: AnyCallback) {
      observer.subscribe('display-content', callback)
    },
    displayContent: broadcastDisplayContent
  }
}
