import { Expose } from 'class-transformer'
import dayjs from 'dayjs'
import { useEvents } from '/~/state/events'
import { useWNMeet } from '/~/state/wnmeet'
import { useError } from '/~/plugins/error'

export class WNMeetVideo {
  isWNMeet = true

  @Expose({ name: 'created_at' })
  createdAt!: string

  link!: string

  url: null | string = null

  tempVideoSrc = ''

  get uuid() {
    return null
  }

  get preview() {
    return null
  }

  get name() {
    return 'WN Meet Recording'
  }

  get formattedDate() {
    return this.createdAt ? dayjs.tz(this.createdAt, 'UTC').tz(dayjs.tz.guess()).format('MMM D, YYYY H:mm') : ''
  }

  async setTempVideoSrc() {
    if (this.tempVideoSrc) {
      return
    }
    this.tempVideoSrc = ''
    const { eventId } = useEvents()
    const wnMeetStore = useWNMeet()
    const { processError } = useError()

    try {
      this.tempVideoSrc = await wnMeetStore.getVideoLink(eventId.value as number, this.link)
    } catch (error: any) {
      console.log(error)
      processError(error, false)
    }
  }
}
