import config from './config'

declare let window: {
  dataLayer: any[]
}

export function checkConfig() {
  if (typeof document === 'undefined') { return }

  if (!config.id) {
    throw new Error('Please enter a Yandex Metrika tracking ID')
  }
}

export function checkAdBlock() {
  const el = document.getElementById('wrapfabtest')

  if (el && el.clientHeight === 0) {
    throw new Error('AdBlock Detected')
  }
}

async function loadScript(src: string) {
  return new Promise((resolve, reject) => {
    const head = document.head || document.getElementsByTagName('head')[0]
    const script = document.createElement('script')

    script.async = true
    script.charset = 'utf-8'
    script.src = `${src}?id=${config.id}`

    head.appendChild(script)

    script.onload = () => resolve({ success: true })
    script.onerror = error => reject(error)
  })
}

function debug(message: string, args?: any) {
  if (config.debug) {
    console.log(`[${config.name}] ${message}`, args)
  }
}

function gTag(name: string, data: any) {
  config.dataLayer.push([name, data])
}

function gTagPush(data: any) {
  config.dataLayer.push(data)
}

function gTagView(name: string, path: string, data = {}) {
  config.dataLayer.push({
    ...data,
    event: 'content-view',
    'content-name': path,
    'content-view-name': name,
  })
}

export async function createTag() {
  if (import.meta.env.VITE_ENV === 'production') {
    await loadScript(config.scriptSrc)

    config.dataLayer = window.dataLayer || []

    return {
      gTag,
      gTagPush,
      gTagView
    }
  } else {
    console.warn('Tracking is disabled, because env option is not "production"')
    debug('DEBUG is true: you\'ll see all API calls in the console')

    return {
      gTag(...args: any) { debug('gTag:', args) },
      gTagPush(...args: any) { debug('gTagPush:', args) },
      gTagView(...args: any) { debug('gTagView:', args) },
    }
  }
}
