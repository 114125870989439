import tags from './tags'
import offers from './offers'
import regions from './regions'
import business from './business'
import sponsors from './sponsors'
import publisher from './publisher'

export default {
  tags,
  offers,
  regions,
  business,
  sponsors,
  publisher
}
