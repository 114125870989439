import { BaseModel } from './baseModel'

export class AnalyticEvent extends BaseModel {
  evt!: string
  ts!: number
  userId!: number
  eventId!: number
  entityId?: number
  entityType?: string
  info?: string
  msg?: string
}
