import {
  DEFAULT_IMAGE_SQUARE,
  DEFAULT_MARKDOWN_PLACEHOLDER,
} from '../constants'
import { basePropertiesSchema } from '../base-properties-schema'
import {
  buttonsPropertiesSchema,
  titlePropertiesSchema,
  showAllSettingsSchema,
  linkColorSchema,
  textColorSchema,
} from '../common'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Content',
  description: 'Two-column with text, images and button(link)',
  type: 'object',
  required: ['text', 'colsNumberOnDesktop', 'colsNumberOnMobile'],
  'ui:options': {
    onlyShowIfDependent: true,
  },
  dependencies: {
    tmp_showAllSettings: ['textColor', 'bgColor', 'linkColor', 'blockShadow'],
    title: ['titleAlign', 'hideOnEmbed'],
  },
  properties: {
    ...basePropertiesSchema,
    ...titlePropertiesSchema,
    ...showAllSettingsSchema,
    ...textColorSchema,
    ...linkColorSchema,
    text: {
      title: 'Text',
      type: 'string',
      'ui:options': {
        placeholder: DEFAULT_MARKDOWN_PLACEHOLDER,
        type: 'textarea',
        rows: 6,
      },
    },
    colsNumberOnDesktop: {
      title: 'Images: number of cols on desktop',
      type: 'number',
      default: 2,
      minimum: 1,
      maximum: 6,
    },
    colsNumberOnMobile: {
      title: 'Images: number of cols on mobile',
      type: 'number',
      default: 2,
      minimum: 1,
      maximum: 6,
    },
    isUseSliderOnMobile: {
      title: 'Use slider on mobile',
      type: 'boolean',
      default: false,
    },
    isOpenFullscreen: {
      title: 'Open images in fullscreen gallery',
      type: 'boolean',
      default: false,
    },
    images: {
      type: 'array',
      title: 'A list of images',
      'ui:options': {
        showIndexNumber: true,
      },
      items: {
        title: 'Image',
        type: 'string',
        default: DEFAULT_IMAGE_SQUARE,
      },
    },
    ...buttonsPropertiesSchema,
  },
}
