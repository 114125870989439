import { Api } from '/-/plugins/api'
import { plainToClass } from '/-/plugins/helpers'
import { Media } from '/~/models/media'
import { useEvents } from '/~/state/events'

async function uploadMedia(formData: { media: File, category: string}): Promise<Media> {
  const { event } = useEvents()

  const { data } = await Api.fetch({
    url: `/${event.value?.id}/media`,
    method: 'POST',
    formData
  })

  if (!data) {
    throw new Error('Empty data')
  }

  return plainToClass(data, Media)
}

export function useMedia() {
  return {
    uploadMedia,
  }
}
