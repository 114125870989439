export default {
  lectures: 'Лекции',
  lectures_archive: 'Архив лекций',
  program: 'Программа',
  chats: 'Сообщения',
  showcase: 'Шоукейс',
  showcase_top: 'Избранные игры',
  members: 'Участники',
  companies: 'Компании',
  consultants: 'Консультанты',
  vacancies: 'Вакансии',
  meetings: 'Встречи',
  speakers: 'Спикеры',
  group_call: 'Групповой звонок',
  zoom_call: 'Zoom Call',
  wnmeet: 'WN Meet',
  agenda: 'Программа',
  contacts: 'Контакты',
  events: 'Мероприятия',
  publishers: 'Издатели',
  host: 'Проведите мероприятие',
  expo: 'Экспо Зона',
  media: 'Медиа партнёры',
  our_media: 'Наши сми',
  partyspace: 'Party Space',
  news: 'Новости',
  volunteer_badges: 'Печать бейджей',
  home: 'Хаб',
  favorites: 'Избранное',
  academy_admin: 'Панель администратора',
  academy_admin_home: 'Вернуться к курсу',
  academy_admin_modules: 'Модули и уроки',
  academy_admin_homeworks: 'Домашние задания',
  academy_admin_settings: 'Настройки',
  academy_admin_promo_messages: 'Рассылки',
  academy_admin_lectures: 'Архив лекций',
  academy_admin_students: 'Студенты',
  academy_program: 'Программа курса',
  academy_stream_records: 'Записи стримов',
  academy_admin_help: 'Помощь',
  notifications: 'Уведомления',
}
