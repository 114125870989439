import { reactive, toRef } from 'vue'
import { Api } from '/-/plugins/api'
import { useEvents } from './events'
import { Branding } from '/~/core/branding'

interface BrandingStateInterface {
  branding?: Branding
  processing: boolean
  brandingEventId?: number
}

const state: BrandingStateInterface = reactive({
  processing: false,
  branding: undefined,
  brandingEventId: undefined,
})

async function getBranding() {
  const { event } = useEvents()

  if (!state.processing && (!state.branding || (state.branding && event.value?.id !== state.brandingEventId))) {
    state.processing = true

    const { data } = await Api.fetch({
      url: `/events/${event.value?.id}/brandings`
    })

    state.branding = new Branding(data)
    state.brandingEventId = event.value?.id

    state.processing = false
  }

  return state.branding
}

function getBanner(group: string, place: string) {
  const banners = state.branding?.getGroup(group)?.banners || []

  return banners?.filter((item) => item.place_id === place) // !
}

function clearBranding() {
  state.branding = undefined
}

export function useBranding() {
  return {
    branding: toRef(state, 'branding'),
    getBanner,
    getBranding,
    clearBranding,
  }
}
