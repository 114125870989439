export default {
  added: 'добавлено',
  add_success: 'Вы успешно добавили ":title" в избранное',
  remove_success: 'Вы успешно удалили ":title" из избранного',
  add_fail: 'Не удалось добавить в избранное ":title"',
  remove_fail: 'Не удалость удалить из избранного ":title"',
  welcome_to_favorites: 'Добро пожаловать в "Избранное"',
  no_news_description: 'Здесь появятся ваши избранные новости.',
  no_events_description: 'Здесь появятся ваши избранные мероприятия.',
  no_lectures_description: 'Здесь появятся ваши избранные доклады на мероприятиях WN.',
  no_companies_description: 'Здесь появятся ваши избранные компании на мероприятиях WN.',
  no_users_description: 'Здесь еще ничего нет. Вы можете добавить в "Избранное" пользователей платформы WN Hub, чтобы оставаться с ними на связи:',
  no_users_visit_profile: 'Зайдите в их профиль и нажмите кнопку добавления в "Избранное"',
  no_users_scan_qr: 'Отсканируйте QR-код на их бейдже при личной встрече на оффлайн-мероприятиях WN',
}
